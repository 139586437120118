import { StreetCityStateZip } from './address';
import { createStore } from './createStore';

export const COMMUNITY_PROPERTY_INITIAL_STATE = {
    marital_status: undefined,
    spouse_first_name: '',
    spouse_last_name: '',
    street: '',
    city: '',
    state: '',
    zip: ''
};

export enum MaritalStatus {
    Single = '1',
    Married = '2',
    Divorced = 'D',
    Widowed = 'W',
    Separated = 'S'
}

export type CommunityProperty = {
    marital_status?: MaritalStatus;
    spouse_first_name: string;
    spouse_last_name: string;

} & StreetCityStateZip;

export const createPrimaryApplicantCommunityPropertyStore = createStore<CommunityProperty>({
    ...COMMUNITY_PROPERTY_INITIAL_STATE
});

export const createJointApplicantCommunityPropertyStore = createStore<CommunityProperty>({
    ...COMMUNITY_PROPERTY_INITIAL_STATE
});