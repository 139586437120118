import { FieldLayoutComponent } from "../_components/FieldLayoutComponent";
import { IApplication } from "../_store/application";
import { IFieldProps } from "../_components/IFieldProps";
import { useApplicationState } from "../_store/useApplicationState";

export const ApplicationTypeComponent = (
  props: IFieldProps<Pick<IApplication, "application">>
) => {
  const application_store = useApplicationState((s) => s.application_store);
  const application = application_store((store) => store.application);
  const setter = application_store.setState;

  return (
    <FieldLayoutComponent label={props.label}>
      <div className="inline-radio">
        <label
          htmlFor="application-type_individual"
        >
          <input
            id={"application-type_individual"}
            type="radio"
            checked={application === "Individual"}
            onChange={() => setter({ application: "Individual" })}
            name="application-type"
          />
          Individual
        </label>

        <label
          htmlFor="application-type_joint"
        >
          <input
            id={"application-type_joint"}
            type="radio"
            checked={application === "Joint"}
            onChange={() => setter({ application: "Joint" })}
            name="app-type"
          />
          Joint
        </label>
      </div>
    </FieldLayoutComponent>
  );
};
