import { IFieldProps } from "./IFieldProps";
import { IFieldError } from "./FieldLayoutComponent";
import { useEffect, useState } from "react";
import {
  IFieldSelector,
  Stores,
  useFormStore,
} from "../_store/useApplicationState";
import { InputModeType } from "./InputModeType";


type InputElementProps<
  TStore extends Stores,
  TField extends TStore[keyof TStore]
> = IFieldProps<TStore, TField> &
  IFieldError &
  IFieldSelector<TStore, TField> & {
    inputMode: InputModeType;
  } & {
    name?: string;
  };
  

export const InputElement = <
  TStore extends Stores,
  TField extends TStore[keyof TStore]
>(
  props: InputElementProps<TStore, TField>
) => {
  const [value_as_string, set_value_as_string] = useState("");
  const [store, set] = useFormStore<TStore>(props.store);
  const setter = set(props.selector);

  //this value is reactive
  const value = store(props.selector);
  useEffect(() => {
    // FIXME: need inverse parser? to_string?
    const as_string = props.to_string
      ? props.to_string(value)
      : value?.toString() ?? "";
    
    // TODO: make better
    // Using null to signal - do nothing, field is in progress
    if (as_string === null) {
      return;
    }

    if (as_string === "") {
      set_value_as_string("");
      return;
    }
    const masked = props.mask ? props.mask(as_string) : as_string;
    set_value_as_string(masked);

  }, [props, value]);

  // TODO: Extract
  const [inputType, setInputType] = useState(props.type ?? "text");
  const onEyeClick = (event: React.MouseEvent<SVGSVGElement>) => {
    if (inputType === "password") {
      setInputType("text");
      event.currentTarget.style.fill = props.passwordEyeColor ?? "black";
    } else {
      setInputType("password");
      event.currentTarget.style.fill = "#9ca3af";
    }
  };

  return (
    <span className="flex items-center flex-row">
      <input
        type={inputType}
        inputMode={props.inputMode}
        name={props.name}
        min={props.min ?? undefined}
        max={props.max ?? undefined}
        className={`
            ${
              props.showError //|| showInvalid
                ? "focus:ring focus:ring-rose-400 focus:border-error-950 border-error-950 border-2"
                : "border-[#d3d3d3] focus:border-[#d3d3d3] focus:ring-gray-600 focus:ring-2"
            } form-input-field
            ${props.disabled ? "text-[#535353] opacity-40" : ""}
                `}
        value={value_as_string}
        placeholder={props.placeholder}
        maxLength={props.maxLength ?? 50}
        // TODO: use context to generate id
        // data-testid="field_input"

        disabled={props.disabled}
        autoComplete={props.autoComplete ?? "do-not-autofill"}
        onChange={(e) => {
          let masked: string = e.target.value;
          if (props.mask) {
            masked = props.mask(masked);
          }

          set_value_as_string(masked);

          const parsed = props.field_parser
            ? props.field_parser(masked)
            : (masked as TField);

          setter(parsed);

          if (props.onChange) {
            props.onChange(parsed);
          }

        }}
        onKeyDown={(e) => {
          props.onKeyDown?.(e);
        }}
        onBlur={(e) => {
          const parsed = props.field_parser
            ? props.field_parser(e.target.value)
            : (e.target.value as TField);

          if (props.onBlur) {
            props.onBlur(parsed, true);
          }

        }}
      />
      {props.type === "password" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 30 30"
          className="-ml-7 cursor-pointer fill-gray-400"
          id="password-eye"
          onClick={onEyeClick}
        >
          <circle cx="29" cy="15" r="1" />
          <circle cx="1" cy="15" r="1" />
          <circle cx="15" cy="15" r="3" />
          <path d="M29.803,14.408C29.803,14.408,24,5,15,5S0.197,14.408,0.197,14.408l-0.034,1.136C0.162,15.544,5,25,15,25s14.838-9.456,14.838-9.456L29.803,14.408z M15,22c-3.866,0-7-3.134-7-7c0-3.866,3.134-7,7-7s7,3.134,7,7C22,18.866,18.866,22,15,22z" />
        </svg>
      )}
    </span>
  );
};
