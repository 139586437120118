import { ComponentValidationResult, IFieldProps } from "./IFieldProps";
import { InputComponent } from "./InputComponent";
import { ITestableComponent } from "./ITestableComponent";
import { isNullOrWhite } from "../_helpers/isNullOrWhite";
import { IFieldSelector, Stores } from "../_store/useApplicationState";
import { onlyLettersMask } from "../_helpers/Masks";
import { StoreField } from "./StoreField";

type IntialsInputComponentProps<
  TStore extends Stores,
  TField extends StoreField<TStore>
> = IFieldProps<TStore> &
  ITestableComponent &
  IFieldSelector<TStore, TField>;

export const InitialsInputComponent = <
  TStore extends Stores,
  TField extends StoreField<TStore>
>(
  props: IntialsInputComponentProps<TStore, TField>
) => {

  const validate = (
    initials: StoreField<TStore>,
    force: boolean,
    isDirty: boolean
  ): ComponentValidationResult => {
    if (!force && !isDirty) {
      return [true];
    }

    if (isNullOrWhite(initials)) {
      return [false, () => "Initials are required."];
    }

    if (props.validate) {
      return props.validate(initials, force, isDirty);
    }

    return [true];
  };

  return (
    <InputComponent
      {...props}
      validate={(value, force, isDirty) => validate(value, force, isDirty)}
      mask={(value) => {
        const masked = onlyLettersMask(value as string);
        return masked.toUpperCase();
      }}
    />
  );
};
