import "./AddressInfoStep.css";
import { LoanBasics } from "./LoanBasics";

import { PrimaryCurrentAddress } from "./PrimaryCurrentAddress";
import { PrimaryPreviousAddress } from "./PrimaryPreviousAddress";
import { PrimaryCommunity } from "../../../my-autoloan-shared/PrimaryCommunity";

import { JointCurrentAddress } from "./JointCurrentAddress";
import { JointPreviousAddress } from "./JointPreviousAddress";
import { JointCommunity } from "../../../my-autoloan-shared/JointCommunity";
import { IApplicationStep } from "../../../_store/application";


export const AddressInfoStep = (props: IApplicationStep) => {
  return (
    <>
      <LoanBasics step={props.step} />

      <PrimaryCurrentAddress step={props.step} />
      <PrimaryPreviousAddress step={props.step} />
      <PrimaryCommunity step={props.step} />

      <JointCurrentAddress step={props.step} />
      <JointPreviousAddress step={props.step} />
      <JointCommunity step={props.step} />
    </>
  );
};
