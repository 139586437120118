import { IFieldError } from "./FieldLayoutComponent";
import {
  ComponentValidationResult,
  IFieldProps,
} from "./IFieldProps";
import { StoreField } from './StoreField';
import { InputComponent } from "./InputComponent";
import { ITestableComponent } from "./ITestableComponent";
import {} from "../_helpers/Masks";
import { isValidEmail } from "../_helpers/validations";

import { IFieldSelector, Stores } from "../_store/useApplicationState";

type EmailInputComponentProps<
  TStore extends Stores,
  TField extends StoreField<TStore>
> = IFieldProps<TStore> &
  IFieldError &
  ITestableComponent &
  IFieldSelector<TStore, TField>;

export const EmailInputComponent = <
  TStore extends Stores,
  TField extends StoreField<TStore>
>(
  props: EmailInputComponentProps<TStore, TField>
) => {
  const validate = (
    email: StoreField<TStore>,
    force: boolean,
    isDirty: boolean | undefined
  ): ComponentValidationResult => {
    if (!force && !isDirty) {
      return [true];
    }

    const valid = isValidEmail(email);
    if (!valid) {
      return [false, () => props.errorMessage ?? "Invalid email."];
    }

    return [true];
  };

  const validateAsync = async (
    email: StoreField<TStore>
  ): Promise<[boolean, (input: string) => string]> => {
    if (props.validateAsync) {
      const [valid, error] = await props.validateAsync(email);
      return [valid, error];
    }

    return [true, () => ""];
  };

  return (
    <InputComponent
      {...props}
      inputMode="email"
      autoComplete="off"
      maxLength={100}
      validate={validate}
      validateAsync={validateAsync}
    ></InputComponent>
  );
};
