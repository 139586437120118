import "./EmploymentInfoStep.css";

import { useScrollToElement } from "../../../_helpers/useScrollToElement";
import { IApplicationStep } from "../../../_store/application";

import { PrimaryCurrentEmployment } from "./PrimaryCurrentEmployment";
import { PrimaryPreviousEmployment } from "./PrimaryPreviousEmployment";
import { JointCurrentEmployment } from "./JointCurrentEmployment";
import { JointPreviousEmployment } from "./JointPreviousEmployment";

export const EmploymentInfoStep = (props: IApplicationStep) => {
  
  useScrollToElement("top");

  return (
    <>
      <PrimaryCurrentEmployment step={props.step} />
      <PrimaryPreviousEmployment step={props.step} />
      <JointCurrentEmployment step={props.step} />
      <JointPreviousEmployment step={props.step} />
    </>
  );
};
