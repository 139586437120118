// import { calculateTotalMonths } from '../_helpers/calculateTotalMonths';
// import { useApplicationState } from '../my-autoloan/useApplicationState';
// import { useApplicationState } from './useApplicationState';

import { createStore } from './createStore';
import { IMonthsYears } from './IMonthsYears';

export type AddressOwnership = 'own' | 'rent' | 'lease' | 'other';

export const ADDRESS_INITIAL_STATE = {
  street: '',
  city: '',
  state: '',
  zip: '',
  years: '',
  months: '',
  monthly_payment: null,
  ownership: undefined,
  same_address: false
};

export type StreetCityStateZip = {
  street: string;
  city: string;
  state: string;
  zip: string;
};

export type StreetAddressType = Pick<StreetCityStateZip, "street">;

export type Address = {
  ownership?: AddressOwnership;
  monthly_payment: number | null;
} & StreetCityStateZip & IMonthsYears;

export const createPrimaryApplicantAddressStore = createStore<Address>({
  ...ADDRESS_INITIAL_STATE,
});

export const createPrimaryApplicantPreviousAddressStore = createStore<Address>({
  ...ADDRESS_INITIAL_STATE,
});

export const createJointApplicantAddressStore = createStore<Address>({
  ...ADDRESS_INITIAL_STATE,
});


export const createJointApplicantPreviousAddressStore = createStore<Address>({
  ...ADDRESS_INITIAL_STATE,
});