import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import React from "react";

import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

if (
  import.meta.env.VITE_DD_ENV === "staging" ||
  import.meta.env.VITE_DD_ENV === "production"
) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APP_ID,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "us5.datadoghq.com",
    service: import.meta.env.VITE_DD_SERVICE,
    env: import.meta.env.VITE_DD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: "1.0.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: "mask-user-input",

    allowedTracingUrls: [
      "https://appform-qa.myautoloan.com/",
      "https://appform.myautoloan.com/",
    ],
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackViewsManually: false,
  });

  datadogLogs.init({
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    site: "us5.datadoghq.com",
    service: import.meta.env.VITE_DD_SERVICE,
    env: import.meta.env.VITE_DD_ENV,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    sessionSampleRate: 100,
    version: "1.0.0",
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
  // <App/>
);
