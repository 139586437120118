import { ComponentValidationResult } from "../../_components/IFieldProps";
import { MaskedInputComponent } from "../../_components/MaskedInputComponent";
import { parseIntSafe } from "../../_helpers/isNullOrWhite";
import { number_formatter, onlyNumbersMask } from "../../_helpers/Masks";
import { IApplicationStep } from "../../_store/application";
import { IYearMakeModelTrim } from "../../_store/loan";
import { useApplicationState, useFormStore } from "../../_store/useApplicationState";
import { YearMakeModelTrim } from "./YearMakeModelTrim";

export const BasicVehicleInformation = (props:IApplicationStep) => {
  const store = useApplicationState((s) => s.loan_details);
  const [application] = useFormStore(s => s.application_store);
  const loan_type = application.getState().loan_type;

  const vehicle_type = store(s => s.vehicle_type);

      const validate_mileage = (
        total: number | null,
        force: boolean,
        isDirty?: boolean
      ): ComponentValidationResult => {
        if (!force && !isDirty) {
          return [true];
        }
  
        if (!total) {
          return [false, () => "Mileage must be at least 1."];
        }
  
        if (total < 1) {
          return [false, () => "Mileage must be at least 1."];
        }
  
        return [true];
      };

  return (
    <>
      <YearMakeModelTrim
        store={(s) => s.loan_details_vehicle}
        isNew={vehicle_type === "NewVehicle"}
        step={props.step}
      />

      {loan_type !== "NewOrUsed" && (
        <MaskedInputComponent<
          Pick<IYearMakeModelTrim, "mileage">,
          number | null
        >
          formatter={number_formatter}
          inputMode="numeric"
          label="Mileage"
          maxLength={7}
          validate={validate_mileage}
          field_parser={(input) => parseIntSafe(input)}
          mask={onlyNumbersMask}
          testId="mileage"
          className="col"
          store={(s) => s.loan_details_vehicle}
          selector={(vehicle) => vehicle.mileage}
          step={props.step}
          min={1}
          max={9999999}
        />
      )}
    </>
  );
};
