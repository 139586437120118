// import info from "../assets/info-120.svg";
import { NewUsedLoan } from "../../my-autoloan-shared/loan-details/NewUsedLoan";
import { RefinanceLoan } from "../../my-autoloan-shared/loan-details/RefinanceLoan";
import { VehicleInformation } from "../../my-autoloan-shared/loan-details/VehicleInformation";
import { PrivatePartyLoan } from "../../my-autoloan-shared/loan-details/PrivatePartyLoan";
import { SellerInformation } from "../../my-autoloan-shared/loan-details/SellerInformation";
import { LeaseBuyoutLoan } from "../../my-autoloan-shared/loan-details/LeaseBuyoutLoan";
import { useApplicationState } from "../../_store/useApplicationState";
import { IApplicationStep, Steps } from "../../_store/application";
import { TradeInVehicleInformation } from '../../my-autoloan-shared/loan-details/TradeInVehicleInformation';
import { useScrollToElement } from "../../_helpers/useScrollToElement";

export const LoanDetailsStep = (props: IApplicationStep) => {
  const application_store = useApplicationState(s => s.application_store);
  const loan_type = application_store(s => s.loan_type);

  useScrollToElement("top");

  return (
    <>
      <div className="group-box" id="top">
        {/* <div className="pb-8 border-b info">
          <p className="text-sm">
            <img src={info} style={{ width: 40, marginBottom: "5px" }} />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>
        </div> */}

        <div className="content pt-responsive">
          {loan_type === 'NewOrUsed' && <NewUsedLoan store={s => s.loan_details} step={props.step} />}
          {loan_type === 'Refinance' && <RefinanceLoan store={s => s.loan_details} step={props.step}/>}
          {loan_type === 'PrivateParty' && <PrivatePartyLoan store={s => s.loan_details} step={props.step}/>}
          {loan_type === 'Buyout' && <LeaseBuyoutLoan store={s => s.loan_details } step={props.step}/>}

          <VehicleInformation  store={s => s.loan_details} step={props.step}/>
        </div>
      </div>

      <TradeInVehicleInformation step={Steps.loan} />

      <SellerInformation store={s => s.loan_details} step={props.step}/>
    </>
  );
};
