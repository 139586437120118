import { useState } from "react";
import { IFieldProps } from "./IFieldProps";
import { StoreField } from './StoreField';

import { IFieldError } from "./FieldLayoutComponent";
import { ITestableComponent } from "./ITestableComponent";
import { isNullOrWhite } from "../_helpers/isNullOrWhite";
import { IStoreSelector, useFormStore } from "../_store/useApplicationState";
import { StreetCityStateZip } from "../_store/address";
import { useFieldValidationRegistry } from "../_store/useFieldValidationRegistry";
import { StreetAddressAutoComplete } from "./StreetAddressAutoComplete";
import { StreetAddressType } from "../_store/address";

type StreetAddressComponentProps = IFieldProps<StreetAddressType> &
  ITestableComponent &
  IFieldError &
  IStoreSelector<StreetCityStateZip>;

const PO_BOX_REGEX = /\bP\.?\s*O\.?\s*Box\b/i;

export const StreetAddressComponent = (props: StreetAddressComponentProps) => {

  const [store, ] = useFormStore(props.store);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const validate = (value: string | undefined, force: boolean): [boolean] => {
    if (!force && !isDirty) {
      return [true];
    }

    if (isNullOrWhite(value)) {
      setShowError(() => true);
      setErrorMessage(() => props.errorMessage ?? "Street Address is required");
      return [false];
    }

    if (PO_BOX_REGEX.test(value!)) {
      setShowError(() => true);
      setErrorMessage(() => 'PO Box is not allowed.');
      return [false];
    }

    setShowError(() => false);
    setErrorMessage(() => "");
    return [true];
  };

  useFieldValidationRegistry(
    props.store,
    (address) => address.street,
    props.step,
    () => {
      const value = store.getState().street;
      return validate(value, true);
    }
  );

  const handleInputBlur = (value: string | undefined) => {
    validate(value, false);
    return value;
  };

  const handleOnChange = (value:StoreField<StreetCityStateZip>) => {
    if (isNullOrWhite(value)) {
      setIsDirty(true);
    }
    setShowError(false);
  };

  return (
    <div className="field-layout">
      <div className="flex flex-row h-6">
        <label>{props.label ?? "Street Address"}</label>
      </div>

      <StreetAddressAutoComplete
        {...props}
        onBlur={handleInputBlur}
        onChange={handleOnChange}
        step={props.step}
        store={props.store}
        selector={(s) => s.street}
        showError={showError}
      />

      {!showError && (
        <p className="sub-text" data-testid="field_helper_text">
          {props.helperText}
        </p>
      )}
      {showError && (
        <p className="sub-text sub-text-error" data-testid="field_error_text">
          {errorMessage}
        </p>
      )}
    </div>
  );
};




