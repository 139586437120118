export interface IDisclosureProps {
  testId: string;
  print?: boolean;
}

export const Disclosure = (props: IDisclosureProps) => {
  function printDiv(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    window.print();
  }

  return (
    <div
      className={`disclosure ${props.print ? "for-printing" : ""}`}
      data-testid={props.testId}
    >
      <div className="flex w-full justify-between items-end mb-2">
        <div>
          <h1>Electronic Records Disclosure</h1>
        </div>

        <div style={{ display: `${props.print ? "none" : "inline"}` }}>
          <a href="#" onClick={printDiv} className="print-button">
            Print
          </a>
        </div>
      </div>

      <p>
        {`You are submitting a request for a loan, financing or lease offers on a
        Horizon Digital Finance LLC web site property (collectively referred to
        herein as "HDF").`}
      </p>
      <p>
        {`The Electronic Signatures in Global and National Commerce Act requires
        your consent to receive certain disclosures electronically. Please read
        this Electronic Records Disclosure and Agreement ("Agreement") carefully
        and save or print a copy of the Agreement for your records.`}
      </p>
      <h3>ELECTRONIC RECORDS</h3>
      <p>
        {`All communications and disclosures with and by HDF on its behalf and on
        behalf of its lending partners, participating banks, finance companies,
        credit unions, and auto dealerships (hereinafter called “Lenders”) are
        conducted electronically. However, if you wish to obtain a paper copy of
        any HDF loan/finance/lease offer request disclosure or any other HDF
        disclosures relating to your offer and/or loan/finance/lease offer
        request is required to be in writing (the “Disclosures”), you may write
        to HDF, 5005 W. Royal Lane, Suite 209, Irving, Texas 75063. We will not
        charge you a fee for a paper copy of the Disclosures you request. HDF
        does not provide any Lenders disclosures.`}
      </p>
      <p>
        {`HDF reserves the right, in its sole discretion, to stop providing
        Disclosures electronically. HDF will provide you with notice of any such
        termination as required by applicable law. If HDF chooses to stop
        providing Disclosures electronically, HDF will send any future
        Disclosures to you in paper form.`}
      </p>
      <h3>YOUR CONSENT IS REQUIRED</h3>
      <p>
        {` The applicant and any co-applicant (collectively, “you”) must consent to
        receive Disclosures electronically in order to complete the
        loan/finance/lease offer request process with HDF on behalf of its
        participating Lenders (as defined above). Your consent will apply to
        Disclosures you receive from HDF, its successors and assignees, and any
        Lenders. You may withdraw your consent for receiving electronic
        Disclosures by notifying HDF at`}{" "}
        <a href="mailto:customer.service@horizondigitalfinance.com">
          customer.service@horizondigitalfinance.com
        </a>
        {`. Since HDF is structured to provide loan/finance/lease offer request
        related information electronically, not consenting, or withdrawing your
        consent to receive electronic Disclosures will terminate your
        loan/finance/lease offer request process. You may withdraw your consent
        to do business electronically with our participating Lenders at no cost
        to you. You may do so by contacting the Lender at the mailing address,
        e-mail address or telephone number they provide to you separately for
        such purpose.`}
      </p>
      <h3>HARDWARE AND SOFTWARE REQUIREMENTS</h3>
      <p>
        To access this Web Site and receive disclosures electronically, you must
        have the following:
      </p>
      <ul>
        <li>
          A personal computer or other access device which is capable of
          accessing the Internet and which can receive HTML files; and
        </li>
        <li>
          An Internet web browser which is capable of supporting 128-bit secure
          socket layer (SSL) encrypted communications, HDF supports the current
          browsers major release versions of Chrome, Firefox, Internet Explorer,
          and Safari; and backward compatibility for one version or major
          release back of those same browser; and
        </li>
        <li>128 bit SSL encryption software.</li>
      </ul>
      <h3>SYSTEM REQUIREMENTS TO RETAIN ELECTRONIC DISCLOSURES</h3>
      <p>
        To retain any electronic Disclosures or other information provided by
        HDF and its participating Lenders through this Web Site, your system
        must have the ability to either download to your hard drive, other
        memory device or print web pages as well as embedded HTML files.
      </p>
      <p>
        If the system requirements for accessing the Disclosures on this Web
        Site change so as to require different or additional hardware or
        software, or upgrades, plug-ins or enhancements to the hardware or
        software used by you (all such changes referred to as a “Systems
        Enhancement”), HDF will notify you and you may either withdraw your
        consent or upgrade your hardware and/or software as required by the
        Systems Enhancement. If you choose to withdraw your consent, or you fail
        to re-confirm your consent (which HDF will treat as your withdrawal of
        consent), you will no longer receive Disclosures electronically and HDF
        will send all future Disclosures to you in paper form.
      </p>
      <h3>UPDATING YOUR INFORMATION</h3>
      <p>
        You should keep HDF and any lender to which you apply informed of any
        change to your e-mail address or your physical address during the
        loan/finance/lease offer request process. You may notify HDF of these
        changes by e-mail at{" "}
        <a href="mailto:customer.service@horizondigitalfinance.com">
          customer.service@horizondigitalfinance.com
        </a>
        .
      </p>
      <h3>CONSENT TO RECEIVE DISCLOSURES ELECTRONICALLY</h3>
      <p>
        In order to use this service, you must consent to receive disclosures
        including but not limited to the GLB Privacy Notice (below) and any
        loan/finance offer request related disclosures and notices,
        electronically and you agree that these electronic disclosures will be
        sufficient as a “writing” under applicable law.
      </p>
      <p>
        <a
          href="https://resources.hdf-application.com/glb-privacy-notice.html"
          target="_blank"
          rel="noreferrer"
        >
          GLB Privacy Notice
        </a>
      </p>
      <p>Last Updated: 07/06/2020</p>
      <br />
      <hr />
      <br />
      <h1>HOW THE PROCESS WORKS</h1>
      <p>
        Horizon Digital Finance, LLC ("HDF") is not acting as a loan broker and
        does not make extensions of credit to consumers. HDF works with a select
        group of lending partners, such as banks, credit unions, finance
        companies and automotive dealerships (the “Lenders”) that offer loans
        and other types of financing directly to consumers (the “Financing”).
        HDF collects certain information from each consumer through this web
        site (the “Web Site”) to identify one or more Lenders that may want to
        provide, directly to you, with a pre-approved loan offer. The term “you”
        means a consumer using the Web Site. If HDF cannot identify Lenders for
        you at all, HDF will let you know through the Web Site, email and SMS
        text. If HDF can match you with one or more Lenders, HDF will also let
        you know through the Web Site, email and SMS text and let those Lenders
        know as well that you may be a match. This does not mean that you are
        approved for credit by any of those Lenders. Those Lenders will then
        review your information on their own and if you qualify for credit,
        return a pre-approved loan offer to you through the Web Site,
        additionally, they may contact you directly by phone, email and or text.
        Having those Lenders provide you with a pre-approved loan offers is not
        a binding agreement or contract. Pre-approved loan offers are subject to
        change or with-drawn as the Lender collect additional information in
        their review and underwriting process. Completing a loan offer request
        form on this Web Site may result in a soft inquiry by HDF and may also
        result in soft and or hard inquiry(s) by the Lender(s) depending on the
        Lender’s practices.
      </p>
      <p>
        HDF also makes certain information about you available to other
        third-party referral sources so that they may be able to assist you if
        you are purchasing an auto from an auto dealership and seeking financing
        (the “Credit Source”). Credit Sources may be brokers or other referral
        sources that work with auto dealers to facilitate your purchase and
        financing of a vehicle. Completing a loan offer request form on this Web
        Site may result in soft and or hard inquiry(s) by HDF and the Credit
        Sources. You will be contacted by the Credit Sources directly by phone,
        email and or text, but not through the Web Site. A current list of
        Lenders and Credit Sources to which your information may be sent is
        available by checking the{" "}
        <a
          href="http://resources.hdf-application.com/lender-and-credit-source-directory.html"
          target="_blank"
          rel="noreferrer"
        >
          Lender Directory
        </a>
        .
      </p>
      <p>
        The Lenders and Credit Sources are solely responsible for its services
        to you, and you agree that HDF shall not be liable for any damages or
        costs of any type arising out of or in any way connected with your use
        of such services. You understand that HDF, the Lenders and any Credit
        Sources may keep your information and any other information provided
        directly by you or obtained from a third party. You agree to notify any
        particular Lender or Credit Source directly if you no longer want to
        receive their communications and/or emails and text.
      </p>
      <p>
        HDF does not endorse or recommend the products or potential offers of
        any particular Lender or Credit Source. You should rely on your own
        judgment in deciding which available credit offer, product, terms and
        Lender, will best suit your needs and financial means.
      </p>
      <p>
        Cost and or Fees: HDF does not charge you a fee for the services that we
        provide. HDF does receive a marketing fee and in certain cases lead fees
        as compensation from the Lenders and or Credit Sources for the goods and
        services provided by HDF, and such compensation varies by product.
      </p>
      <p>Last updated 04/02/2025</p>
      <br />
      <hr />
      <br />
      <h1>PRE-QUALIFICATION AND LOAN/FINANCE OFFER RELATED DISCLOSURES</h1>
      <p>
        {` Horizon Digital Finance, LLC ("HDF") is not acting as a loan broker. HDF
        is not acting as a lender, bank, credit union, finance company or
        automobile dealer (collectively, “Lender”) related to this request for a
        loan/finance offer for automobile financing, refinancing and/or purchase
        (each, a “Financing”). The HDF web site ("Web Site") facilitates a
        consumer’s request for loan/finance offers process through select
        Lenders and other credit aggregators (each, a "Credit Source") as shown
        on the`}{" "}
        <a
          href="http://resources.hdf-application.com/lender-and-credit-source-directory.html"
          target="_blank"
          rel="noreferrer"
        >
          Lender Directory
        </a>
        {`. The term you shall be referred to as "you". When you complete a loan
        offer request, in order to submit through the Web Site, you will
        authorize and provide consent for HDF to share your information with
        these Lenders and Credit Sources.`}
      </p>
      <p>
        Important information about procedures for requesting a loan/finance
        offer and for opening a new account. To help the government fight the
        funding of terrorism and money laundering activities, Federal law
        requires all financial institutions to obtain, verify and record
        information that identifies each person who opens an account. What this
        means to you: When you request loan/finance offers for or open an
        account, we will ask for your name, address, date of birth and other
        information that will allow us to identify you.
      </p>
      <h3>Consent And Authorization For A Credit Bureau Report</h3>
      <ul>
        <li>
          All statements made in this pre-qualification are true and correct,
          and are made for the purpose of obtaining Financing;
        </li>
        <li>
          You are at least 18 years of age (or such other age of majority
          applicable to your state of residence);
        </li>
        <li>
          {`Authorization is given to HDF, the Lenders, and Credit Sources to
          obtain my credit report in connection with this pre-qualification
          loan/finance offer request form, any credit application or transaction
          resulting from the form, and for reviewing any resulting account,
          taking collection action, or any other permissible purpose under the
          Federal Fair Credit Reporting Act. HDF acquires a "soft" credit
          inquiry, Lenders and Credit Sources may acquire "soft" or "hard"
          credit inquiry(s);`}
        </li>
        <li>
          Authorization is given to HDF, the Lenders and the Credit Sources to
          use and share the information you provide to HDF as the law allows in
          order to process your request for credit;
        </li>
        <li>
          By submitting your information, you give your consent for HDF to share
          information about your request with the Lenders and Credit Sources,
          and authorize HDF to post any decision about Financing by the Lenders
          on the Web Site for your review;
        </li>
        <li>
          You have reviewed the list of Lender and Credit Sources on the{" "}
          <a
            href="http://resources.hdf-application.com/lender-and-credit-source-directory.html"
            target="_blank"
            rel="noreferrer"
          >
            Lender Directory
          </a>
          ;
        </li>
        <li>
          HDF and the Lenders make no representations or warranties, express or
          implied, to any applicant regarding the value, condition or
          identification of the vehicle to be purchased;
        </li>
      </ul>
      <p>Last Updated: 07/26/2023</p>
      <br />
      <hr />
      <br />
      <h1>STATE LAW APPLICATION DISCLOSURES</h1>
      <p>
        “You” means the applicant. “We” or “us” means the creditor described
        above.
      </p>
      <h4>Notice To California Customers</h4>
      <p>A Married applicant may apply for separate account.</p>
      <h4>Notice To New York Customers</h4>
      <p>
        In connection with your application for credit, a consumer report may be
        obtained which contains information on your credit worthiness. If you
        request, you will be informed whether a consumer report was obtained and
        if so, you will be given the name and address of the consumer reporting
        agency which furnished the report. If your application is granted,
        subsequent consumer reports may be obtained in connection with any
        update, extension or renewal of credit.{" "}
      </p>
      <h4>Notice To Ohio Customers</h4>
      <p>
        The Ohio laws against discrimination require that all creditors make
        credit equally available to all creditworthy customers, and that credit
        reporting agencies maintain separate credit histories on each individual
        upon request. The Ohio civil rights commission administers compliance
        with this law.
      </p>
      <h4>Notice To Rhode Island Customers</h4>
      <p>
        Consumer reports may be requested in connection with this application.
      </p>
      <h4>Notice To Vermont Customers</h4>
      <p>
        You grant permission to us to obtain information on you, including a
        credit reports, in connection with an evaluation of your
        creditworthiness. If an account is created, you authorize the obtaining
        of credit reports for purposes of reviewing, servicing, modifying,
        extending or taking collection action on the account, or for other
        legitimate purposes associated with the account.
      </p>
      <h4>Notice To Wisconsin Customers</h4>
      <p>
        The interest of the creditor will not be adversely affected by a
        provision of a marital property agreement, a unilateral statement under
        Wisconsin Statutes §766.59 or a court decree under Wisconsin Statutes
        §766.70, unless you furnish a copy of such agreement, statement or
        decree to the creditor, or the creditor has actual knowledge of such
        provision before credit is granted.
      </p>
      <p>Last updated 11/08/2022</p>
      <br />
      <hr />
      <br />
      <h1>HDF LICENSE INFORMATION</h1>
      <h4>For California Applicants</h4>
      <p>
        <strong>
          Loans arranged pursuant to a California Financing Law license.
        </strong>
        <br />
      </p>
      <p>
        California Department of Financial Protection and Innovation (DFPI),
        California Finance Lenders License No. 603-9699 with principal office
        located at:
        <br />
        Horizon Digital Finance, LLC <br />
        5005 W Royal Lane, Irving TX 75063 <br />
      </p>
      <p>
        IF YOU HAVE ANY QUESTIONS ABOUT YOUR RIGHTS, OR IF YOU WISH TO FILE A
        COMPLAINT, CONTACT THE MANAGEMENT OF THE FINANCIAL INSTITUTION OR:
      </p>
      <h4>Department of Financial Protection and Innovation (DFPI):</h4>
      <p>
        2101 Arena Boulevard
        <br />
        Sacramento, CA 95834
        <br />
        Phone: (916) 576-4941
      </p>
      <p>
        320 West 4th Street, Suite 750
        <br />
        Los Angeles, CA 90013-2344
        <br />
        Phone: (213) 576-7500
      </p>
      <p>
        300 S. Spring Street, Suite 15513
        <br />
        Las Angeles, CA 90013
        <br />
        Phone: (213) 897-2085
      </p>
      <p>
        1515 K Street, Suite 200
        <br />
        Sacramento, CA 95814-4052
        <br />
        Phone (916) 445-7205
      </p>
      <p>
        1350 Front Street, Room 2034
        <br />
        San Diego CA 92101-3697
        <br />
        Phone (619) 525-4233
      </p>
      <p>
        One Samsome Street, Suite 600
        <br />
        San Francisco, CA 94104-4428
        <br />
        Phone (415) 972-8565
      </p>
      <p>
        7575 Metropolitan Drive, Suite 108
        <br />
        San Diego, CA 92108
        <br />
        Phone (619) 682-7227
      </p>
      <p>
        Toll-Free Phone (all locations) (866) 275-2677 Website{" "}
        <a href="http://www.dbo.ca.gov" target="_blank" rel="noreferrer">
          http://www.dbo.ca.gov
        </a>
      </p>
      <h4> For Ohio Applicants</h4>
      <p>
        Ohio Department of Commerce, Division of Financial Institutions;
        Certificate No. CI.550146.000 with principal office located at:
        <br />
        Horizon Digital Finance, LLC
        <br />
        5005 W Royal Lane, Irving TX 75063
        <br />
      </p>

      <p>
        Division of Financial Institutions 77 South High Street, 23rd Floor.
        <br />
        Columbus, OH 43215-6133{" "}
        <a href=" https://com.ohio.gov/home" target="_blank" rel="noreferrer">
          {" "}
          https://com.ohio.gov/home
        </a>{" "}
        Phone: (614) 728-8400 <br />
        Email: web.dfi@com.ohio.gov Email Office of Consumer Affairs: <br />
        webdfi-oca@com.ohio.gov Email Consumer Finance: webdfi-cf@com.ohio.gov{" "}
        <br />
      </p>

      <h4>For Texas Applicants</h4>
      <p>
        The Office of Consumer Credit Commissioner, State of Texas License No.
        9045-42905 with principal office located at:
        <br />
        Horizon Digital Finance, LLC
        <br />
        5005 W Royal Lane, Irving TX 75063
      </p>
      <p>
        The Office of Consumer Credit Commissioner (OCCC)
        <br />
        2601 N. Lamar Blvd.
        <br />
        Austin, Texas 78705.
      </p>
      <p>
        Direct general inquiries to 512.936.7600
        <br />
        <a href="https://occc.texas.gov" target="_blank" rel="noreferrer">
          occc.texas.gov
        </a>
        <br />
        Toll-free Consumer Helpline number is 800.538.1579
      </p>
      <p>Last updated 07/18/2024</p>
      <br />
      <hr />
      <br />
      <h1>Regulation B Disclosures</h1>
      <ol>
        <li>
          <p>
            {` Under the Equal Credit Opportunity Act (ECOA), HDF and its Lenders
            are prohibited from discriminating in any aspect of the credit
            transaction. Prohibited bases are: sex, marital status, race, color,
            religion, national origin, age (provided the applicant has the
            capacity to enter into a binding contract), the fact that all or
            part of the applicant's income is derived from any public assistance
            program and the fact that the applicant has, in good faith,
            exercised any right under the Consumer Protection Act (i.e. the
            right to lodge a complaint).`}
          </p>
        </li>
        <li>
          <p>
            Alimony, child support, or separate maintenance income need not be
            revealed if you do not choose to have it considered as a basis for
            repaying this obligation.
          </p>
        </li>

        <p data-testid="bottom">Last Updated: 01/30/2023</p>
      </ol>
    </div>
  );
};
