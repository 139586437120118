import { useRef, useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {
  // FieldValidationAsyncFunction,
  // FieldValidationFunction,
  useFieldValidationRegistry,
} from "../../../_store/useFieldValidationRegistry";
import { IApplication, Steps } from "../../../_store/application";
import {
  // IFieldSelector,
  useFormStore,
} from "../../../_store/useApplicationState";
import { datadogRum } from "@datadog/browser-rum";
// import { StoreField } from "../../../_components/StoreField";

// type CaptchaComponentProps = IFieldSelector<
//   IApplication,
//   StoreField<IApplication>
// >;

export const CaptchaComponent = () => {
  const captchaRef = useRef<HCaptcha>(null);
  const [showError, setShowError] = useState(false);

  const [store, set] = useFormStore<IApplication>(s => s.application_store);
  const token_setter = set(s => s.captcha);
  const token_challenged_setter = set(s => s.captcha_challenged);


  useFieldValidationRegistry(s => s.application_store, s => s.captcha, Steps.account, () => {
    const token = store.getState().captcha;
    if (token && token !== "") {
      setShowError(false);
      return [true];
    }

    setShowError(true);
    return [false, () => "Please complete hCaptcha."];
  });

  const site_key = import.meta.env.VITE_HCAPTCHA_SITE_KEY;

  return (
    <div className="mt-4">
      <HCaptcha
        ref={captchaRef}
        sitekey={site_key}
        onVerify={(token) => {
          if (token && token !== "") {
            setShowError(false);
            token_setter(token);
          }
        }}

        onOpen={() => {
          token_challenged_setter(true);
          datadogRum.addAction('[captcha_challenge]', { challenged: true });
          console.log('[captcha_challenge]:', { challenged: true });
        }}
      />
      {showError && (
        <p className="sub-text sub-text-error" data-testid="field_error_text">
          Please complete hCaptcha.
        </p>
      )}
    </div>
  );
};
