import { SelectComponent } from "./SelectComponent";
import { isValidRelationship } from "../_helpers/validations";
import { Account, Relationship } from "../_store/account";
import {
  IStoreSelector,
  useApplicationState,
} from "../_store/useApplicationState";
import { IApplicationStep } from "../_store/application";
import { ComponentValidationResult } from "./IFieldProps";

export const RelationshipComponent = (props: IStoreSelector<Account> & IApplicationStep) => {
  const { getState: getter } = useApplicationState((s) => s.joint_account);
  const { getState: address_getter } = useApplicationState(
    (s) => s.primary_current_address
  );

  const validate = (
    force: boolean,
    isDirty: boolean | undefined
  ): ComponentValidationResult => {
    if (!force && !isDirty) {
      return [true];
    }

    const { relationship } = getter();
    if (!relationship) {
      return [false, () => "Relationship is required."];
    }

    const { state } = address_getter();
    const valid = isValidRelationship(relationship, state!);
    if (!valid) {
      return [
        false,
        () => "Relationship is not allowed in primary applicant's state.",
      ];
    }

    return [true];
  };

  const relationshipKeys: string[] = Object.keys(Relationship);
  const relationshipOptions = relationshipKeys.map((x) => {
    const key: keyof typeof Relationship = x as keyof typeof Relationship;
    return (
      <option key={key} value={Relationship[key]}>
        {key.replace("_", " ")}
      </option>
    );
  });

  return (
    <SelectComponent<Pick<Account, "relationship">, Relationship | undefined>
      label="Relationship"
      className="col"
      validate={(_, force, isDirty) => {
        return validate(force, isDirty);
      }}
      store={props.store}
      selector={(account) => account.relationship}
      step={props.step}
      
    >
      <option></option>
      {relationshipOptions}
    </SelectComponent>
  );
};
