// import { IMonthsYears } from "../_store/IMonthsYears";
import { parseIntSafe } from "./isNullOrWhite";

// export const calculateTotalMonths = (
//   // getter: <K extends keyof IMonthsYears>(prop: K) => Pick<IMonthsYears, K>
//   months: string,
//   years: string
// ): number | undefined => {



//   return calculateTotalMonthsWithValues(years, months);
// };

export const calculateTotalMonthsWithValues = (years?: string, months?: string) => {
  if (!years || !months) {
    return undefined;
  }

  const yearsInt = parseIntSafe(years);
  const monthsInt = parseIntSafe(months);
  return yearsInt * 12 + monthsInt;
}