import { isNullOrWhite } from "../../_helpers/isNullOrWhite";

interface VehicleOptions {
  years: string[];
  makes: { make: string; hh_code: string; }[];
  models: string[];
  trims: string[];
}

export const fetchVehicleOptions = async (
  isNew: boolean,
  year?: string,
  make?: string,
  model?: string
): Promise<VehicleOptions> => {
  const params = `isNew=${isNew}${isNullOrWhite(year) ? '' : `&year=${year}`}${isNullOrWhite(make) ? '' : `&make=${make}`}${isNullOrWhite(model) ? '' : `&model=${model}`}`;

  const result = await fetch(`${import.meta.env.VITE_MAL_API_URL}/vehicles?${params}`, {
    method: 'GET',
  });

  const json = await result.json();
  return json;
};
