export async function validateEmail(
  email: string, first_name: string, last_name: string, zip: string
): Promise<Response> {
  return await post({ email, first_name, last_name, zip }, '/profile/validate/email');
}

export async function validateSsn(ssn: string): Promise<Response> {
  return await post({ ssn }, '/profile/validate/ssn');
}

export async function validatePhone(phone: string): Promise<Response> {
  return await post({ phone }, '/profile/validate/phone');
}

export async function validateVulgarField(field: string): Promise<Response> {
  return await post({ field }, '/profile/validate/vulgar');
}

export async function validateName(first_name: string, last_name: string): Promise<Response> {
  return await post(
    { first_name, last_name },
    "/profile/validate/name"
  );
}

export interface ValidationResponseDto {
  is_valid: boolean;
  error_message: string;
}

async function post(body: object, url: string): Promise<Response> {
  return await fetch(`${import.meta.env.VITE_MAL_API_URL}${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });
}