import { useEffect, useState, useRef } from "react";
import { registerCursorTracker } from 'cleave-zen'

import { IFieldProps } from "./IFieldProps";
import { IFieldError } from "./FieldLayoutComponent";

import {
  IFieldSelector,
  Stores,
  useFormStore,
} from "../_store/useApplicationState";
import { InputModeType } from "./InputModeType";


type MaskedInputElementProps<
  TStore extends Stores,
  TField extends TStore[keyof TStore]
> = IFieldProps<TStore, TField> & 
  IFieldError & 
  IFieldSelector<TStore, TField> & {
    formatter: (input:string) => string
  }
  & {
    inputMode: InputModeType;
  };

export const MaskedInputElement = <
  TStore extends Stores,
  TField extends TStore[keyof TStore]
>(
  props: MaskedInputElementProps<TStore, TField>
) => {
  const [value_as_string, set_value_as_string] = useState("");
  const [store, set] = useFormStore<TStore>(props.store);
  const setter = set(props.selector);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return registerCursorTracker({
      input: inputRef.current!,
      // TODO: improve this...
      // passing delimiter as prop acts weird
      delimiters: ['/'],
    });
  }, []);

  //this value is reactive
  const value = store(props.selector);
  useEffect(() => {
    // FIXME: need inverse parser? to_string?
    const as_string = props.to_string
      ? props.to_string(value)
      : value?.toString() ?? "";
    
    // TODO: make better
    // Using null to signal - do nothing, field is in progress
    if (as_string === null) {
      return;
    }

    if (as_string === "") {
      set_value_as_string("");
      return;
    }

    const masked = props.formatter ? props.formatter(as_string) : as_string;
    set_value_as_string(masked);

  }, [props, value]);

  // // TODO: Extract
  // const [inputType, setInputType] = useState(props.type ?? "text");
  // const onEyeClick = (event: React.MouseEvent<SVGSVGElement>) => {
  //   if (inputType === "password") {
  //     setInputType("text");
  //     event.currentTarget.style.fill = props.passwordEyeColor ?? "black";
  //   } else {
  //     setInputType("password");
  //     event.currentTarget.style.fill = "#9ca3af";
  //   }
  // };

  return (
    <span className="flex items-center flex-row">
      <input
        ref={inputRef}
        type="text"
        inputMode={props.inputMode ?? "none"}
        min={props.min ?? undefined}
        max={props.max ?? undefined}
        className={`
            ${
              props.showError //|| showInvalid
                ? "focus:ring focus:ring-rose-400 focus:border-error-950 border-error-950 border-2"
                : "border-[#d3d3d3] focus:border-[#d3d3d3] focus:ring-gray-600"
            } form-input-field
            ${props.disabled ? "text-[#535353] opacity-40" : ""}
                `}
        value={value_as_string}
        placeholder={props.placeholder}
        maxLength={props.maxLength ?? 50}
        // TODO: use context to generate id
        // data-testid="field_input"

        disabled={props.disabled}
        autoComplete={props.autoComplete ?? "do-not-autofill"}
        onChange={(e) => {
          const value = e.target.value;

          const masked = props.formatter(value);

          set_value_as_string(masked);

          const parsed = props.field_parser
            ? props.field_parser(masked)
            : (masked as TField);

          setter(parsed);

          if (props.onChange) {
            props.onChange(parsed);
          }

        }}
        onKeyDown={(e) => {
          props.onKeyDown?.(e);
        }}
        onBlur={(e) => {
          const parsed = props.field_parser
            ? props.field_parser(e.target.value)
            : (e.target.value as TField);

          if (props.onBlur) {
            props.onBlur(parsed, true);
          }

        }}
      />
    </span>
  );
};
