import { IApplicationStep } from "../../../_store/application";
import { useApplicationType } from "../../../_store/useApplicationType";
import { CurrentEmployment } from "./CurrentEmployment";

export const JointCurrentEmployment = (
  props: IApplicationStep & {
    label?: string;
  }
) => {
  const application_type = useApplicationType();

  return (
    <>
      {application_type === "Joint" && (
        <CurrentEmployment
          label={"Co-Applicant Employment"}
          store={(s) => s.joint_current_employment}
          step={props.step}
          applicant={'joint'}
        />
      )}
    </>
  );
};
