import { calculateTotalMonthsWithValues } from "../_helpers/calculateTotalMonths";
import { Employment } from "./employment";
import { ApplicationStateSelector, useApplicationState } from "./useApplicationState";

export const MIN_MONTHS_AT_EMPLOYMENT = 6;

export const useRequiresPreviousEmployment = (
  selector: ApplicationStateSelector<Employment>
) => {
  const current_employment = useApplicationState(selector);
  const years = current_employment(employment => employment.years);
  const months = current_employment(employment => employment.months);
  const total_months = calculateTotalMonthsWithValues(years, months);
  
  return total_months
    ? total_months < MIN_MONTHS_AT_EMPLOYMENT
    : false;
}