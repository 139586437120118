import { useState } from "react";
import { FieldLayoutComponent } from "../_components/FieldLayoutComponent";
import { IApplication, LoanType } from "../_store/application";
import { IFieldProps } from "../_components/IFieldProps";
import { useApplicationState } from "../_store/useApplicationState";
import { useFieldValidationRegistry } from "../_store/useFieldValidationRegistry";
import { isNullOrWhite } from "../_helpers/isNullOrWhite";

export const LoanTypeComponent = (
  props: IFieldProps<Pick<IApplication, "loan_type">>
) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const application_store = useApplicationState((s) => s.application_store);
  // this is reactive
  const loan_type = application_store((app) => app.loan_type);

  const validate = (): [boolean] => {
    // this is non reactive
    const value = application_store.getState().loan_type;

    const isValid = !isNullOrWhite(value);
    if (!isValid) {
      setErrorMessage(() => "Please select a Loan Type.");
      setShowError(() => true);
      return [false];
    }

    setErrorMessage(() => "");
    setShowError(() => false);
    return [true];
  };

  useFieldValidationRegistry(
    (s) => s.application_store,
    (app) => app.loan_type,
    props.step,
    () => {
      return validate();
    }
  );

  const handleOnChange = (loan_type: LoanType) => {
    application_store.setState({ loan_type });
    setErrorMessage("");
    setShowError(false);
  };

  return (
    <>
      <FieldLayoutComponent
        label={props.label}
        showError={showError}
        errorMessage={errorMessage}
      >
        <div className="inline-radio">
          <label htmlFor="loan_type_new">
            <input
              id={"loan_type_new"}
              type="radio"
              checked={loan_type === "NewOrUsed"}
              name="loan-type"
              onChange={() => handleOnChange("NewOrUsed")}
            />
            {`New or Used`}
          </label>

          <label htmlFor="loan_type_refi">
            <input
              id={"loan_type_refi"}
              type="radio"
              name="loan-type"
              checked={loan_type === "Refinance"}
              onChange={() => handleOnChange("Refinance")}
            />
            {`Refinance`}
          </label>

          <label htmlFor="loan_type_private">
            <input
              id={"loan_type_private"}
              type="radio"
              name="loan-type"
              checked={loan_type === "PrivateParty"}
              onChange={() => handleOnChange("PrivateParty")}
            />
            {`Private Party`}
          </label>

          <label htmlFor="loan_type_buyout">
            <input
              id={"loan_type_buyout"}
              type="radio"
              name="loan-type"
              checked={loan_type === "Buyout"}
              onChange={() => handleOnChange("Buyout")}
            />
            {`Lease Buyout`}
          </label>
        </div>
      </FieldLayoutComponent>
      <div className="flex flex-row items-start">
        <svg
          width="18"
          height="18"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2 flex items-center cursor-pointer"
        >
          <g id="info">
            <path
              id="Vector"
              d="M7.3335 4.66658H8.66683V5.99992H7.3335V4.66658ZM7.3335 7.33325H8.66683V11.3333H7.3335V7.33325ZM8.00016 1.33325C4.32016 1.33325 1.3335 4.31992 1.3335 7.99992C1.3335 11.6799 4.32016 14.6666 8.00016 14.6666C11.6802 14.6666 14.6668 11.6799 14.6668 7.99992C14.6668 4.31992 11.6802 1.33325 8.00016 1.33325ZM8.00016 13.3333C5.06016 13.3333 2.66683 10.9399 2.66683 7.99992C2.66683 5.05992 5.06016 2.66659 8.00016 2.66659C10.9402 2.66659 13.3335 5.05992 13.3335 7.99992C13.3335 10.9399 10.9402 13.3333 8.00016 13.3333Z"
              fill="#009C96"
            />
          </g>
        </svg>
        {loan_type === "NewOrUsed" && (
          <div className="text-sm">
            Buying a car from a franchised dealership.
          </div>
        )}
        {loan_type === "Refinance" && (
          <div className="text-sm">Refinance my existing auto loan.</div>
        )}
        {loan_type === "PrivateParty" && (
          <div className="text-sm">
            Buying a car from an individual seller other than a dealership.
          </div>
        )}
        {loan_type === "Buyout" && (
          <div className="text-sm">Buying the car you have been leasing.</div>
        )}
      </div>
    </>
  );
};
