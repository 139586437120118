import { IApplicationStep } from '../../_store/application';
import { useApplicationState } from '../../_store/useApplicationState';
import { YearMakeModelTrim } from './YearMakeModelTrim';

// TODO: Not sure if we need this wrapper...
export const TradeInVehicleInformation = (props: IApplicationStep) => {
  const application_store = useApplicationState(s => s.application_store);
  const loan_type = application_store(s => s.loan_type);
  const loan_details = useApplicationState(s => s.loan_details);
  const loan_details_trade_in = useApplicationState(s => s.loan_details_trade_in);

  const trade_in = loan_details(loan => loan.trade_in);
  loan_details_trade_in.setState({ mileage: 0 });

  return (
    loan_type === 'NewOrUsed' && trade_in === "WithTradeIn" && (
      <div className="group-box">
        <h2>Trade In Vehicle</h2>
        <div className="content">
          <div className="grid margin-responsive">
            <YearMakeModelTrim
              store={s => s.loan_details_trade_in }
              isNew={false}
              step={props.step}
            />
          </div>
          <div className="info"></div>
        </div>
      </div>
    )
  );
};