export const isNullOrWhite = (input?: unknown) => {

  if (input === null || input === undefined) {
    return true;
  }

  if (input?.toString().trim() === '') {
    return true;
  }

  return false;
};


export const parseIntSafe = (input?: string):number => {
  if (isNullOrWhite(input)) {
    return 0;
  }

  const safe = parseInt(input ?? '0', 10);
  if (isNaN(safe)) {
    return 0;
  }
  return safe;
}