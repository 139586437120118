import { useApplicationProgress, useSubscribeApplicationState } from "../useApplicationProgress";
import "./ProgressBar.css";

export const ProgressBar = () => {
  const { progress } = useApplicationProgress();
  useSubscribeApplicationState();

  return (
    <>
      <div className="progress-bar" data-testid="progress-bar">
        {progress > 0 && (
          <div
            style={{
              flex: `${progress}%`,
              transition: "flex-basis 75ms ease-in-out",
            }}
            className="pr-2 text-right align-middle leading-7 bg-[#009C96] text-white"
            data-testid="percent_complete"
          >
            {Math.trunc(progress)}%
          </div>
        )}
        <div
          style={{
            flex: `${100 - progress}%`,
            transition: "flex-basis 75ms ease-in-out",
          }}
          className="text-center align-middle leading-7 bg-[#E8E8E1] text-white"
          data-testid="percent_incomplete"
        ></div>
      </div>
    </>
  );
};
