import { IApplicationStep } from "../_store/application";
import { CommunityPropertyState } from "./CommunityPropertyState";
import { useRequiresCommunity } from "../_store/useResidenceRequirements";

export const PrimaryCommunity = (props: IApplicationStep) => {
  const primary_requires_community = useRequiresCommunity(
    (s) => s.primary_current_address
  );

  return (
    <>
      {primary_requires_community && (
        <CommunityPropertyState
          store={(s) => s.primary_community}
          step={props.step}
        />
      )}
    </>
  );
};
