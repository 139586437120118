
import { IApplicationStep } from "../../../_store/application";
import { useRequiresPreviousEmployment } from "../../../_store/useEmploymentRequirements";
import { PreviousEmployment } from './PreviousEmployment';


export const PrimaryPreviousEmployment = (props: IApplicationStep) => {
  const requiresPrimaryPreviousEmployment = useRequiresPreviousEmployment(
    (s) => s.primary_current_employment
  );

  return (
    <>
      {requiresPrimaryPreviousEmployment && (
        <PreviousEmployment
          store={(s) => s.primary_previous_employment}
          step={props.step}
          applicant="primary"
        />
      )}
    </>
  );
};
