import { useState } from "react";
import {
  FieldLayoutComponent,
  IFieldError,
  IFieldLayoutProps,
} from "./FieldLayoutComponent";
import { isNullOrWhite } from "../_helpers/isNullOrWhite";
import { IMonthsYears } from "../_store/IMonthsYears";
import { calculateTotalMonthsWithValues } from "../_helpers/calculateTotalMonths";
import { InputElement } from "./InputElement";
import { IStoreSelector, useFormStore } from "../_store/useApplicationState";
import { IFieldProps } from "./IFieldProps";
import { twoDigits } from "../_helpers/Masks";
import { useFieldValidationRegistry } from "../_store/useFieldValidationRegistry";

type MonthYearsComponentProps = IFieldError &
  IFieldLayoutProps & {
    minimumValueErrorMessage?: string;
    onChange?: (months: number) => void;
  } & IFieldProps<IMonthsYears> &
  IStoreSelector<IMonthsYears>;

const MAX_YEARS = "99";
const MAX_MONTHS = "11";

export const MonthsYearsComponent = (props: MonthYearsComponentProps) => {

  const [store, set] = useFormStore(props.store);
  const months_setter = set((s) => s.months);
  const years_setter = set((s) => s.years);

  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const validate = (
    force: boolean,
    isDirty: boolean,
    months: string,
    years: string
  ): [boolean] => {
    if (!isDirty && !force) {
      setShowError(() => false);
      setErrorMessage(() => "");
      return [true];
    }

    if (isNullOrWhite(months) || isNullOrWhite(years)) {
      setShowError(() => true);
      setErrorMessage(
        () => props.errorMessage ?? "Please select years and months."
      );
      setIsDirty(() => true);
      return [false];
    }

    const total = calculateTotalMonthsWithValues(years, months);

    if (total === 0) {
      setShowError(() => true);
      setErrorMessage(
        () =>
          props.minimumValueErrorMessage ?? "Please select at least one month."
      );
      setIsDirty(() => true);
      return [false];
    }

    setShowError(() => false);
    setErrorMessage(() => "");
    return [true];
  };

  useFieldValidationRegistry(props.store, "months_years", props.step, () => {
    const {months, years} = store.getState();
    return validate(true, isDirty, months, years);
  });


  return (
    <FieldLayoutComponent
      {...props}
      label={props.label}
      showError={showError}
      errorMessage={errorMessage}
      className="month-years"
    >
      <div className="flex items-baseline flex-row mt-2">
        <InputElement<Pick<IMonthsYears, "years">, string>
          {...props}
          type="number"
          inputMode="numeric"
          min="0"
          max={MAX_YEARS}
          label=""
          errorMessage=""
          showError={showError}
          onChange={(years) => {
            const { months } = store.getState();
            if (!isNullOrWhite(years) && isNullOrWhite(months)) {
              months_setter("00");
            }
            setIsDirty(() => true);

            // need the latest months again...
            const latest_months = store.getState().months;
            validate(false, true, latest_months, years);
          }}
          store={props.store}
          selector={(input) => input.years}
          mask={(years) => {

            if(Number(years) < 0) {
              return twoDigits(0);
            }

            if (Number(years) > Number(MAX_YEARS)) {
              return MAX_YEARS;
            }
            return twoDigits(years);
          }}
          onBlur={(value) => {
            const { months } = store.getState();
            validate(false, isDirty, months, value);
          }}
        />
        <label className="mx-2">Years</label>
        <InputElement<Pick<IMonthsYears, "months">, string>
          {...props}
          type="number"
          inputMode="numeric"
          min="0"
          max="11"
          label=""
          errorMessage=""
          showError={showError}
          onChange={(months) => {
            const { years } = store.getState();
            if (!isNullOrWhite(months) && isNullOrWhite(years)) {
              years_setter("00");
            }
            setIsDirty(() => true);
            
            // need latest years
            const latest_years = store.getState().years;
            validate(false, true, months, latest_years);
          }}
          store={props.store}
          selector={(input) => input.months}
          mask={(months) => {
            if(Number(months) < 0) {
              return twoDigits(0);
            }

            if (Number(months) > Number(MAX_MONTHS)) {
              return MAX_MONTHS;
            }
            return twoDigits(months);
          }}
          onBlur={(value) => {
            const { years } = store.getState();
            validate(false, isDirty, value, years);
          }}
          className="w-full grow"
        />
        <label className="ml-2">Months</label>
      </div>
    </FieldLayoutComponent>
  );
};
