import { match, P } from "ts-pattern";
import { useApplicationState } from "../_store/useApplicationState";
import { Address } from "../_store/address";
import { Employment } from "../_store/employment";
import { requiresPreviousAddress } from "../_store/useResidenceRequirements";
import { LeaseBuyoutLoan, NewOrUsedLoan, PrivatePartyLoan, RefinanceLoan } from "../_store/loan";
import { calculateTotalMonthsWithValues } from "../_helpers/calculateTotalMonths";

// TODO: not quite happy with this yet...
const match_requirements = (
  current_residence: Address,
  current_employment: Employment,
  requires_previous_residence?: boolean,
  previous_residence?: Address,
  requires_previous_employment?: boolean,
  previous_employment?: Employment
) => {

  const residence = match(requires_previous_residence)
    .with(P.union(false, undefined), () => {
      const total_months = calculateTotalMonthsWithValues(
        current_residence.years,
        current_residence.months
      );
      return {
        "$type": "Current",
        current_residence: {
          "$type": "Current",
          ...current_residence,
          total_months
        },
      };
    })
    .with(true, () => {
      const current_total_months = calculateTotalMonthsWithValues(
        current_residence.years,
        current_residence.months
      );

      const previous_total_months = calculateTotalMonthsWithValues(
        previous_residence?.years,
        previous_residence?.months
      );

      return {
        "$type": "CurrentAndPrevious",
        current_residence: {
          "$type": "Current",
          ...current_residence,
          total_months: current_total_months
        },
        previous_residence: {
          "$type": "Previous",
          ...previous_residence,
          total_months: previous_total_months
        },
      };
    })
    .exhaustive();

  const employment = match(requires_previous_employment)
    .with(P.union(false, undefined), () => {
      const total_months = calculateTotalMonthsWithValues(
        current_employment.years,
        current_employment.months
      );
      return {
        "$type": "Current",
        current_employment: {
          "$type": "Current",
          ...current_employment,
          total_months
        },
      };
    })
    .with(true, () => {
      const current_total_months = calculateTotalMonthsWithValues(
        current_employment.years,
        current_employment.months
      );

      const previous_total_months = calculateTotalMonthsWithValues(
        previous_employment?.years,
        previous_employment?.months
      );

      return {
        "$type": "CurrentAndPrevious",
        current_employment: {
          "$type": "Current",
          ...current_employment,
          total_months: current_total_months
        },
        previous_employment: {
          "$type": "Previous",
          ...previous_employment,
          total_months: previous_total_months
        },
      };
    })
    .exhaustive();

  return [residence, employment];
};

export const useSubmitPayload = () => {
  // Only use getters, no need to be reactive

  const {
    application_store,
    loan_details,
    loan_details_vehicle,
    loan_details_trade_in,

    primary_account,
    primary_current_address,
    primary_previous_address,
    primary_current_employment,
    primary_previous_employment,

    joint_account,
    joint_current_address,
    joint_previous_address,
    joint_current_employment,
    joint_previous_employment,
  } = useApplicationState();

  const build = () => {
    const { application: application_type } = application_store.getState();

    const primary_requires_previous_residence = requiresPreviousAddress(
      primary_current_address.getState()
    );
    const joint_requires_previous_residence = false;
    const primary_requires_previous_employment = false;
    const joint_requires_previous_employment = false;

    const primary_account_payload = match(application_type)
      .with("Individual", () => {
        const {
            first_name,
            last_name,
            phone,
            email,
            birthdate,
            ssn,
            password
        } = primary_account.getState();

        return {
          first_name,
          last_name,
          phone,
          email,
          birthdate,
          ssn,
          password
        }
      }        
      )
      .with("Joint", () => {
        const {
          first_name,
          last_name,
          phone,
          email,
          birthdate,
          ssn,
          password,
          initials
      } = primary_account.getState();

        return {
          first_name,
          last_name,
          phone,
          email,
          birthdate,
          ssn,
          password,
          initials
        }
      })
      .exhaustive();


    const applicant_payload = match(application_type)
      .with("Individual", () => {
        const [residence, employment] = match_requirements(
          primary_current_address.getState(),
          primary_current_employment.getState(),
          primary_requires_previous_residence,
          primary_previous_address.getState(),
          primary_requires_previous_employment,
          primary_previous_employment.getState()
        );

        return {
          $type: "Individual",
          primary_applicant: {
            $type: "Individual",
            responsibility: 0,
            ...primary_account_payload,
            residence_requirements: { ...residence },
            employment_requirements: { ...employment },
            additional_requirements: { $type: "None" },
          },
        };
      })
      .with("Joint", () => {
        const [primary_residence, primary_employment] = match_requirements(
          primary_current_address.getState(),
          primary_current_employment.getState(),
          primary_requires_previous_residence,
          primary_previous_address.getState(),
          primary_requires_previous_employment,
          primary_previous_employment.getState()
        );

        const [joint_residence, joint_employment] = match_requirements(
          joint_current_address.getState(),
          joint_current_employment.getState(),
          joint_requires_previous_residence,
          joint_previous_address.getState(),
          joint_requires_previous_employment,
          joint_previous_employment.getState()
        );

        return {
          $type: "Joint",
          primary_applicant: {
            ...primary_account_payload,
            residence_requirements: { ...primary_residence },
            employment_requirements: { ...primary_employment },
            additional_requirements: { $type: "None" },
          },

          joint_applicant: {
            ...joint_account.getState(),
            residence_requirements: { ...joint_residence },
            employment_requirements: { ...joint_employment },
            additional_requirements: { $type: "None" },
          },
        };
      })
      .exhaustive();

    
    const trade_in_payload = match(loan_details.getState().trade_in)
      .with(
        "NoTradeIn", (no_trade_in) => ({
          $type: no_trade_in
        })
      )
      .with(
        "WithTradeIn", (trade_in) => ({
          $type: trade_in,
          vehicle: {
            $type: "UsedVehicle",
            ...loan_details_trade_in.getState(),
          }
        })
      )
      .with(
        null, () => {}
      )
      .exhaustive();

    const application = application_store.getState();

    const loan_details_payload = match(application.loan_type)
      .with("NewOrUsed", () => {
        const { amount, term, cash_down } =
          loan_details.getState() as NewOrUsedLoan;

        return {
          amount,
          term,
          cash_down,
        };
      })
      .with("Refinance", () => {
        const {
          amount,
          term,
          cash_down,
          interest_rate,
          monthly_payment,
          lien_holder,
          next_payment_date,
        } = loan_details.getState() as RefinanceLoan;

        return {
          amount,
          term,
          cash_down,
          interest_rate,
          monthly_payment,
          lien_holder,
          next_payment_date,
        };
      })
      .with("PrivateParty", () => {
        const {
          amount,
          term,
          cash_down,
          seller_first_name,
          seller_last_name,
          seller_phone_number,
          lien_holder

        } = loan_details.getState() as PrivatePartyLoan;

        return {
          amount,
          term,
          cash_down,
          seller_first_name,
          seller_last_name,
          seller_phone_number,
          lien_holder
        };
      })
      .with("Buyout", () => {
        const { amount, term, next_payment_date, lien_holder } =
          loan_details.getState() as LeaseBuyoutLoan;

        return {
          amount,
          term,
          next_payment_date,
          lien_holder,
        };
      })
      .exhaustive();

    // marketing params
    const form_params = application_store.getState().params;
    
    const source = form_params?.source;  
    const site = form_params?.site;  
    const channel = form_params?.channel;  
    const promo_code = form_params?.promo_code;
    // this needs a better name
    const reference_type = form_params?.type;
    const seo_term = form_params?.term;
    const cj_event = form_params?.cjevent;
    const _ost = form_params?._ost;
    
    const application_payload = {
      $type: application.application,
      loan: {
        $type: application.loan_type,

        ...loan_details_payload,
        
        trade_in: {... trade_in_payload },

        vehicle: {
          $type: loan_details.getState().vehicle_type,
          ...loan_details_vehicle.getState(),
        },
      },
      captcha: application.captcha,

      //marketing
      source,
      site,
      channel,
      promo_code,
      reference_type,
      seo_term,
      cj_event,
      _ost,
      _hc: application.captcha_challenged
    };

    return {
      ...application_payload,
      ...applicant_payload,
      id: 0,
    };
  };

  return [build];
};
