import { ComponentValidationResult } from "../../_components/IFieldProps";
import { InputComponent } from "../../_components/InputComponent";
import { MaskedInputComponent } from "../../_components/MaskedInputComponent";
import { MoneyInputComponent } from "../../_components/MoneyInputComponent";
import { SelectComponent } from "../../_components/SelectComponent";
import { StoreField } from "../../_components/StoreField";
import {
  date_formatter,
  money_formatter,
  onlyNumbersAndOneDecimal,
} from "../../_helpers/Masks";
import { date_parser } from "../../_helpers/parsers";
import {
  isValidPaymentDate,
} from "../../_helpers/validations";
import { IApplicationStep } from "../../_store/application";
import { RefinanceLoan as RefinanceLoanType } from "../../_store/loan";
import { NullableDate } from "../../_store/NullableDate";
import { IStoreSelector, useFormStore } from "../../_store/useApplicationState";
import { LienHolder } from "./LienHolder";

export const RefinanceLoan = (props: IStoreSelector<RefinanceLoanType> & IApplicationStep) => {
  
  const minimum_loan = 5000;
  const [store] = useFormStore(app => app.loan_details);

  store.setState({ trade_in: "NoTradeIn", vehicle_type: "UsedVehicle" });

  const validate_loan_amount = (): ComponentValidationResult => {
    const { amount } = store.getState();

    if ((amount ?? 0) < minimum_loan) {
      return [false, () => `You must refinance at least ${money_formatter(minimum_loan)}`];  
    }

    return [true];
  };

  const validateInterestRate = (
    interest_rate: StoreField<Pick<RefinanceLoanType, 'interest_rate'>>,
    force: boolean,
    isDirty?: boolean | undefined
  ): ComponentValidationResult => {
    if (!force && !isDirty) {
      return [true];
    }
    
    if (Number(interest_rate) === 0 || isNaN(Number(interest_rate))) {
      return [
        false,
        () => "Please enter the interest rate on the vehicle being financed (cannot be 0).",
      ];
    }

    return [true];
  };

  const validateMonthlyPayment = (
    monthly_payment: StoreField<Pick<RefinanceLoanType, 'monthly_payment'>>,
    force: boolean,
    isDirty?: boolean | undefined
  ): ComponentValidationResult => {
    if (!force && !isDirty) {
      return [true];
    }

    if (monthly_payment === 0) {
      return [
        false,
        () => "Please enter the monthly loan payment on the vehicle being financed (cannot be 0).",
      ];
    }

    return [true];
  };

  const validatePaymentDate = (
    next_payment_date: StoreField<Pick<RefinanceLoanType, 'next_payment_date'>>,
    force: boolean,
    isDirty: boolean | undefined
  ): ComponentValidationResult => {
    if (!force && !isDirty) {
      return [true];
    }

    if (!next_payment_date) {
      return [false, () => "Next Payment Date is required."];
    }

    const valid = isValidPaymentDate(next_payment_date);
    if (!valid) {
      return [valid, () => "Please enter a valid next payment date."];
    }

    return [true];
  };

  return (
    <div className="">
      <h2>Current Loan Details</h2>

      <div className="grid margin-responsive">
        <MoneyInputComponent<Pick<RefinanceLoanType, "amount">, number | null>
          label="Remaining Balance"
          maxLength={9}
          validate={validate_loan_amount}
          testId="remainingBalance"
          className="col"
          infoIcon="Remaining Balance"
          store={props.store}
          selector={(loan) => loan.amount}
          step={props.step}
          helperText={`Minimum to refinance: ${money_formatter(minimum_loan)}`}
        />

        <InputComponent<Pick<RefinanceLoanType, "interest_rate">, number | null>
          label="Interest Rate"
          maxLength={5}
          mask={onlyNumbersAndOneDecimal}
          testId="interestRate"
          validate={(value, force, isDirty) =>
            validateInterestRate(value, force, isDirty)
          }
          className="col"
          infoIcon="Interest Rate"
          store={props.store}
          selector={(loan) => loan.interest_rate}
          step={props.step}
        />

        <MoneyInputComponent<
          Pick<RefinanceLoanType, "monthly_payment">,
          number | null
        >
          label="Monthly Payment"
          maxLength={9}
          testId="monthlyPayment"
          className="col my-2"
          validate={(value, force, isDirty) =>
            validateMonthlyPayment(value, force, isDirty)
          }
          infoIcon="Monthly Payment"
          store={props.store}
          selector={(loan) => loan.monthly_payment}
          step={props.step}
          // minimum={1}
        ></MoneyInputComponent>

        <LienHolder store={props.store} step={props.step} />

        <MaskedInputComponent<
          Pick<RefinanceLoanType, "next_payment_date">,
          NullableDate
        >
          label="Next Payment Date"
          inputMode="numeric"
          placeholder=""
          helperText="(mm/dd/yyyy)"
          // mask={dateMask}
          validate={(value, force, isDirty) =>
            validatePaymentDate(value, force, isDirty)
          }
          store={props.store}
          selector={(loan) => loan.next_payment_date}
          step={props.step}
          formatter={date_formatter}
          field_parser={date_parser}
        ></MaskedInputComponent>

        <SelectComponent<Pick<RefinanceLoanType, "term">, string>
          label="Requested Term"
          className="col"
          store={props.store}
          selector={(loan) => loan.term}
          step={props.step}
        >
          <option></option>
          <option>24</option>
          <option>36</option>
          <option>48</option>
          <option>54</option>
          <option>60</option>
          <option>72</option>
          <option>84</option>
        </SelectComponent>
      </div>
    </div>
  );
};
