
import { InfoIcon } from './InfoIcon';
import { InfoIcons } from "./InfoIcons";

export interface IFieldError {
    showError?: boolean;
    errorMessage?: string;
}

export interface IFieldLayoutProps {
  label?: string;
  helperText?: string;
  children?: React.ReactNode;

  infoIcon?: keyof typeof InfoIcons;

  className?: string;
  testId?: string;

}


export const FieldLayoutComponent = (props: IFieldLayoutProps & IFieldError) => {
  const { showError, errorMessage } = props;

  return (
    <div
      className={`field-layout ${props.className ?? ''}`}
      data-testid={props.testId}
    >
      {props.label && (
        <div className="flex flex-row h-6">
          <label className="flex items-center" data-testid="field_label">
            {props.label}
          </label>
          {props.infoIcon && (
            <div className="flex items-center">
              <InfoIcon infoIcon={props.infoIcon} />
            </div>
          )}
        </div>
      )}
      {props.children}
      {props.helperText && !showError && (
        <p className="sub-text" data-testid="field_helper_text">
          {props.helperText}
        </p>
      )}

      {showError && (
        <p className="sub-text sub-text-error" data-testid="field_error_text">
          {errorMessage}
        </p>
      )}
    </div>
  );
};
