import { DefaultDateDelimiter, formatDate as formatDateZen, formatNumeral, NumeralThousandGroupStyles } from "cleave-zen";

const mask = (value: string, mask: string) => {
  const result: Array<string> = [];
  const valueArray = [...value].reverse();

  let maskIndex = 0;

  while (valueArray.length > 0 && maskIndex < mask.length) {
    if (mask[maskIndex] === "#") {
      const current = valueArray.pop() ?? "";
      result.push(current);
      maskIndex++;
    } else {
      result.push(mask[maskIndex]);
      maskIndex += 1;
    }
  }

  return result.join("");
};

export const onlyNumbersMask = (value: string | number) => {
  const stringValue = value.toString();
  return stringValue.replace(/\D/g, "");
}

export const onlyLettersMask = (value: string) => {
  return value?.replace(/[^a-zA-Z]/g, "");
}

export const onlyLettersAndNumbersMask = (value: string | number) => {
  return value?.toString().replace(/[^a-zA-Z0-9 '-]/g, "");
}

export const nameMask = (value: string | number) => {
  return value?.toString().replace(/[^a-zA-Z '-]/g, "");
}


// export const onlyNumbersWithCommasMask = (value: string | number) => {
//   const stringValue = onlyNumbersMask(value);

//   const options = { minimumFractionDigits: 0 };
//   const result = new Intl.NumberFormat("en-US", options).format(
//     parseInt(stringValue !== "" ? stringValue : "0")
//   );

//   return result;
// };


export const onlyNumbersAndOneDecimal = (value: string | number) => {
  const stringValue = value.toString();
  const firstDecimal = stringValue.indexOf('.');
  if (firstDecimal === -1) {
    return stringValue.replace(/\D/g, "");
  }

  return stringValue.substring(0, firstDecimal + 1) + stringValue.slice(firstDecimal + 1).replace(/\D/g, "");
}

export const phoneMask = (value: string | number) => {
  const stringValue = onlyNumbersMask(value);

  const result = mask(stringValue, "###-###-####");
  return result;
};

export const dateMask = (value: string | number) => {
  const stringValue = onlyNumbersMask(value);
  return stringValue
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1");
};

export const dateMask_cleave = (value: string | number) => {
  const masked = formatDateZen(value.toString(), {
    delimiter: "/",
    datePattern: ["m", "d", "Y"],
  });

  return masked;
};

export const ssnMask = (value: string | number) => {
  const stringValue = onlyNumbersMask(value);

  const result = mask(stringValue, "###-##-####");
  return result;
};

export const zipMask = (value: string | number) => {
  const stringValue = onlyNumbersMask(value);
  return stringValue.replace(/(\d{5})(\d)/, "$1");
};

export const monthYearMask = (value: string | number) => {
  const stringValue = onlyNumbersMask(value);
  const numberValue = Number(stringValue);

  if (numberValue < 10) {
    return String(numberValue).padStart(2, "0");
  } else if (numberValue > 99) {
    return "99";
  }
  return stringValue;
};

// export const moneyMask = (value: string | number) => {
//   const stringValue = onlyNumbersWithCommasMask(value);
//   return "$ " + stringValue;
// };


export const formatDate = (input:string) => {
  const date = new Date(input);
  
  const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
  const formattedDate = formatter.format(date);

  return formattedDate;
}

export const twoDigits = (input:string | number) => {
  return Number(input).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
}

export const money_formatter = (input: string | number) => {
  const masked = formatNumeral(input.toString(), {
    prefix: input !== "" ? "$" : "",
    numeralThousandsGroupStyle: NumeralThousandGroupStyles.THOUSAND,
    numeralPositiveOnly: true,
    numeralDecimalScale: 0
  });
  return masked;
};

export const date_formatter = (input:string) => {
  const masked = formatDateZen(input, {
    delimiter: DefaultDateDelimiter,
    datePattern: ["m", "d", "Y"]
  });

  return masked;
};

export const number_formatter = (input:string) => {
  const masked = formatNumeral(input.toString(), {
    // prefix: input !== "" ? "$" : "",
    numeralThousandsGroupStyle: NumeralThousandGroupStyles.THOUSAND,
    numeralPositiveOnly: true,
    numeralDecimalScale: 0
  });
  return masked;
}