import './SsnInputComponent.css';

import { IFieldError } from './FieldLayoutComponent';
import { ComponentValidationResult, IFieldProps } from './IFieldProps';
import { MaskedInputComponent } from './MaskedInputComponent';
import { ITestableComponent } from './ITestableComponent';
// import { ssnMask } from '../_helpers/Masks';
import { validateSSN } from '../_helpers/validations';
import { isSsnValid } from '../_api/mal-validations';
import { IFieldSelector, Stores } from '../_store/useApplicationState';
import { StoreField } from './StoreField';
import { useState } from 'react';
import { formatGeneral } from 'cleave-zen';


type SsnInputComponentProps<
  TStore extends Stores,
  TField extends StoreField<TStore>
> = IFieldProps<TStore> &
  IFieldError &
  ITestableComponent &
  IFieldSelector<TStore, TField>;

export const SsnInputComponent = <
  TStore extends Stores,
  TField extends StoreField<TStore>
  >(
  props: SsnInputComponentProps<TStore, TField>
) => {

  const [showDup30Days, setShowDup30Days] = useState<boolean>(false);

  const validate = (
    ssn: StoreField<TStore>,
    force: boolean, 
    isDirty: boolean): ComponentValidationResult => {
    if (!force && !isDirty) {
      return [true];
    }

    if (!ssn) {
      return [false, () => `${props.label} is required.`];
    }

    const valid = validateSSN(ssn as unknown as string);
    if (!valid) {
      return [false, () => props.errorMessage ?? 'Invalid SSN.'];
    }

    return [true];    
  };

  const validateAsync = async (
    ssn: StoreField<TStore>,
  ): Promise<[boolean, (label:string) => string]> => {
    setShowDup30Days(false);
    const [isValid, errorMessage] = await isSsnValid(ssn);

    if (!isValid && errorMessage === 'DUPLICATE_SSN_30_DAYS') {
      setShowDup30Days(true);
      return [false, () => ''];
    }

    if (!isValid) {
      return [false, () => errorMessage ?? props.errorMessage];
    }

    // TODO: add a new signature without the empty message func.
    return [true, () => ''];
  }

  return (
    <>
    <MaskedInputComponent<TStore, TField>
      {...props}
      inputMode="numeric"
      formatter={(input:string) => {
        const masked = formatGeneral(input, {
          delimiter: '-',
          blocks: [3,2,4],
          numericOnly: true
        });
        return masked;
      }}
      
      validate={(value, force, isDirty) => validate(value, force, isDirty)}
      validateAsync={validateAsync}

      store={props.store}
      selector={props.selector}
    ></MaskedInputComponent>
    {showDup30Days && <div className='sub-text dup-ssn-30-days'>
      We already have an application on file with this social security number. 
      This means you have applied with us or one of our partners in the last 30 days. 
      Email addresses, passwords and social security numbers are security features within our site and must match. 
      If you forgot which email address you used when you applied use our <a href='#'>Forgot Email</a> or <a>Forgot Password</a> features or <a href='#'>Log In</a> now to access your application.
    </div>}
    </>
  );
};
