import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  size,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react";
import { debounce } from "lodash";
import { forwardRef, useRef, useState } from "react";
import SmartySDK from "smartystreets-javascript-sdk";
import { lookupAddress } from "../_api/addressApi";
import {
  IFieldSelector,
  IStoreSelector,
  useFormStore,
} from "../_store/useApplicationState";
import { StreetCityStateZip } from "../_store/address";
import { IFieldProps } from "./IFieldProps";
import { IFieldError } from "./FieldLayoutComponent";
import { StreetAddressType } from "../_store/address";
// import { useFieldValidation } from "../_store/useFieldValidationRegistry";
import { isNullOrWhite } from "../_helpers/isNullOrWhite";

type StreetAutoCompleteItemProps = {
  children: React.ReactNode;
  active: boolean;
};

const StreetAutoCompleteItem = forwardRef<
  HTMLDivElement,
  StreetAutoCompleteItemProps & React.HTMLProps<HTMLDivElement>
>(({ children, active, ...rest }, ref) => {
  const id = useId();
  return (
    <div
      ref={ref}
      role="option"
      id={id}
      aria-selected={active}
      {...rest}
      style={{
        ...rest.style,
      }}
      className={`hover:bg-gray-200 active:bg-gray-200 cursor-default py-2 px-4 text-sm w-full text-left ${
        active ? " bg-gray-200" : ""
      }`}
    >
      {children}
    </div>
  );
});
StreetAutoCompleteItem.displayName = "StreetAutoCompleteItem";

const StreetAutoCompleteItemList = (
  props: {
    searchResults: SmartySDK.usAutocompletePro.Suggestion[];
    getItemProps: (
      userProps?: React.HTMLProps<HTMLElement>
    ) => Record<string, unknown>;
    listRef: React.RefObject<Array<HTMLElement | null>>;
    activeIndex: number | null;

    onClick: (item: SmartySDK.usAutocompletePro.Suggestion) => void;
  } & IStoreSelector<StreetCityStateZip> &
    IFieldSelector<StreetAddressType, string>
) => {
  return props.searchResults.map((item, index) => (
    <StreetAutoCompleteItem
      {...props.getItemProps({
        // key: `${item.streetLine}-${index}`,
        ref(node) {
          if (props.listRef.current) {
            props.listRef.current[index] = node;
          }
        },
        onClick() {
          if (props.onClick) {
            props.onClick(item);
          }
        },
      })}
      active={props.activeIndex === index}
      key={`${item.streetLine}-${index}`}
    >
      {item.streetLine}
      {(item.secondary ?? "") != "" ? ` ${item.secondary}` : ""}, {item.city},{" "}
      {item.state} {item.zipcode}
    </StreetAutoCompleteItem>
  ));
};

const StreetAddressInputElement = (
  props: IFieldProps<StreetAddressType> &
    IStoreSelector<StreetCityStateZip> &
    IFieldError & {
      getReferenceProps: (
        userProps?: React.HTMLProps<Element>
      ) => Record<string, unknown>;
      inputRef: React.RefObject<HTMLInputElement>;
    }
) => {
  const [store, set] = useFormStore<StreetCityStateZip>(props.store);
  const street = store((s) => s.street);
  const setter = set((address) => address.street);

  return (
    <input
      value={street}
      autoComplete="do-not-autofill"
      type="text"
      className={`${
        props.showError
          ? "focus:ring focus:ring-rose-400 focus:border-error-950 border-error-950 border-2"
          : "border-[#d3d3d3] focus:border-[#d3d3d3] focus:ring-gray-600 focus:ring-2"
      } form-input-field
        `}
      {...props.getReferenceProps({
        ref: props.inputRef,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setter(e.target.value);
          if (props.onChange) {
            props.onChange(e.target.value);
          }
        },

        placeholder: props.placeholder ?? "Enter Street Address",
        "aria-autocomplete": "list",
        onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
          if (props.onKeyDown) {
            props.onKeyDown(event);
          }
        },
      })}
      onBlur={(e) => {
        if (props.onBlur) {
          props.onBlur(e.target.value, true);
        }
      }}
      maxLength={props.maxLength}
      data-testid="field_input"
    />
  );
};

export const StreetAddressAutoComplete = (
  props: IStoreSelector<StreetCityStateZip> &
    IFieldSelector<StreetAddressType, string> &
    IFieldProps<StreetAddressType> &
    IFieldError
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [searchResults, setSearchResults] = useState<
    SmartySDK.usAutocompletePro.Suggestion[]
  >([]);

  const [store, ] = useFormStore<StreetCityStateZip>(props.store);

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
    elements: {
      reference: inputRef.current,
    },
  });



  const set_selected_item = (item: SmartySDK.usAutocompletePro.Suggestion) => {

    store.setState({
      street: item.secondary ? `${item.streetLine}, ${item.secondary}` : item.streetLine,
      city: item.city,
      state: item.state,
      zip: item.zipcode
    });

  };

  const role = useRole(context, { role: "listbox" });
  const dismiss = useDismiss(context);

  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: (index) => {

      // Using this trick to preserve activeIndex until onBlur
      // onBlur will reset activeIndex back to null
      if (index === activeIndex || index === null) {
        return;
      }

      setActiveIndex(() => index);
    },
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav]
  );
  
  const handleAddressLookup = async () => {
    const value = store.getState().street;
    if (isNullOrWhite(value)) {
      return;
    }

    const response = await lookupAddress(value);

    if (response.result.length <= 0) {
      return;
    }

    setSearchResults(response.result);
    setActiveIndex(() => 0);
    setIsOpen(true);    

    inputRef.current?.focus();
   
  };

  const debouncedAddressLookup = debounce(handleAddressLookup, 500);

  return (
    <>
      <StreetAddressInputElement
        {...props}
        autoComplete="street-address"
        getReferenceProps={getReferenceProps}
        inputRef={inputRef}
        onBlur={(e) => {
          if (props.onBlur) {
            props.onBlur(e, true);
          }

          if (activeIndex !== null) {
            const item = searchResults[activeIndex];
            set_selected_item(item);
            setActiveIndex(() => null);
          }          

        }}
        onChange={(value) => {
          if (props.onChange) {
            props.onChange(value);
          }

          if (!isNullOrWhite(value)) {
            debouncedAddressLookup();
          }
        }}
        showError={props.showError}
        store={props.store}
        step={props.step}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (
            event.key === "Enter" &&
            activeIndex !== null &&
            searchResults[activeIndex]
          ) {
            const item = searchResults[activeIndex];
            set_selected_item(item);
            setIsOpen(() => false);
          }
        }}
      />

      <FloatingPortal>
        {isOpen && (
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  ...floatingStyles,
                  top: "-1px",
                },
              })}
              className="border rounded-lg border-gray-300 shadow-md bg-white overflow-y-auto"
            >
              <StreetAutoCompleteItemList
                searchResults={searchResults}
                getItemProps={getItemProps}
                activeIndex={activeIndex}
                onClick={(item) => {
                  set_selected_item(item);
                  setIsOpen(false);

                  inputRef.current?.focus();
                }}
                listRef={listRef}
                store={props.store}
                selector={(s) => s.street}
              />
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
};
