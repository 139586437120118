import './ApplicationTabs.css';
import { Steps } from '../../_store/application';
import { useApplicationState } from '../../_store/useApplicationState';

const resolveClass = (step: Steps, current: Steps) => {
    const status =
      step === current ? 'current' : step < current ? 'completed' : 'next';
  
    return `step ${status}`;
  };

export const ApplicationTabs = () => {
    
    const application_store = useApplicationState(s => s.application_store);
    const step = application_store(app => app.step);
    const step_setter = application_store.setState;

    const set_step = (step: Steps) => {
      if (import.meta.env.VITE_ALLOW_STEP_OVERRIDE === "true") {
        step_setter({ step });
      }
    }
    
    return (
        <div
        className="application-tabs"
        data-testid="step_bar"
      >
        <>
            <div
              onClick={() => set_step(Steps.address)}
              className={resolveClass(Steps.address, step)}
            >
              STEP 1
            </div>
            <div
              onClick={() => set_step(Steps.employment)}
              className={resolveClass(Steps.employment, step)}
            >
              STEP 2
            </div>
            <div
              onClick={() => set_step(Steps.loan)}
              className={resolveClass(Steps.loan, step)}
            >
              STEP 3
            </div>
            <div
              onClick={() => set_step(Steps.account)}
              className={resolveClass(Steps.account, step) + ' mr-0'}
            >
              STEP 4
            </div>
          </>
      </div>
    )
}