import { ComponentValidationResult } from "../../../_components/IFieldProps";
import { money_formatter } from "../../../_helpers/Masks";

export const validateIncome = (applicant:'primary' | 'joint', chronology: 'current' | 'previous' ) => (
  value: number | null,
  force: boolean,
  isDirty: boolean
): ComponentValidationResult => {

  const minimum = 10_000;

  if (applicant === 'joint' && chronology === 'previous') {
    return [true];
  }

  if (force || isDirty) {
    if ((value ?? 0) < minimum) {
      return [false, (label: string) => `${label} must be at least ${money_formatter(minimum)}.`];
    }
  }

  return [true];
};
