
import { useRef, useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
} from '@floating-ui/react';
import {FloatingArrow, arrow} from '@floating-ui/react';
import { InfoIcons } from "./InfoIcons";

export interface IInfoIconProps {
  infoIcon: keyof typeof InfoIcons;
}

export const InfoIcon = (props: IInfoIconProps) => {

  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const {refs, floatingStyles, context} = useFloating({
    placement: 'bottom',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift(),  arrow({
      element: arrowRef,
    }),],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {move: false});
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {role: 'tooltip'});
 
  // Merge all the interactions into prop getters
  const {getReferenceProps, getFloatingProps} = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);
  
  const title = props.infoIcon ?? '';
  const body = InfoIcons[title];

  return (
    <>
      <svg data-testid="info_icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-1 flex items-center cursor-pointer"  ref={refs.setReference} {...getReferenceProps()}>
        <g id="info">
          <path id="Vector" d="M7.3335 4.66658H8.66683V5.99992H7.3335V4.66658ZM7.3335 7.33325H8.66683V11.3333H7.3335V7.33325ZM8.00016 1.33325C4.32016 1.33325 1.3335 4.31992 1.3335 7.99992C1.3335 11.6799 4.32016 14.6666 8.00016 14.6666C11.6802 14.6666 14.6668 11.6799 14.6668 7.99992C14.6668 4.31992 11.6802 1.33325 8.00016 1.33325ZM8.00016 13.3333C5.06016 13.3333 2.66683 10.9399 2.66683 7.99992C2.66683 5.05992 5.06016 2.66659 8.00016 2.66659C10.9402 2.66659 13.3335 5.05992 13.3335 7.99992C13.3335 10.9399 10.9402 13.3333 8.00016 13.3333Z" fill="#535353" />
        </g>
      </svg>

      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className="tooltip"
        >
          <h2 className="info-title-override">{title}</h2>
          <p data-testid="field_info_text">{body}</p>
          <FloatingArrow ref={arrowRef} context={context} className="arrow" />
        </div>
      )}
    </>
  );
};