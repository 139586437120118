import { createStore } from './createStore';

export type ApplicationType = 'Individual' | 'Joint';

export type LoanType = 'NewOrUsed' | 'Refinance' | 'PrivateParty' | 'Buyout';

export type FormParameters = {
  loan_type?: string,
  source?: string,
  type?: string,
  term?: string,
  site?: string,
  promo_code?: string,
  channel?: string,
  cjevent?: string,
  '_ost'?: string
};


export const mapLoanType = (input: "P" | "R" | "2" | "B"): LoanType => {
  switch (input) {
    case "P":
      return "NewOrUsed";
    case "R":
      return "Refinance";
    case "2":
      return "PrivateParty";
    case "B":
      return "Buyout";
    default:
      return "NewOrUsed";
  }
};


export enum Steps {
  'none' = 0,
  'address' = 1,
  // 'address_joint' = 1.5,
  'employment' = 2,
  // 'employment_joint' = 2.5,
  'loan' = 3,
  'account' = 4,
  'submitted' = 5
}

export interface IApplicationStep {
  step: Steps
}

export interface IApplication {
  //TODO: better name... type?
  application: ApplicationType;
  loan_type: LoanType;
  step: Steps,
  progress: number,
  id?: number,

  captcha: string | null;
  captcha_challenged: boolean;

  params: FormParameters | null;
}

export const createMalApplicationStore = createStore<IApplication>({
  application: 'Individual',
  loan_type: 'NewOrUsed',
  step: Steps.address,
  progress: 0,
  captcha: null,
  captcha_challenged: false,
  params: null
});

export type Chronology = 'current' | 'previous' | '';


