import { useState } from "react";
import {
  FieldLayoutComponent,
  IFieldError,
} from "../../_components/FieldLayoutComponent";
import { IFieldProps } from "../../_components/IFieldProps";
import { LoanDetails, VehicleType } from "../../_store/loan";
import {
  IFieldSelector,
  useFormStore,
} from "../../_store/useApplicationState";
import { useFieldValidationRegistry } from "../../_store/useFieldValidationRegistry";

type VehicleTypeType = Pick<LoanDetails, "vehicle_type">;

export const VehicleTypeComponent = (
  props: IFieldProps<VehicleTypeType> &
    IFieldError &
    IFieldSelector<VehicleTypeType, VehicleType | null>
) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [store, set] = useFormStore<VehicleTypeType>(props.store);
  const vehicle_type = store((vehicle) => vehicle.vehicle_type);
  const setter = set(props.selector);

  //Component does not have isDirty - no need to force
  const validate = (
    value?: VehicleType
  ): [boolean, (label: string) => string] => {
    if (!value) {
      setShowError(() => true);
      setErrorMessage(() => "Please confirm vehicle type.");
      return [false, () => "Please confirm vehicle type."];
    }

    setShowError(() => false);
    setErrorMessage(() => "");
    return [true, () => ""];
  };

  useFieldValidationRegistry(props.store, props.selector, props.step, () => {
    const value = store.getState().vehicle_type;

    return validate(value);
  });

  return (
    <FieldLayoutComponent
      {...props}
      showError={showError}
      errorMessage={errorMessage}
    >
      <div className="inline-radio">
        <label htmlFor="vehicle-type_new">
          <input
            id="vehicle-type_new"
            type="radio"
            name="vehicle-type"
            checked={vehicle_type === "NewVehicle"}
            onChange={() => {
              setter("NewVehicle");
              validate("NewVehicle");
            }}
            data-testid="vehicle-type_new"
          />
          New
        </label>

        <label htmlFor="vehicle-type_used">
          <input
            id="vehicle-type_used"
            type="radio"
            name="vehicle-type"
            checked={vehicle_type === "UsedVehicle"}
            onChange={() => {
              setter("UsedVehicle");
              validate("UsedVehicle");
            }}
            data-testid="vehicle-type_used"
          />
          Used
        </label>
      </div>
    </FieldLayoutComponent>
  );
};
