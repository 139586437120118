import { Relationship } from "../_store/account";
import { NullableDate } from "../_store/NullableDate";
import { isNullOrWhite } from "./isNullOrWhite";

export const isValidPhoneFormat = (value: unknown) => {
  const value_as_string = value?.toString() ?? '';

  const regex = /^\d{3}-\d{3}-\d{4}$/;
  return regex.test(value_as_string);
}

export const isValidEmail = (value?: unknown) => {
  
  const value_as_string = value?.toString() ?? '';
  if (isNullOrWhite(value_as_string)) {
    return false;
  }

  const regex = /^\s*\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*\s*$/;
  return regex.test(value_as_string);
}

export const validate_date_string = (date_as_string: string) => {
  if (isNullOrWhite(date_as_string)) {
    return false;
  }

  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  return regex.test(date_as_string);
};

export const validateDOB = (date: Date | null | "invalid") => {
  if (date === null || date === "invalid") {
    return false;
  }

  const oldest = new Date();
  oldest.setFullYear(oldest.getFullYear() - 100);

  return (
    date instanceof Date &&
    !isNaN(date.getTime()) &&
    date >= oldest
  );
}

export const isValidPaymentDate = (date: NullableDate) => {
  if (isNullOrWhite(date)) return false;

  if (date === null || date === "invalid") {
    return false;
  }

  const oldest = new Date();
  oldest.setFullYear(oldest.getFullYear() - 100);

  return (
    date instanceof Date &&
    !isNaN(date.getTime()) &&
    date >= oldest
  );
}

export const validateSSN = (value: string) => {
  const regex = /^\d{3}-\d{2}-\d{4}$/;
  return regex.test(value);
}

export const isZipValid = (value: string): boolean => {
  const regex = /^\d{5}$/;
  return regex.test(value);
}


export function containsSpecialCharacters(value: string): boolean {
  return /^[a-z\d\s]+$/i.test(value) === false;
}

export function validateSpecialCharacters(
  value: string
): [boolean, (label: string) => string] {
  const error_message = (label: string) =>
    `${label} connot contain any special characters such as &, %, $, #, @, ?, /, - or _`;
  const valid = containsSpecialCharacters(value);
  return valid ? [true, () => ""] : [false, error_message];
}

export const isValidRelationship = (relationship: Relationship, state: string): boolean => {
  const validDemesticPartnerStates = ['CA', 'DC', 'ME', 'NV', 'OR', 'WA', 'WI', 'HI'];
  const validCivilUnionStates = ['CO', 'HI', 'IL', 'VT', 'NJ'];

  switch (relationship) {
    case Relationship.Domestic_Partner:
      return validDemesticPartnerStates.includes(state);
    case Relationship.Civil_Union:
      return validCivilUnionStates.includes(state);
    default:
      return true;
  }
}

export const validateName = (name: string) => {
  if (name.length < 2) {
    return false;
  }

  if (name.length >= 3) {
    return /[aeiouy]/i.test(name);
  }

  return true;
};