import info from "../../assets/info-120.svg";

import { MonthsYearsComponent } from "../../../_components/MonthsYearsComponent";
import { StreetAddressComponent } from "../../../_components/StreetAddressComponent";
import { Address } from "../../../_store/address";
import { CityStateZip } from "../../../my-autoloan-shared/CityStateZip";
import { ResidenceOwnership } from "../../../my-autoloan-shared/ResidenceOwnership";
import { IApplicationStep } from "../../../_store/application";
import { useApplicationType } from "../../../_store/useApplicationType";
import { money_parser } from "../../../_helpers/parsers";
import { MoneyInputComponent } from "../../../_components/MoneyInputComponent";
import { useApplicationState } from "../../../_store/useApplicationState";

export const PrimaryCurrentAddress = (props: IApplicationStep) => {
  const application_type = useApplicationType();
  const address_store = useApplicationState(s => s.primary_current_address);
  const ownership = address_store(s => s.ownership);
  console.log('own=>', ownership);

  return (
    <div className="group-box">
      <h2>Your Address</h2>
      <div className="content">
        <div className="grid margin-responsive">
          <StreetAddressComponent
            label={"Street"}
            placeholder=""
            maxLength={50}
            store={(s) => s.primary_current_address}
            step={props.step}
          />

          <CityStateZip
            store={(s) => s.primary_current_address}
            step={props.step}
          />

          <MonthsYearsComponent
            label="How long have you lived at this address?"
            errorMessage="Please select years and months at this address."
            minimumValueErrorMessage="Must have lived at least one month at this address."
            store={(s) => s.primary_current_address}
            step={props.step}
          ></MonthsYearsComponent>

          <ResidenceOwnership
            label={"Do you"}
            store={(s) => s.primary_current_address}
            selector={(address) => address.ownership}
            step={props.step}
          ></ResidenceOwnership>

          <MoneyInputComponent<Pick<Address, "monthly_payment">, number | null>
            label="Monthly Housing Payment"
            placeholder=""
            maxLength={9}
            infoIcon="Monthly House Payment"
            testId="rc_monthly_payment"
            store={(s) => s.primary_current_address}
            selector={(address) => address.monthly_payment}
            step={props.step}
            field_parser={money_parser}
            minimum={ownership === 'own' ? 0 : 1}
          ></MoneyInputComponent>
        </div>

        <div className="info">
          {application_type === 'Joint' && (
            <p className="text-sm">
              <img src={info} style={{ width: 40, marginBottom: "5px" }} />
              {`If the Joint Applicant lives with the Applicant and the Applicant
              entered 100% of the housing payment, the Joint Applicant's Housing
              Payment should then be entered as $0.00. If the Applicant only
              entered their portion of the Housing Payment, then the Joint
              Applicant should enter their portion of the Housing Payment. If
              the Joint Applicant and Applicant do not live together, each
              should enter their individual amount for the Housing Payment.`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
