import { useState } from "react";
import { Disclosure } from "../../../_components/Disclosure";
import { useApplicationState } from "../../../_store/useApplicationState";
import { IApplicationStep } from "../../../_store/application";
import { useFieldValidationRegistry } from "../../../_store/useFieldValidationRegistry";

export const ElectronicRecordsDisclosure = (props: IApplicationStep) => {
  // const scrollableRef = useRef<HTMLDivElement | null>(null);
  // const [scrolledToTheBottom, setScrolledToTheBottom] = useState(false);

  const primary_account = useApplicationState((s) => s.primary_account);
  const acknowledge = primary_account((account) => account.acknowledge);
  const { setState: setter, getState: getter } = primary_account;

  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDirty, setIsDirty] = useState(false);


  const validate = (
    value: boolean,
    force: boolean
  ): [boolean] | [boolean, (label: string) => string] => {
    if (force) {
      setIsDirty(() => true);
    }

    if (isDirty || force) {
      if (value !== true) {
        setShowError(() => true);
        setErrorMessage(() => "Acknowledgement is required.");
        return [false];
      }
    }

    setShowError(() => false);
    setErrorMessage(() => "");
    return [true];
  };

  useFieldValidationRegistry(
    (store) => store.primary_account,
    (selector) => selector.acknowledge,
    props.step,
    () => {
      const value = primary_account.getState().acknowledge;
      return validate(value, true);
    }
  );

  return (
    <>
      <div className="field-layout">
        <div className="flex items-start">
          <input
            type="checkbox"
            className={`cursor-pointer check-box mt-1`}
            onChange={(e) => {
              setter({ acknowledge: e.target.checked });
              validate(e.target.checked, true);
            }}
            checked={acknowledge}
            data-testid="acknowledgement_checkbox"
          ></input>
          <label
            htmlFor="authorize-check"
            data-testid="acknowledgement_label"
            className={`cursor-pointer`}
            onClick={() => {
              const { acknowledge } = getter();
              setter({ acknowledge: !acknowledge });
              validate(!acknowledge, true);
            }}
          >
            <span>
              I(“We” if applying for a Joint loan/financing offer request)
              acknowledge that I/We have received, reviewed, and consent to
              these terms. Please read, scroll to the bottom.
            </span>
          </label>
        </div>
        {showError && (
          <p className="sub-text sub-text-error" data-testid="field_error_text">
            {errorMessage}
          </p>
        )}
      </div>
      <div className="h-96 mt-8 overflow-scroll overflow-x-hidden bg-white">
        <Disclosure print={false} testId="electronic_disclosure" />
      </div>
    </>
  );
};
