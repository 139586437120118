import { isNullOrWhite } from "./isNullOrWhite";
import { validate_date_string } from "./validations";

export const money_parser = (input: string) => {
  if (isNullOrWhite(input)) {
    return null;
  }

  const clean = input.replace(/[^\d.-]/g, "");
  const value = parseFloat(clean);

  return value;
};

export const date_parser =(input:string) => {
  if (isNullOrWhite(input)) {
    return null;
  }

  const valid = validate_date_string(input);
  if (!valid) {
    return "invalid";
  }

  const parsed = Date.parse(input);
  
  if (isNaN(parsed)) {
    return "invalid";
  }

  return new Date(parsed);
}