import { useState } from "react";
import { FieldLayoutComponent, IFieldError } from "./FieldLayoutComponent";
import { IFieldProps } from "./IFieldProps";
import { StoreField } from './StoreField';
import { ISelectElementProps, SelectElement } from "./SelectElement";
import { isNullOrWhite } from "../_helpers/isNullOrWhite";
import { ITestableComponent } from "./ITestableComponent";
import { IFieldSelector, Stores, useFormStore } from "../_store/useApplicationState";
import { useFieldValidationRegistry } from "../_store/useFieldValidationRegistry";

// export type SelectComponentProps<TStore> = IFieldProps<TStore> &
//   IFieldError &
//   ISelectElementProps &
//   ITestableComponent &
//   IFieldSelector<TStore>;

export type SelectComponentProps<
  TStore extends Stores,
  TField extends StoreField<TStore>
> = IFieldProps<TStore, TField> &
  ISelectElementProps &
  IFieldError &
  ITestableComponent &
  IFieldSelector<TStore, TField>;

export const SelectComponent = <
  TStore extends Stores,
  TField extends StoreField<TStore>
>(props: SelectComponentProps<TStore, TField>) => {

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validate = (value:StoreField<TStore>, force: boolean): [boolean] => {
    if (!isDirty && !force) {
      return [true];
    }

    const isValid = !isNullOrWhite(value);
    if (!isValid) {
      setShowError(() => true);
      setErrorMessage(() => `${props.label} is required.`);
      return [false];
    }

    if (props.validate) {
      const [valid, customMessage] = props.validate(value, force, isDirty);
      setShowError(() => !valid);

      if (!valid) {
        setErrorMessage(() => customMessage(props.label ?? 'This'));
      }
      
      return [valid];
    }

    setShowError(() => false);
    setErrorMessage(() => "");
    return [true];
  };

  const onBlurValidation = async (value:StoreField<TStore>) => {
    const [isValid] = validate(value, false);
    if (isValid && props.validateAsync) {

      const [isValidAsync, message] = await props.validateAsync(value);
      if (!isValidAsync) {
        setShowError(() => true);
        setErrorMessage(() => message(props.label ?? ''));
      }
    }
  };

  
  const [store] = useFormStore<TStore>(props.store);
  
  useFieldValidationRegistry(
    props.store,
    props.selector,
    props.step,
    () => {
      const value = props.selector(store.getState());
      return validate(value, true);
    }
  );

  return (
    <FieldLayoutComponent
      {...props}
      showError={showError}
      errorMessage={errorMessage}
    >
      <SelectElement
        {...props}
        showError={showError}
        onChange={(value) => {
          setShowError(() => false);
          if (!isNullOrWhite(value)) {
            setIsDirty(() => true);
          }

          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onBlur={async (value) => {
          await onBlurValidation(value);
          
          if (props.onBlur) {
            props.onBlur(value, true);
          }
        }}
      />
    </FieldLayoutComponent>
  );
};
