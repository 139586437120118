import { useState } from 'react';
import { IFieldError } from '../_components/FieldLayoutComponent';
import { ComponentValidationResult, IFieldProps } from '../_components/IFieldProps';
import { InputComponent } from '../_components/InputComponent';
import { ITestableComponent } from '../_components/ITestableComponent';
import { IFieldSelector, Stores } from '../_store/useApplicationState';
import PasswordChecklist from "react-password-checklist";

type PasswordInputComponentProps<
  TStore extends Stores,
  TField extends TStore[keyof TStore]
> = IFieldProps<TStore> &
  IFieldError &
  ITestableComponent &
  IFieldSelector<TStore, TField>;

export const PasswordInputComponent = <
  TStore extends Stores,
  TField extends TStore[keyof TStore]
>(
  props: PasswordInputComponentProps<TStore, TField>
) => {
  const [password, setPassword] = useState('');
  const [valid, setValid] = useState(false);
  
  const validate = (): ComponentValidationResult => {
    // the validate function must return:
    // an array containing true (if all validations pass)
    // OR an array containing false and a function that returns the error message 
    return valid ? [valid] : [valid, () => ''];
  }  

  return (
    <>
      <InputComponent<TStore, TField>
        {...props}
        autoComplete="new-password"
        maxLength={20}
        type="password"
        infoIcon="Password"
        passwordEyeColor="#FF4C00"
        store={props.store}
        selector={props.selector}
        validate={validate}
        onChange={(value) => {
          const value_as_string = value?.toString() ?? "";
          setPassword(value_as_string);
        }}
        className="mb-0"
      ></InputComponent>

      <div>
        <PasswordChecklist
          rules={["minLength","specialChar","number","capital"]}
          minLength={8}
          iconSize={14}
          value={password}
          onChange={(isValid) => {
            setValid(isValid);
          }}
          className="password-checklist"
        />
      </div>
    </>
  );
};
