import { ApplicationProfile } from "./ApplicationProfile";

//TODO: pass token via curried function..

export async function submit(payload: unknown): Promise<Response> {
  const response = await fetch(`${import.meta.env.VITE_MAL_API_URL}/profile/submit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "x-hdf-token": token,
    },
    body: JSON.stringify(payload),
  });

  return response;
}

// TODO: add token parameter back 
export async function saveProfile(payload: ApplicationProfile): Promise<Response> {
  const response = await fetch(`${import.meta.env.VITE_MAL_API_URL}/profile`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "x-hdf-token": token,
    },
    body: JSON.stringify(payload),
  });

  return response;
}