import { InputComponent } from "../../_components/InputComponent";
import { LoanDetails } from "../../_store/loan";
import { isPotentialVulgarFieldValid } from "../../_api/mal-validations";
import {
  IStoreSelector,
  useApplicationState,
} from "../../_store/useApplicationState";
import { BasicVehicleInformation } from "./BasicVehicleInformation";
import { IApplicationStep } from "../../_store/application";

export const VehicleInformation = (
  props: IStoreSelector<LoanDetails> & IApplicationStep
) => {
  const application_store = useApplicationState((s) => s.application_store);
  const loan_type = application_store((s) => s.loan_type);

  const validateLienHolderAsync = async (
    value: string
  ): Promise<[boolean, (label: string) => string]> => {
    const [isVulgarFree, vulgarErrorMessage] =
      await isPotentialVulgarFieldValid(value);
    if (!isVulgarFree) {
      return [false, vulgarErrorMessage];
    }

    return [true, () => ""];
  };

  return (
    <div className="">
      <h2>Vehicle</h2>
      <div className="grid margin-responsive">
        <BasicVehicleInformation step={props.step} />

        {loan_type !== "NewOrUsed" && (
          <>
            <InputComponent<Pick<LoanDetails, "lien_holder">, string>
              label="Lien Holder"
              maxLength={50}
              //TODO: type value needs to be casted?
              validateAsync={(value) =>
                validateLienHolderAsync(value as string)
              }
              testId="lienHolder"
              className="col"
              store={props.store}
              selector={(loan) => loan.lien_holder}
              step={props.step}
            />
          </>
        )}
      </div>
    </div>
  );
};
