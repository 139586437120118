import { create } from "zustand";

// const useMyStore = create<{stuff:number}>((set) => ({
//   stuff: 0,
//   setStuff: (s:number) => set({stuff: s})
// }));

// const stuff = useMyStore(s => s.stuff);

// export interface IStoreProp<TStore> {
//   setter: (value: Partial<TStore>) => void;
//   getter: <K extends keyof TStore>(name?: K) => Pick<TStore, K> | TStore;
// }

// // type Store = typeof create;

export const createStore = <TStore>(initial:TStore) => {

  return create<TStore>(() => ({
    ...initial
  }))

};



// export const createStore = <TStore>(initial: TStore): Store => {
//   return function <TP extends IStoreProp<TStore>>() {
//     const hook = create<TStore & TP>((set, get) => {
//       return {
//         ...initial,
//         setter: (value: any) => {
//           // set({ ...value });
//           set(value);
//         },
//         getter: (name?: string) => {

//           const store = get() as TStore;
          
//           //if no key is provided, return whole
//           if (!name) {
//             const { getter, setter, ...clean } = store as IStoreProp<TStore>;
//             return clean;
//           }

//           let key = name as keyof typeof store;

//           type k = Pick<TStore, keyof TStore>;
//           const val = store[key];
//           return val as k;

//         },

//       } as TStore & TP;
//     });

//     return hook as Store;
//   };
// };
