import { useEffect, useState } from "react";
import { SelectComponent } from "../../_components/SelectComponent";
import { fetchVehicleOptions } from "./vehicleApi";
import { ApplicationStateSelector, useFormStore } from "../../_store/useApplicationState";
import { IYearMakeModelTrim } from "../../_store/loan";
import { IApplicationStep } from "../../_store/application";


type YearMakeModelTrimProps = {
  isNew: boolean;
  store: ApplicationStateSelector<IYearMakeModelTrim>
} & IApplicationStep
& IApplicationStep;

export const YearMakeModelTrim = (props: YearMakeModelTrimProps) => {
  const [years, setYears] = useState<string[]>([]);
  const [makes, setMakes] = useState<{ make: string; hh_code: string }[]>([]);
  const [models, setModels] = useState<string[]>([]);
  const [trims, setTrims] = useState<string[]>([]);

  const [store] = useFormStore(props.store);
  const year = store(vehicle => vehicle.year);
  const make = store(vehicle => vehicle.make);
  const model = store(vehicle => vehicle.model);

  useEffect(() => {
    (async () => {
      const dto = await fetchVehicleOptions(props.isNew, year, make, model);
      setYears(dto.years);
      setMakes(dto.makes);
      setModels(dto.models);
      setTrims(dto.trims);
    })();
  }, [make, model, props.isNew, year]);

  return (
    <>
      <SelectComponent<Pick<IYearMakeModelTrim, "year">, string>
        label="Year"
        className="w-1/2"
        store={props.store}
        selector={(vehicle) => vehicle.year}
        step={props.step}
        testId="year_select"
      >
        <option></option>
        {years && years.map((entry) => <option key={entry}>{entry}</option>)}
      </SelectComponent>

      <SelectComponent<Pick<IYearMakeModelTrim, "make">, string>
        label="Make"
        store={props.store}
        selector={(vehicle) => vehicle.make}
        step={props.step}
        testId="make_select"
      >
        <option></option>
        {makes &&
          makes.map((entry) => (
            <option key={entry.make} value={entry.hh_code}>
              {entry.make}
            </option>
          ))}
      </SelectComponent>

      <SelectComponent<Pick<IYearMakeModelTrim, "model">, string>
        label="Model"
        store={props.store}
        selector={(vehicle) => vehicle.model}
        step={props.step}
        testId="model_select"
      >
        <option></option>
        {models && models.map((entry) => <option key={entry}>{entry}</option>)}
      </SelectComponent>

      <SelectComponent<Pick<IYearMakeModelTrim, "trim">, string>
        label="Trim"
        store={props.store}
        selector={(vehicle) => vehicle.trim}
        step={props.step}
        testId="trim_select"
      >
        <option></option>
        {trims && trims.map((entry) => <option key={entry}>{entry}</option>)}
      </SelectComponent>
    </>
  );
};
