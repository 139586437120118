import { IApplicationStep } from "../../../_store/application";
import { useApplicationType } from "../../../_store/useApplicationType";
import { useRequiresPreviousEmployment } from "../../../_store/useEmploymentRequirements";
import { PreviousEmployment } from "./PreviousEmployment";

export const JointPreviousEmployment = (props: IApplicationStep) => {
  const application_type = useApplicationType();
  const requiresJointPreviousEmployment = useRequiresPreviousEmployment(
    (s) => s.joint_current_employment
  );
  return (
    <>
      {application_type === "Joint" && requiresJointPreviousEmployment && (
        <PreviousEmployment
          label="Co-Applicant Previous Employment"
          store={(s) => s.joint_previous_employment}
          step={props.step}
          applicant="joint"
        />
      )}
    </>
  );
};
