import "./ResidenceOwnership.css";

import { useState } from "react";
import { FieldLayoutComponent } from "../_components/FieldLayoutComponent";
import { IFieldProps } from "../_components/IFieldProps";
import { Address, AddressOwnership } from "../_store/address";
import { isNullOrWhite } from "../_helpers/isNullOrWhite";
import { IFieldSelector, useFormStore } from "../_store/useApplicationState";
import { useFieldValidationRegistry } from "../_store/useFieldValidationRegistry";
import { StoreField } from "../_components/StoreField";

type ResidenceOwnershipStoreType = Pick<Address, "ownership">;

type ResidenceOwnershipProps = IFieldProps<
  ResidenceOwnershipStoreType,
  AddressOwnership | undefined
> &
  IFieldSelector<ResidenceOwnershipStoreType, AddressOwnership | undefined>;

export const ResidenceOwnership = (props: ResidenceOwnershipProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [store, set] = useFormStore<ResidenceOwnershipStoreType>(props.store);
  const ownership = store((address) => address.ownership);
  const setter = set(props.selector);

  //Component does not have isDirty - no need to force
  const validate = (
    value: StoreField<ResidenceOwnershipStoreType>
  ): [boolean] => {
    if (isNullOrWhite(value)) {
      setShowError(() => true);
      setErrorMessage(() => "Please select residence ownership status.");
      return [false];
    }

    setShowError(() => false);
    setErrorMessage(() => "");
    return [true];
  };

  // we need a key to tag the radio controls
  const [key] = useFieldValidationRegistry(
    props.store,
    props.selector,
    props.step,
    () => {
      const value = store.getState().ownership;
      return validate(value);
    }
  );

  const handleOnChange = (status: AddressOwnership) => {
    setter(status);
    setShowError(() => false);
    setErrorMessage(() => "");
  };

  return (
    <FieldLayoutComponent
      {...props}
      label={props.label}
      showError={showError}
      errorMessage={errorMessage}
      className="residence-ownership"
    >
      <div className="inline-radio">
        <label htmlFor={`${key}_own`}>
          <input
            id={`${key}_own`}
            name={`${key}_own`}
            type="radio"
            checked={ownership === "own"}
            onChange={() => handleOnChange("own")}
          />
          Own
        </label>

        <label htmlFor={`${key}_rent`}>
          <input
            id={`${key}_rent`}
            name={`${key}_rent`}
            type="radio"
            checked={ownership === "rent"}
            onChange={() => handleOnChange("rent")}
          />
          Rent
        </label>

        <label htmlFor={`${key}_lease`}>
          <input
            id={`${key}_lease`}
            name={`${key}_lease`}
            type="radio"
            checked={ownership === "lease"}
            onChange={() => handleOnChange("lease")}
          />
          Lease
        </label>

        <label htmlFor={`${key}_other`}>
          <input
            id={`${key}_other`}
            name={`${key}_other`}
            type="radio"
            checked={ownership === "other"}
            onChange={() => handleOnChange("other")}
          />
          Other
        </label>
      </div>
    </FieldLayoutComponent>
  );
};
