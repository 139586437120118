import "./App.css";
import "./GroupBox.css";
import "./Scrollbars.css";
import "./Mobile.css";

import logo from "./assets/myautoloan-logo.svg";

import { ProgressBar } from "./components/ProgressBar";
import { LoanApplicationForm } from "./components/LoanApplicationForm";

import { AddressInfoStep } from "./components/address-info/AddressInfoStep";
import { EmploymentInfoStep } from "./components/employment-info/EmploymentInfoStep";
import { LoanDetailsStep } from "./components/LoanDetailsStep";
import { AccountAndAuthorizationStep } from "./components/account-and-authorization/AccountAndAuthorizationStep";

import { FormParameters, mapLoanType, Steps } from "../_store/application";
import { ApplicationTabs } from "./components/ApplicationTabs";
import { ApplicationSubmitted } from "./components/ApplicationSubmitted";
import { useApplicationState } from "../_store/useApplicationState";

import { useFieldValidation } from "../_store/useFieldValidationRegistry";
import { useSubmitPayload } from "./useSubmitPayload";
import { submit } from "./profile-api";
import { useEffect, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";

/**
 * Scrolls to the first element in the DOM (top-to-bottom) that has the "error" class.
 * If none is found, it does nothing.
 */
const scrollToFirstError = () => {
  // This selects the first element with the class "error" (top to bottom)
  const errorElement = document.querySelector('.border-error-950');
  
  if (errorElement) {
    // Use the built-in scrollIntoView to scroll to the element
    errorElement.scrollIntoView({
      behavior: 'smooth',  // for a smooth scrolling effect
      block: 'center',     // center the element in the viewport
    });
  }
}


const App = () => {
  
  console.log("args =>",  window.__hdf?.args);

  const [showError, setShowError] = useState(false);
  const [profileId, setProfileId] = useState<number>(0);
  const [build_payload] = useSubmitPayload();
  const application_store = useApplicationState((s) => s.application_store);
  const step = application_store((app) => app.step);
  const { find } = useFieldValidation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    console.log('Commit SHA => ', __COMMIT_HASH__);
    console.log('Build number => ', __BUILD_NUMBER__);

    const args = window.__hdf?.args ?? {};

    datadogRum.addAction("[form_start]", {
      ...args,
      __COMMIT_HASH__,
      __BUILD_NUMBER__,
    });
  }, []);

  useEffect(() => {
    if (window.__hdf?.args) {

      const params: FormParameters = {
        loan_type: window.__hdf?.args.loanType,
        source: window.__hdf?.args.source,
        type: window.__hdf?.args.type,
        term: window.__hdf?.args.term,
        site: window.__hdf?.args.site,
        promo_code: window.__hdf?.args.promoCode,
        channel: window.__hdf?.args.channel,
        cjevent:window.__hdf?.args.channel,
        '_ost': window.__hdf?.args['_ost']
      };

      const loan_type = mapLoanType(window.__hdf?.args.loanType);
      application_store.setState({ loan_type, params });
    }
  }, [application_store]);

  const onContinueSubmit = async () => {

    datadogRum.addAction('[continue_button]', { step });

    setShowError(false);
    const fields = find(step);
    const validators = fields
      ?.map((validate) => {
        return Promise.resolve(validate());
      });

    const results = await Promise.all(validators);

    const valid = results.every(([valid]) => valid);

    if (!valid) {
      datadogRum.addError(new Error('[validation_error]'), { step });
      scrollToFirstError();
      setIsSubmitting(false);
      return;
    }

    if (step == Steps.account) {
      try {

        setIsSubmitting(true);
        console.log("[submit]: start:{start}", new Date());
        datadogRum.addAction("[submit]", { status: "start" });

        // TODO: need to disable btns on save/submit
        const payload = build_payload();
        const response = await submit(payload);

        if (response.status !== 200) {

          if (response.status < 500) {
            console.warn('[submit] status:{status}', response.status);
          } else {
            console.error('[submit] status:{status}', response.status);
          }
          
          datadogRum.addError(new Error('[submit]'), { status: response.status });
          setShowError(true);
          return false;
        }

        console.log("[submit]: status:{status}", response.status);
        datadogRum.addAction("[submit]", { status: "complete" });

        const json = await response.json();
        setProfileId(json?.profile_id);
      } catch (error) {
        console.trace(error);
        console.error("[submit]:", error);
        setShowError(true);
        return;
      } finally {
        setIsSubmitting(false);
      }      
    }

    application_store.setState({ step: step + 1 });
  };

  return (
    <>
      <div className="container-logo">
        <img src={logo} alt="MyAutoLoan Logo" />
      </div>

      <div className="container-app">
        <h1 className="header-main">Pre-Qualification Loan / Finance Form</h1>
        <ApplicationTabs />
        <ProgressBar />

        {showError && 
          <div className="px-8 py-4">
            <p className="sub-text-error">Your application could not be processed at this time, please try again later.</p>
          </div>
        }

        <LoanApplicationForm>
          {step === Steps.address && <AddressInfoStep step={Steps.address} />}

          {step === Steps.employment && (
            <EmploymentInfoStep step={Steps.employment} />
          )}

          {step === Steps.loan && <LoanDetailsStep step={Steps.loan} />}

          {step === Steps.account && (
            <AccountAndAuthorizationStep step={Steps.account} />
          )}

          {step === Steps.submitted && <ApplicationSubmitted profileId={profileId} />}

          {step === Steps.account && (
            <>
              <p className="text-sm py-4">
                By clicking the “SUBMIT APPLICATION” button, I(”We” if applying
                for a Joint loan/financing offer request) are authorizing HDF,
                its Lenders, Credit Sources their brands and/or dealers to
                contact me at the Primary Phone number provided, using automated
                telephone dialing systems, text and/or pre-recorded messages for
                marketing purposes. It is understood that calls and messages to
                any mobile phone number may incur fees from my cellular
                provider. I understand that consent is not required as a
                condition of these services.
              </p>
            </>
          )}
        </LoanApplicationForm>

        {step < Steps.submitted && (
          <div className="sticky-footer">
            <div>
              {step > 1 && (
                <button
                  className="navigation-button"
                  onClick={() => application_store.setState({ step: step - 1 })}
                  data-testid="back_btn"
                >
                  &larr; BACK
                </button>
              )}
            </div>
            {step < Steps.account && (
              <div>
                <button
                  className="navigation-button"
                  onClick={onContinueSubmit}
                  data-testid="next_btn"
                >
                  CONTINUE →
                </button>
              </div>
            )}
            {step === Steps.account && (
              <button
                className="navigation-button text-xs"
                onClick={onContinueSubmit}
                disabled={isSubmitting}
                data-testid="submit_btn"
              >
                {isSubmitting ? 'SUBMITTING...' : 'SUBMIT APPLICATION'}
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default App;
