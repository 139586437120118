import { InputComponent } from "../../_components/InputComponent";
import { SelectComponent } from "../../_components/SelectComponent";

import { isPotentialVulgarFieldValid } from "../../_api/mal-validations";
import {
  IStoreSelector,
  useApplicationState,
} from "../../_store/useApplicationState";
import { LoanDetails, RefinanceLoan } from "../../_store/loan";

import { IApplicationStep } from "../../_store/application";
import { LIEN_HOLDER_COMPANIES } from "./LIEN_HOLDER_COMPANIES";
// import { StoreField } from "../../_components/StoreField";

export const LienHolder = (
  props: IStoreSelector<RefinanceLoan> & IApplicationStep
) => {
  const loan_details = useApplicationState((s) => s.loan_details);
  const lien_holder = loan_details((loan) => loan.lien_holder);
  const { getState: getter, setState: setter} = loan_details;

  // useEffect(() => {
  //   if (
  //     !isNullOrWhite(lien_holder) &&
  //     !LIEN_HOLDER_COMPANIES.includes(lien_holder) &&
  //     lien_holder !== "Other"
  //   ) {
  //     setter({ lien_holder: "Other", lien_holder_other: lien_holder });
  //   }
  // }, []);

  const onLienHolderChange = (value: string | undefined) => {
    if (value !== "Other") {
      setter({ lien_holder_other: "" });
    }
  };

  // const validateLienHolderOther = (
  //   lien_holder_other: StoreField<Pick<RefinanceLoan, 'lien_holder_other'>>,
  //   force: boolean,
  //   isDirty?: boolean
  // ): [boolean, string] => {

  //   if (force || isDirty) {

  //     // const [isFreeOfSpecialCharacters, specialCharactersErrorMessage] =
  //     //   validateSpecialCharacters(lien_holder_other!);

  //     if (!isFreeOfSpecialCharacters) {
  //       return [false, specialCharactersErrorMessage("Lien Holder")];
  //     }
  //   }

  //   return [true, ""];
  // };

  const validateLienHolderOtherAsync = async (): Promise<
    [boolean, (label: string) => string]
  > => {
    const { lien_holder_other } = getter();

    const [isVulgarFree, vulgarErrorMessage] =
      await isPotentialVulgarFieldValid(lien_holder_other!);
    if (!isVulgarFree) {
      return [false, vulgarErrorMessage];
    }

    return [true, () => ""];
  };

  const options = LIEN_HOLDER_COMPANIES.map((x) => (
    <option key={x}>{x}</option>
  ));

  return (
    <>
      <SelectComponent<Pick<RefinanceLoan, "lien_holder">, string>
        label="Lien Holder"
        infoIcon="Lien Holder"
        onChange={onLienHolderChange}
        className="col"
        store={props.store}
        selector={(loan) => loan.lien_holder}
        step={props.step}
      >
        <option></option>
        {options}
        <option>Other</option>
      </SelectComponent>

      {lien_holder === "Other" && (
        <InputComponent<Pick<LoanDetails, "lien_holder_other">, string>
          label="Lien Holder Name"
          maxLength={50}
          // validate={(value, force, isDirty) => validateLienHolderOther(value, force, isDirty)}
          validateAsync={validateLienHolderOtherAsync}
          testId="remainingBalance"
          className="col"
          store={props.store}
          selector={(loan) => loan.lien_holder_other}
          step={props.step}
        />
      )}
    </>
  );
};
