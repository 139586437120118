import { IApplicationStep } from "../../../_store/application";
import { CurrentEmployment } from "./CurrentEmployment";


export const PrimaryCurrentEmployment = (props: IApplicationStep) => {
  return (
    <CurrentEmployment
      store={(s) => s.primary_current_employment}
      step={props.step} 
      applicant={'primary'}
      />      
  );
};
