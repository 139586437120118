import { IApplicationStep } from "../../../_store/application";
import { useScrollToElement } from "../../../_helpers/useScrollToElement";
import { ApplicationTypeComponent } from "../../../my-autoloan-shared/ApplicationType";
import "./LoanBasics.css";
import { LoanTypeComponent } from "../../../my-autoloan-shared/LoanTypeComponent";

export const LoanBasics = (props: IApplicationStep) => {
  useScrollToElement("top");

  return (
    <div className="group-box" id="top">
      <h2>Loan Basics</h2>
      <div className="">
        <div className="grid margin-responsive">
          <ApplicationTypeComponent
            label="Application Type"
            step={props.step}
          />
          <LoanTypeComponent label="Loan Type" step={props.step} />
        </div>
      </div>
    </div>
  );
};
