import { useApplicationState} from './useApplicationState';
import { calculateTotalMonthsWithValues } from "../_helpers/calculateTotalMonths";
import { isNullOrWhite } from '../_helpers/isNullOrWhite';
import { ComponentValidationResult } from '../_components/IFieldProps';

const convert_to_months = (
    [current_employment_years, current_employment_months]: [string?, string?],
    [previous_employment_years, previous_employment_months]: [string?, string?],
    [current_address_years, current_address_months]: [string?, string?],
    [previous_address_years, previous_address_months]: [string?, string?]
  ): [number, number, number, number] => {
    const current_employment_total = calculateTotalMonthsWithValues(
      current_employment_years,
      current_employment_months
    );
    const previous_employment_total = calculateTotalMonthsWithValues(
      previous_employment_years,
      previous_employment_months
    );
    const current_address_total = calculateTotalMonthsWithValues(
      current_address_years,
      current_address_months
    );
    const previous_address_total = calculateTotalMonthsWithValues(
      previous_address_years,
      previous_address_months
    );
  
    return [
      current_employment_total ?? 0,
      previous_employment_total ?? 0,
      current_address_total ?? 0,
      previous_address_total ?? 0,
    ];
  };

  
const validate_dob_span = (
    dob: unknown, 
    current_employment_months: number,
    previous_employment_months: number,
    current_address_months: number,
    previous_address_months: number
  ): ComponentValidationResult => {

    const dob_as_string = dob?.toString() ?? '';
    if (isNullOrWhite(dob_as_string)) {
      return [false, () => 'Invalid date type'];
    }

    const dobDate = new Date(dob_as_string);
    const dobMonths = (new Date().getFullYear() - dobDate.getFullYear()) * 12;
  
    if (current_employment_months && (dobMonths - (current_employment_months + previous_employment_months)) < (12 * 14)) {
      return [false, () => 'Your age must be at least as long as your total length of employment plus 14 years.'];
    }
  
    if (current_address_months && (dobMonths - (current_address_months + previous_address_months)) < 0) {
      return [false, () => 'Your age must be at least as long as your total time at your residence(s).'];
    } 
  
    return [true];
  }

export const useAgeSpanValidator = () => {

    const {
        primary_current_address,
        primary_previous_address,
        primary_current_employment,
        primary_previous_employment,
    
        joint_current_address,
        joint_previous_address,
        joint_current_employment,
        joint_previous_employment
      } = useApplicationState();
    
      const primary_current_address_getter  = primary_current_address.getState;
      const primary_previous_address_getter  = primary_previous_address.getState;
      const primary_current_employment_getter  = primary_current_employment.getState;
      const primary_previous_employment_getter  = primary_previous_employment.getState;
    
      const joint_current_address_getter  = joint_current_address.getState;
      const joint_previous_address_getter  = joint_previous_address.getState;
      const joint_current_employment_getter  = joint_current_employment.getState;
      const joint_previous_employment_getter  = joint_previous_employment.getState;

      return {
        primary: (dob: unknown) => {
            const [
                current_employment_total,
                previous_employment_total,
                current_address_total,
                previous_address_total,
              ] = convert_to_months(
                [
                  primary_current_employment_getter().years,
                  primary_current_employment_getter().months,
                ],
                [
                  primary_previous_employment_getter().years,
                  primary_previous_employment_getter().months,
                ],
                [
                  primary_current_address_getter().years,
                  primary_current_address_getter().months,
                ],
                [
                  primary_previous_address_getter().years,
                  primary_previous_address_getter().months,
                ]
              );

              return validate_dob_span(
                dob,
                current_employment_total,
                previous_employment_total,
                current_address_total,
                previous_address_total
              );
        },
        joint: (dob: unknown) => {
            const [
                current_employment_total,
                previous_employment_total,
                current_address_total,
                previous_address_total,
              ] = convert_to_months(
                [
                  joint_current_employment_getter().years,
                  joint_current_employment_getter().months,
                ],
                [
                  joint_previous_employment_getter().years,
                  joint_previous_employment_getter().months,
                ],
                [
                  joint_current_address_getter().years,
                  joint_current_address_getter().months,
                ],
                [
                  joint_previous_address_getter().years,
                  joint_previous_address_getter().months,
                ]
              );


              return validate_dob_span(
                dob,
                current_employment_total,
                previous_employment_total,
                current_address_total,
                previous_address_total
              );

        }
      }

};