import { create, StoreApi, UseBoundStore } from "zustand";

import { createMalApplicationStore } from "./application";
import {
  createJointApplicantAddressStore,
  createJointApplicantPreviousAddressStore,
  createPrimaryApplicantAddressStore,
  createPrimaryApplicantPreviousAddressStore,
} from "./address";
import {
  createJointApplicantEmploymentStore,
  createJointApplicantPreviousEmploymentStore,
  createPrimaryApplicantEmploymentStore,
  createPrimaryApplicantPreviousEmploymentStore,
} from "./employment";
import {
  createLoanDetailsStore,
  createLoanDetailsTradeInStore,
  createLoanDetailsVehicleStore,
} from "./loan";
import {
  createAccountAndAuthorizeStore,
  createJointAccountAndAuthorizeStore,
} from "./account";

import {
  createJointApplicantCommunityPropertyStore,
  createPrimaryApplicantCommunityPropertyStore,
} from "./communityProperty";

import { StoreField } from "../_components/StoreField";

const storeDefinitions = {
  // TODO: rename to application_details
  application_store: createMalApplicationStore,
  loan_details: createLoanDetailsStore,
  loan_details_vehicle: createLoanDetailsVehicleStore,
  loan_details_trade_in: createLoanDetailsTradeInStore,

  primary_current_address: createPrimaryApplicantAddressStore,
  primary_previous_address: createPrimaryApplicantPreviousAddressStore,
  primary_current_employment: createPrimaryApplicantEmploymentStore,
  primary_previous_employment: createPrimaryApplicantPreviousEmploymentStore,

  primary_community: createPrimaryApplicantCommunityPropertyStore,
  primary_account: createAccountAndAuthorizeStore,

  joint_current_address: createJointApplicantAddressStore,
  joint_previous_address: createJointApplicantPreviousAddressStore,
  joint_community: createJointApplicantCommunityPropertyStore,
  joint_current_employment: createJointApplicantEmploymentStore,
  joint_previous_employment: createJointApplicantPreviousEmploymentStore,
  joint_account: createJointAccountAndAuthorizeStore,
};

type StoreDefinitionType = typeof storeDefinitions;

export const useApplicationState = create(() => storeDefinitions);

export type Selector<T, K extends keyof T = keyof T> = (input: T) => T[K];


export type ApplicationStateSelector<TStore> = (
  stores: StoreDefinitionType
) => UseBoundStore<StoreApi<TStore>>;


type ExtractStore<TSelector> = TSelector extends UseBoundStore<
  StoreApi<infer TStore>
>
  ? Partial<TStore>
  : never;
type BoundStores = StoreDefinitionType[keyof StoreDefinitionType];
export type Stores = ExtractStore<BoundStores>;


export interface IStoreSelector<TStore extends Stores> {
  store: (stores: StoreDefinitionType) => UseBoundStore<StoreApi<TStore>>;
}

export interface IFieldSelector<
  TStore extends Stores,
  // TField extends TStore[keyof TStore]
  TField extends StoreField<TStore>
> extends IStoreSelector<TStore> {
  selector: ReturnType<Selector<TStore>> extends TField
    ? Selector<TStore>
    : never;

  // TODO: Split
  field_parser?: (input: string) => TField;
  // FIXME: input should be TField
  to_string?: (input:TStore[keyof TStore]) => string | null;
}

type CloneType = { [key: string]: string };

export const useFormStore = <TStore extends Stores>(
  // selector: ApplicationStateSelector
  selector: (stores: StoreDefinitionType) => UseBoundStore<StoreApi<TStore>>
) => {
  const store = useApplicationState(selector) as UseBoundStore<
    StoreApi<TStore>
  >;

  //TODO: Extract for optimization
  // this creates a "clone" of the store fields and names as strings
  const initial = store.getInitialState() as object;
  const clone = Object.keys(initial).reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {} as CloneType) as TStore;

  // type SetterType = (selector: Selector<Stores>) => (value: TStore[keyof TStore]) => void;

  const set = (field_selector: Selector<TStore>) => {
    if (!field_selector) {
      const selector_missing_error = "field_selector missing.";
      console.error(selector_missing_error, selector);
      throw new Error(selector_missing_error);
    }

    const prop_name = field_selector(clone) as string;

    const setter = (value: TStore[keyof TStore]) => {
      const slice = { [prop_name]: value } as Partial<TStore>;
      store.setState(slice);
      return;
    };

    return setter;
  };

  return [store, set] as [typeof store, typeof set];
};
