import { ComponentValidationResult } from "../../_components/IFieldProps";
import { MoneyInputComponent } from "../../_components/MoneyInputComponent";
import { SelectComponent } from "../../_components/SelectComponent";
import { money_formatter } from "../../_helpers/Masks";
import { IApplicationStep } from "../../_store/application";
import { NewOrUsedLoan } from "../../_store/loan";
import { IStoreSelector, useFormStore } from "../../_store/useApplicationState";
import { TradeInComponent } from "./TradeInComponent";
import { VehicleTypeComponent } from "./VehicleTypeComponent";

type NewUsedLoanProps = IStoreSelector<NewOrUsedLoan> & IApplicationStep;

export const NewUsedLoan = (props: NewUsedLoanProps) => {

  const minimum_loan = 7999;
  const [store] = useFormStore(app => app.loan_details);
  const [vehicle] = useFormStore(app => app.loan_details_vehicle);

  // For this type of loan mileage is not required
  vehicle.setState({ mileage: 0});
  
  const validate_loan_amount = (): ComponentValidationResult => {
    const { amount, cash_down } = store.getState();

    const loan_amount = (amount ?? 0) - (cash_down ?? 0);
    if (loan_amount < minimum_loan) {
      return [false, () => `You must borrow at least ${money_formatter(minimum_loan)}`];  
    }

    return [true];
  };

  return (
    <div className="">
      <h2>Loan Details</h2>

      <div className="grid margin-responsive">
        <MoneyInputComponent<Pick<NewOrUsedLoan, 'amount'>, number | null>
          label="Total Sales Price"
          maxLength={9}
          testId="totalSalesPrice"
          className="col"
          store={props.store}
          selector={loan => loan.amount}
          step={props.step}
          validate={validate_loan_amount}
          helperText={`Loan amount (total - cash down) must be at least ${money_formatter(minimum_loan)}`}
        ></MoneyInputComponent>

        <SelectComponent<Pick<NewOrUsedLoan, 'term'>, string>
          label="Requested Term"
          className="col"
          store={props.store}
          selector={loan => loan.term}
          step={props.step}
        >
          <option></option>
          <option>24</option>
          <option>36</option>
          <option>48</option>
          <option>54</option>
          <option>60</option>
          <option>72</option>
          <option>84</option>
        </SelectComponent>

        <VehicleTypeComponent
          label="Vehicle Type"
          store={props.store}
          selector={vehicle => vehicle.vehicle_type}
          step={props.step}
        />

        <MoneyInputComponent<Pick<NewOrUsedLoan, 'cash_down'>, number | null>
          label="Cash Down Payment"
          maxLength={9}
          className="col my-2"
          store={props.store}
          selector={loan => loan.cash_down}
          step={props.step}
          validate={validate_loan_amount}
          helperText={`Loan amount (total - cash down) must be at least ${money_formatter(minimum_loan)}`}
          
        ></MoneyInputComponent>

        <TradeInComponent
          label="Trading in current car?"
          store={props.store}
          selector={vehicle => vehicle.trade_in}
          step={props.step}
        ></TradeInComponent>
      </div>
    </div>
  );
}