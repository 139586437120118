import { createStore } from './createStore';
import { NullableDate } from './NullableDate';

export interface IPasswordField {
    password: string;
}

export enum Relationship {
  Spouse = '2',
  Domestic_Partner = 'P',
  Civil_Union = 'U',
  Other = 'X'
}

export type Account = {
    initials: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    birthdate: NullableDate;
    ssn: string;
    password?: string;
    relationship?: Relationship;

    acknowledge: boolean;
    authorize?: boolean;
} & IPasswordField;

export const ACCOUNT_INITIAL_STATE = {
    initials: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    birthdate: null,
    ssn: '',
    password: '',
    relationship: undefined,

    acknowledge: false,
    authorize:undefined
};

export const createAccountAndAuthorizeStore = createStore<Account>({
    ...ACCOUNT_INITIAL_STATE,
});

export const createJointAccountAndAuthorizeStore = createStore<Account>({
    ...ACCOUNT_INITIAL_STATE,
});