import {
  ComponentValidationResult,
  IFieldProps,
} from "./IFieldProps";
import { StoreField } from './StoreField';
import { InputComponent } from "./InputComponent";
import { ITestableComponent } from "./ITestableComponent";
import { IFieldSelector, Stores } from "../_store/useApplicationState";
import { nameMask } from "../_helpers/Masks";
import { validateName } from "../_helpers/validations";


type NameInputComponentProps<
  TStore extends Stores,
  TField extends TStore[keyof TStore]
> = IFieldProps<TStore> & ITestableComponent & IFieldSelector<TStore, TField> & {
  name?: string;
}

export const NameInputComponent = <
  TStore extends Stores,
  TField extends TStore[keyof TStore]
>(
  props: NameInputComponentProps<TStore, TField>
) => {
  const validate = (
    value: StoreField<TStore>,
    force: boolean,
    isDirty: boolean
  ): ComponentValidationResult => {
    if (!force && !isDirty) {
      return [true];
    }

    if (!validateName(value as string)) {
      return [false, (label) => `${label} is invalid.`];
    }

    if (props.validate) {
      return props.validate(value, force, isDirty);
    }

    return [true];
  };

  const validateAsync = async (
    value: StoreField<TStore>
  ): Promise<[boolean, (input: string) => string]> => {
    if (props.validateAsync) {
      const [valid, error] = await props.validateAsync(value);

      return [valid, error];
    }

    return [true, () => ""];
  };

  return (
    <InputComponent
      {...props}
      name={props.name}
      validate={validate}
      validateAsync={validateAsync}
      store={props.store}
      selector={props.selector}

      mask={nameMask}
    />
  );
};
