import { useApplicationState } from "../../_store/useApplicationState";

const THANK_YOU_PAGE_URL = `${import.meta.env.VITE_MAL_THANK_YOU_URL}`;

const build_app_date = () => {
  const application_date = new Date();
  try {
    const app_date_part = new Intl.DateTimeFormat("en-US", {
      dateStyle: "short",
      timeZone: "America/Chicago",
    }).format(application_date);

    const app_time_part = new Intl.DateTimeFormat("en-US", {
      timeStyle: "medium",
      timeZone: "America/Chicago",
    }).format(application_date);

    return `${app_date_part} ${app_time_part}`;
  } catch (error) {
    console.error("[build_app_date]:", error);
    return application_date.toISOString();
  }
};

export const ApplicationSubmitted = ({ profileId }: { profileId: number }) => {
  const {
    application_store,
    primary_account,
    primary_current_address,
    // loan_details,
    loan_details_vehicle,
  } = useApplicationState();

  const app_date = build_app_date();

  const form_params = application_store.getState().params;
  const loan_type = form_params?.loan_type ?? '';
  const source = form_params?.source ?? '';  
  const site = form_params?.site ?? '';  
  const channel = form_params?.channel ?? '';

  // const promo_code = form_params?.promo_code;
  // const type = form_params?.type;
  // const term = form_params?.term;
  // const cjevent = form_params?.cjevent;
  // const _ost = form_params?._ost;  
  

  const params = {
    state: primary_current_address.getState().state,
    //
    loanType: loan_type,
    site: site,
    channel: channel,
    source: source,
    firstName: primary_account.getState().first_name,
    lastName: primary_account.getState().last_name,
    address: primary_current_address.getState().street,
    city: primary_current_address.getState().city,
    zip: primary_current_address.getState().zip,
    email: primary_account.getState().email,
    homePhone: primary_account.getState().phone,
    homePhoneAreaCode: "",
    homePhonePrefix: "",
    homePhoneSuffix: "",
    make: loan_details_vehicle.getState().make,

    //TODO: These are passed blank.
    promoCode: "",
    type: "",
    term: "",

    appType: application_store.getState().application,
    // appDate: `${app_date_part} ${app_time_part}`,
    appDate: app_date,
    formType: "ms",

    profileId: profileId.toString(),
  };

  return (
    <iframe
      style={{ overflow: "hidden" }}
      frameBorder="0"
      height="900px"
      width="100%"
      scrolling="auto"
      src={`${THANK_YOU_PAGE_URL}?${new URLSearchParams(params).toString()}`}
    ></iframe>
  );
};
