import { createStore } from './createStore';
import { IMonthsYears } from './IMonthsYears';

export type EmploymentStatus =
  | 'employed'
  | 'unemployed'
  | 'retired'
  | 'military'
  | 'self'
  | 'student'
  | 'other';


export type Employment = {
  status?: EmploymentStatus;
  occupation: string;
  employer: string;
  phone: string;
  income: number | null;
} & IMonthsYears;


export const INITIAL_EMPLOYMENT_STATE = {
  status: undefined,
  occupation: '',
  employer: '',
  phone: '',
  
  months: '',
  years: '',
  
  income: null
};

export const createPrimaryApplicantEmploymentStore = createStore<Employment>({
  ...INITIAL_EMPLOYMENT_STATE,
});

export const createJointApplicantEmploymentStore = createStore<Employment>({
  ...INITIAL_EMPLOYMENT_STATE,
});

export const createPrimaryApplicantPreviousEmploymentStore = createStore<Employment>({
  ...INITIAL_EMPLOYMENT_STATE,
});

export const createJointApplicantPreviousEmploymentStore = createStore<Employment>({
  ...INITIAL_EMPLOYMENT_STATE,
});
