import { money_formatter } from "../_helpers/Masks";
import { money_parser } from "../_helpers/parsers";
import { IFieldSelector, Stores } from "../_store/useApplicationState";
import { ComponentValidationResult, IFieldProps } from "./IFieldProps";
import { ITestableComponent } from "./ITestableComponent";
import { MaskedInputComponent } from "./MaskedInputComponent";
import { StoreField } from "./StoreField";


type MoneyInputComponentProps<
  TStore extends Stores,
  TField extends TStore[keyof TStore]
> = IFieldProps<TStore, TField> &
  ITestableComponent &
  IFieldSelector<TStore, TField> & {
    minimum?: number | null;
  }

export const MoneyInputComponent = <
  TStore extends Stores,
  TField extends TStore[keyof TStore]
>(
  props: MoneyInputComponentProps<TStore, TField>
) => {
  const validateAmount = (
    total: StoreField<TStore>,
    force: boolean,
    isDirty: boolean
  ): ComponentValidationResult => {
    
    if (!force && !isDirty) {
      return [true];
    }

    if (total === null) {
      return [false, (label) => `${label} is required.`];
    }

    if (isNaN(Number(total))) {
      return [false, (label) => `${label} is invalid.`];
    }

    if (props.validate) {
      const [valid, error_function] = props.validate(total, force, isDirty);
      if (!valid) {
        return [false, error_function];
      }
    }

    if (props.minimum === 0 && Number(total) === 0) {
      return [true];
    }

    if (props.minimum && Number(total) < props.minimum) {
      return [false, (label) => `${label} must be at least ${money_formatter(props.minimum!)}`];
    }
    else if (Number(total) < 1) {
      return [false, (label) => `${label} must be greated than zero.`];
    }

    return [true];
  };
  
  const field_parser = (input:string) => {
    const parsed = money_parser(input);
    return parsed as TField;
  }

  return (
    <MaskedInputComponent<TStore, TField>
      inputMode="numeric"
      formatter={money_formatter}
      label={props.label}
      placeholder={props.placeholder}
      maxLength={props.maxLength}
      infoIcon={props.infoIcon}
      testId={props.testId}
      store={props.store}
      selector={props.selector}
      step={props.step}
      validate={validateAmount}
      field_parser={props.field_parser ?? field_parser}
      helperText={props.helperText}
    ></MaskedInputComponent>
  );
};
