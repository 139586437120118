import { createStore } from "./createStore";
import { NullableDate } from "./NullableDate";

export type VehicleType = "NewVehicle" | "UsedVehicle" | null;

export type TradeIn = "WithTradeIn" | "NoTradeIn";

export interface IYearMakeModelTrim {
  year: string;
  make: string;
  model: string;
  trim: string;

  mileage: number | null;
}

export type NewOrUsedLoan = {
  amount: number | null;
  term: string;
  vehicle_type: VehicleType | null;
  cash_down: number | null;

  trade_in: TradeIn | null;
};

export type RefinanceLoan = {
  amount: number | null;
  term: string;
  vehicle_type: VehicleType | null;
  cash_down: number | null;
  trade_in: TradeIn | null;
  interest_rate: number | null;
  monthly_payment: number | null;
  lien_holder: string;
  lien_holder_other: string;
  next_payment_date: NullableDate;
};

export type PrivatePartyLoan = {
  amount: number | null;
  term: string;
  vehicle_type: VehicleType | null;
  cash_down: number | null;
  seller_first_name: string;
  seller_last_name: string;
  seller_phone_number: string;
  lien_holder: string;
};

export type LeaseBuyoutLoan = {
  amount: number | null;
  vehicle_type: VehicleType | null;
  next_payment_date: NullableDate;
  lien_holder: string;
  term: string;
};


export type LoanDetails = NewOrUsedLoan & RefinanceLoan & PrivatePartyLoan & LeaseBuyoutLoan;

const INITIAL_LOAN_DETAILS_STATE = {
  amount: null,
  term: "",
  vehicle_type: null,
  trade_in: null,
  cash_down: null,
  interest_rate: null,
  monthly_payment: null,
  lien_holder: "",
  lien_holder_other: "",
  
  next_payment_date: null,

  seller_first_name: "",
  seller_last_name: "",
  seller_phone_number: "",
};

const YEAR_MAKE_MODEL_TRIM_INITIAL = {
  year: "",
  make: "",
  model: "",
  trim: "",
  mileage: null
};

export const createLoanDetailsStore = createStore<LoanDetails>({
  ...INITIAL_LOAN_DETAILS_STATE,
});

export const createLoanDetailsVehicleStore = createStore<IYearMakeModelTrim>({
  ...YEAR_MAKE_MODEL_TRIM_INITIAL,
});

export const createLoanDetailsTradeInStore = createStore<IYearMakeModelTrim>({
  ...YEAR_MAKE_MODEL_TRIM_INITIAL,
});
