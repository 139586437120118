import { calculateTotalMonthsWithValues } from "../_helpers/calculateTotalMonths";
import { Address } from "./address";
import {
  ApplicationStateSelector,
  useApplicationState,
} from "./useApplicationState";

export const MIN_MONTHS_AT_ADDRESS = 6;

export const requiresPreviousAddress = (current_address: Address) => {
  const years = current_address.years;
  const months = current_address.months;

  const total_months = calculateTotalMonthsWithValues(years, months);

  const requires_previous = total_months
    ? total_months < MIN_MONTHS_AT_ADDRESS
    : false;

  return requires_previous;
};

export const useRequiresPreviousAddress = (
  selector: ApplicationStateSelector<Address>
) => {
  const current_address = useApplicationState(selector);
  // const requires_previous = requiresPreviousAddress(current_address.getState());
  const years = current_address(address => address.years);
  const months = current_address(address => address.months);

  const total_months = calculateTotalMonthsWithValues(years, months);

  const requires_previous = total_months
    ? total_months < MIN_MONTHS_AT_ADDRESS
    : false;

  return requires_previous;

  // return requires_previous;
};

export const useRequiresCommunity = (
  selector: ApplicationStateSelector<Address>
) => {
  const current_address = useApplicationState(selector);

  const state = current_address((address) => address.state);
  const requires_community = state === "WI";
  return requires_community;
};
