import { ComponentValidationResult, IFieldProps } from "./IFieldProps";
import { MaskedInputComponent } from "./MaskedInputComponent";
import { ITestableComponent } from "./ITestableComponent";
import { validateDOB } from "../_helpers/validations";
import { IStoreSelector, Stores } from "../_store/useApplicationState";
import { Account } from "../_store/account";
import { date_formatter } from "../_helpers/Masks";
import { NullableDate } from "../_store/NullableDate";
import { date_parser } from "../_helpers/parsers";


type DobInputComponentProps<
  TStore extends Stores
> = IFieldProps<TStore> &
  ITestableComponent &
  IStoreSelector<TStore>;

export const DobInputComponent = (
  props: DobInputComponentProps<Pick<Account, "birthdate">>
) => {
  const validate = (
    date: NullableDate,
    force: boolean,
    isDirty: boolean
  ): ComponentValidationResult => {
    if (!force && !isDirty) {
      return [true];
    }

    if (!date) {
      return [false, () => "Date of birth is required."];
    }

    const valid = validateDOB(date);
    if (!valid) {
      return [false, () => "Please enter a valid birth date."];
    }

    if (props.validate) {
      return props.validate(date, force, isDirty);
    }

    return [true];
  };

  return (
    <MaskedInputComponent<Pick<Account, "birthdate">, NullableDate>
      {...props}

      inputMode="numeric"
      formatter={date_formatter}

      selector={(account) => account.birthdate}
      label="Date of Birth"
      helperText="(mm/dd/yyyy)"
      
      validate={validate}
      field_parser={date_parser}
      // TODO: make better
      to_string={(input) => {
        if (input === "invalid") {
          // do nothing...
          return null;
        }
        
        if (input === null) {
          return "";
        }

        const as_string = `${
          Number(input.getMonth() + 1).toString().padStart(2,'0')
        }/${Number(input.getDate()).toString().padStart(2,'0')}/${input.getFullYear()}`;

        return as_string;
      }}
    ></MaskedInputComponent>
  );
};
