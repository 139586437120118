import { InputComponent } from "../../_components/InputComponent";
import { LoanDetails } from "../../_store/loan";
// import { isNullOrWhite } from "../../../_helpers/isNullOrWhite";
// import { isNameValid } from "../../../_api/mal-validations";
import { IStoreSelector, useApplicationState } from "../../_store/useApplicationState";
import { PhoneInputComponent } from "../../_components/PhoneInputComponent";
import { IApplicationStep } from "../../_store/application";
import { nameMask } from "../../_helpers/Masks";

export const SellerInformation = (props: IStoreSelector<LoanDetails> & IApplicationStep) => {
  const application_store = useApplicationState(s => s.application_store);
  const loan_type = application_store(s => s.loan_type);
  // const { getter } = useApplicationState(s => s.loan_details)();

  // TODO: First/Last validation needs to be adjusted
  //			 When server validation call is made, only one input error is updated instead of both
  const validateAsync = async (): Promise<[boolean, (label:string) => string]> => {
    // const firstName = getter("seller_first_name") as string;
    // const lastName = getter("seller_last_name") as string;

    // if (!isNullOrWhite(firstName) && !isNullOrWhite(lastName)) {
    //   const [isValid, errorMessage] = await isNameValid(firstName, lastName);
    //   if (!isValid) {
    //     return [false, _ => errorMessage];
    //   }
    // }

    return [true, () => ""];
  };

  return (
    loan_type === 'PrivateParty' && (
      <div className="group-box">
        <h2>{`Seller's Information`}</h2>
        <div className="content">
          <div className="grid margin-responsive">
            <InputComponent<Pick<LoanDetails, 'seller_first_name'>, string>
              label="First Name"
              maxLength={50}
              testId="seller_first_name"
              placeholder=""
              validateAsync={validateAsync}
              store={props.store}
              selector={loan => loan.seller_first_name}
              step={props.step}
              mask={nameMask}
            ></InputComponent>

            <InputComponent<Pick<LoanDetails, 'seller_last_name'>, string>
              label="Last Name"
              maxLength={50}
              testId="seller_last_name"
              placeholder=""
              validateAsync={validateAsync}
              store={props.store}
              selector={loan => loan.seller_last_name}
              step={props.step}
              mask={nameMask}
            ></InputComponent>

            <PhoneInputComponent<Pick<LoanDetails, 'seller_phone_number'>, string>
              label="Seller's Phone"
              maxLength={50}
              testId="sellerPhone"
              placeholder=""
              errorMessage={"Please enter a valid phone number."}
              // TODO: Check, wrong field?
              store={props.store}
              selector={loan => loan.seller_phone_number}
              step={props.step}
            ></PhoneInputComponent>
          </div>
          <div className="info"></div>
        </div>
      </div>
    )
  );
};
