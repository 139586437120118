import { check_vulgar_bogus } from "../../../_api/mal-validations";
import { InputComponent } from "../../../_components/InputComponent";
import { MoneyInputComponent } from "../../../_components/MoneyInputComponent";
import { MonthsYearsComponent } from "../../../_components/MonthsYearsComponent";
import { onlyLettersAndNumbersMask } from "../../../_helpers/Masks";
import { IApplicationStep } from "../../../_store/application";
import { Employment } from "../../../_store/employment";
import { IStoreSelector } from "../../../_store/useApplicationState";
import { validateIncome } from "./validateIncome";

export const PreviousEmployment = (
  props: IApplicationStep &
    IStoreSelector<Employment> & {
      label?: string;
      applicant: "primary" | "joint";
    }
) => {
  return (
    <div className="group-box">
      <h2>Previous Employment</h2>
      <div className="content">
        <div className="grid margin-responsive">
          <>
            {/* TODO: Previous employment status needs to default to employed if present... */}

            <InputComponent<Pick<Employment, "occupation">, string>
              label="Occupation"
              placeholder=""
              maxLength={50}
              testId="occupation"
              store={props.store}
              selector={(employment) => employment.occupation}
              step={props.step}
              validateAsync={(value) => check_vulgar_bogus(value as string)}
              mask={onlyLettersAndNumbersMask}
            ></InputComponent>

            <InputComponent<Pick<Employment, "employer">, string>
              label="Previous Employer"
              placeholder=""
              maxLength={75}
              testId="emp_name"
              store={props.store}
              selector={(employment) => employment.employer}
              step={props.step}
              validateAsync={(value) => check_vulgar_bogus(value as string)}
              mask={onlyLettersAndNumbersMask}
            ></InputComponent>

            <MonthsYearsComponent
              label="Time at Previous Employer?"
              testId="elapsed_time"
              store={props.store}
              step={props.step}
            />

            <MoneyInputComponent<Pick<Employment, "income">, number | null>
              label="Gross Yearly Income"
              placeholder=""
              maxLength={12}
              infoIcon="Gross Yearly Income"
              testId="income"
              store={props.store}
              selector={(employment) => employment.income}
              step={props.step}
              validate={validateIncome(props.applicant, 'previous')}
            ></MoneyInputComponent>
          </>
        </div>
        <div className="info"></div>
      </div>
    </div>
  );
};
