import { Account } from "../../../_store/account";
import info from "../../assets/info-120.svg";
import { DobInputComponent } from "../../../_components/DobInputComponent";
import { ElectronicRecordsDisclosure } from "./ElectronicRecordsDisclosure";
import { EmailInputComponent } from "../../../_components/EmailInputComponent";
import { PasswordInputComponent } from "../../../my-autoloan-shared/PasswordInputComponent";
import { PhoneInputComponent } from "../../../_components/PhoneInputComponent";
import { SsnInputComponent } from "../../../_components/SsnInputComponent";
import { InitialsInputComponent } from "../../../_components/InitialsInputComponent";
import { NameInputComponent } from "../../../_components/NameInputComponent";
import { useScrollToElement } from "../../../_helpers/useScrollToElement";
import { RelationshipComponent } from "../../../_components/RelationshipComponent";
import { isEmailValid, isNameValid } from "../../../_api/mal-validations";

import { useApplicationState } from "../../../_store/useApplicationState";
import { isNullOrWhite } from "../../../_helpers/isNullOrWhite";
import { useAgeSpanValidator } from "../../../_store/useAgeSpanValidator";
import { IApplicationStep } from "../../../_store/application";
import { ComponentValidationResult } from "../../../_components/IFieldProps";
import { useFieldValidation } from "../../../_store/useFieldValidationRegistry";

import { CaptchaComponent } from "./CaptchaComponent";

const validate_initials = (
  first_name: string,
  last_name: string,
  initials: string
): ComponentValidationResult => {
  if (
    isNullOrWhite(first_name) ||
    isNullOrWhite(last_name) ||
    isNullOrWhite(initials)
  ) {
    return [true];
  }

  const first_initial = first_name?.substring(0, 1).toUpperCase();
  const last_initial = last_name?.substring(0, 1).toUpperCase();
  const match = initials?.toUpperCase() === `${first_initial}${last_initial}`;

  if (match) {
    return [true];
  }

  return [false, () => "Initials do not match, please check."];
};

const server_side_full_name_validation = async (
  first?: string,
  last?: string
): Promise<[boolean, string]> => {
  if (isNullOrWhite(first) || isNullOrWhite(last)) {
    return [true, ""];
  }

  const [valid, error] = await isNameValid(first ?? "", last ?? "");
  return [valid, error];
};

export const AccountAndAuthorizationStep = (props: IApplicationStep) => {
  const { single: field_finder } = useFieldValidation();
  const { primary: validate_primary_dob_span, joint: validate_joint_dob_span } =
    useAgeSpanValidator();

  const {
    primary_account,
    primary_current_address,
    joint_account,
    joint_current_address,
    application_store,
  } = useApplicationState();

  const { getState: primary_account_getter } = primary_account;
  const { getState: primary_current_address_getter } = primary_current_address;

  const { getState: joint_account_getter } = joint_account;
  const { getState: joint_current_address_getter } = joint_current_address;

  const application_type = application_store(
    (application) => application.application
  );

  useScrollToElement("top");

  return (
    <>
      <div className="group-box" id="top">
        <div className="content">
          <div className="">
            <h2>Account</h2>
            <div className="grid margin-responsive">
              <>
                {application_type === "Joint" && (
                  <InitialsInputComponent<Pick<Account, "initials">, string>
                    label="Initials"
                    maxLength={2}
                    className="w-1/2"
                    store={(s) => s.primary_account}
                    selector={(account) => account.initials}
                    step={props.step}
                    validate={(initials) => {
                      const { first_name, last_name } =
                        primary_account_getter();
                      return validate_initials(first_name, last_name, initials);
                    }}
                  />
                )}

                <NameInputComponent<Pick<Account, "first_name">, string>
                  label="First Name"
                  name="first-name"
                  autoComplete="given-name"
                  maxLength={50}
                  testId="first_name"
                  placeholder=""
                  store={(s) => s.primary_account}
                  selector={(account) => account.first_name}
                  step={props.step}
                  validateAsync={async () => {
                    const { first_name, last_name } = primary_account_getter();

                    const [valid, error] =
                      await server_side_full_name_validation(
                        first_name,
                        last_name
                      );

                    if (!valid) {
                      return [false, () => error];
                    }

                    const [, initials_validator] = field_finder(
                      (s) => s.primary_account,
                      (f) => f.initials,
                      props.step
                    );

                    if (initials_validator !== "not_found") {
                      const [initialis_valid, initials_error] =
                        await initials_validator();
                      if (!initialis_valid) {
                        return [false, initials_error ?? (() => "")];
                      }
                    }

                    return [valid, () => error];
                  }}
                ></NameInputComponent>

                <NameInputComponent<Pick<Account, "last_name">, string>
                  label="Last Name"
                  maxLength={50}
                  testId="last_name"
                  placeholder=""
                  store={(s) => s.primary_account}
                  selector={(account) => account.last_name}
                  step={props.step}
                  // validate={() => {
                  //   const [, initials_validator] = field_finder(
                  //     (s) => s.primary_account,
                  //     (f) => f.initials,
                  //     props.step
                  //   );

                  //   if (initials_validator !== "not_found") {
                  //     return initials_validator() as ComponentValidationResult;
                  //   }

                  //   return [true] as ComponentValidationResult;
                  // }}
                  validateAsync={async () => {
                    const { first_name, last_name } = primary_account_getter();

                    const [valid, error] =
                      await server_side_full_name_validation(
                        first_name,
                        last_name
                      );

                    if (!valid) {
                      return [false, () => error];
                    }

                    const [, initials_validator] = field_finder(
                      (s) => s.primary_account,
                      (f) => f.initials,
                      props.step
                    );

                    if (initials_validator !== "not_found") {
                      const [initialis_valid, initials_error] =
                        await initials_validator();
                      if (!initialis_valid) {
                        return [false, initials_error ?? (() => "")];
                      }
                    }

                    return [valid, () => error];
                  }}
                ></NameInputComponent>

                <PhoneInputComponent
                  label="Phone"
                  maxLength={50}
                  testId="phone"
                  placeholder=""
                  errorMessage={"Please enter a valid phone number."}
                  store={(s) => s.primary_account}
                  selector={(acct) => acct.phone}
                  step={props.step}
                ></PhoneInputComponent>

                <EmailInputComponent<Pick<Account, "email">, string>
                  label="Email Address"
                  placeholder=""
                  errorMessage={"Please enter a valid email address."}
                  allowSpecialCharacters={true}
                  infoIcon="Email Address"
                  testId="email"
                  store={(s) => s.primary_account}
                  selector={(account) => account.email}
                  step={props.step}
                  validateAsync={async (value) => {
                    const { first_name, last_name } = primary_account_getter();
                    const { zip } = primary_current_address_getter();

                    const [valid, error] = await isEmailValid(
                      (value as string) ?? "",
                      first_name ?? "",
                      last_name ?? "",
                      zip ?? ""
                    );

                    return [valid, () => error];
                  }}
                ></EmailInputComponent>

                <DobInputComponent
                  testId="dob"
                  store={(s) => s.primary_account}
                  step={props.step}
                  validate={validate_primary_dob_span}
                ></DobInputComponent>

                <SsnInputComponent<Pick<Account, "ssn">, string>
                  label="Social Security Number"
                  placeholder="xxx-xx-xxxx"
                  errorMessage={"Please enter a valid social security number."}
                  infoIcon="Social Security Number"
                  testId="ssn"
                  store={(s) => s.primary_account}
                  selector={(account) => account.ssn}
                  step={props.step}
                ></SsnInputComponent>

                <PasswordInputComponent<Pick<Account, "password">, string>
                  label="Password"
                  placeholder=""
                  store={(s) => s.primary_account}
                  selector={(account) => account.password}
                  step={props.step}
                ></PasswordInputComponent>
              </>
            </div>
          </div>

          {application_type === "Individual" && (
            <div className="flex items-center text-sm mr-6 info">
              <p>
                <img src={info} style={{ width: 40, marginBottom: "5px" }} />
                We communicate with you via email with application status and
                notice of credit decisions and approval information.
              </p>
            </div>
          )}

          {application_type === "Joint" && (
            <div className="">
              <h2>Joint Applicant</h2>

              <div className="grid margin-responsive">
                <>
                  <InitialsInputComponent<Pick<Account, "initials">, string>
                    label="Initials"
                    maxLength={2}
                    className="w-1/2"
                    store={(s) => s.joint_account}
                    selector={(account) => account.initials}
                    step={props.step}
                    validate={(initials) => {
                      const { first_name, last_name } = joint_account_getter();
                      return validate_initials(first_name, last_name, initials);
                    }}
                  />

                  <NameInputComponent<Pick<Account, "first_name">, string>
                    label="First Name"
                    maxLength={50}
                    testId="first_name"
                    placeholder=""
                    store={(s) => s.joint_account}
                    selector={(account) => account.first_name}
                    step={props.step}
                    // validate={() => {
                    //   const [, initials_validator] = field_finder(
                    //     (s) => s.joint_account,
                    //     (f) => f.initials,
                    //     props.step
                    //   );

                    //   if (initials_validator !== "not_found") {
                    //     return initials_validator() as ComponentValidationResult;
                    //   }

                    //   return [true] as ComponentValidationResult;
                    // }}
                    validateAsync={async () => {
                      const { first_name, last_name } = joint_account_getter();

                      const [valid, error] =
                        await server_side_full_name_validation(
                          first_name,
                          last_name
                        );

                      if (!valid) {
                        return [false, () => error];
                      }

                      const [, initials_validator] = field_finder(
                        (s) => s.joint_account,
                        (f) => f.initials,
                        props.step
                      );

                      if (initials_validator !== "not_found") {
                        const [initialis_valid, initials_error] =
                          await initials_validator();
                        if (!initialis_valid) {
                          return [false, initials_error ?? (() => "")];
                        }
                      }

                      return [valid, () => error];
                    }}
                  ></NameInputComponent>

                  <NameInputComponent<Pick<Account, "last_name">, string>
                    // id={"last_name"}
                    label="Last Name"
                    maxLength={50}
                    testId="last_name"
                    placeholder=""
                    store={(s) => s.joint_account}
                    selector={(account) => account.last_name}
                    step={props.step}
                    // TODO: dedup
                    // validate={() => {
                    //   const [, initials_validator] = field_finder(
                    //     (s) => s.joint_account,
                    //     (f) => f.initials,
                    //     props.step
                    //   );

                    //   if (initials_validator !== "not_found") {
                    //     return initials_validator() as ComponentValidationResult;
                    //   }

                    //   return [true] as ComponentValidationResult;
                    // }}
                    // TODO: dedup
                    validateAsync={async () => {
                      const { first_name, last_name } = joint_account_getter();

                      const [valid, error] =
                        await server_side_full_name_validation(
                          first_name,
                          last_name
                        );
                      if (!valid) {
                        return [false, () => error];
                      }

                      const [, initials_validator] = field_finder(
                        (s) => s.joint_account,
                        (f) => f.initials,
                        props.step
                      );

                      if (initials_validator !== "not_found") {
                        const [initialis_valid, initials_error] =
                          await initials_validator();
                        if (!initialis_valid) {
                          return [false, initials_error ?? (() => "")];
                        }
                      }

                      return [valid, () => error];
                    }}
                  ></NameInputComponent>

                  <RelationshipComponent
                    store={(s) => s.joint_account}
                    step={props.step}
                  />

                  <PhoneInputComponent
                    label="Phone"
                    maxLength={50}
                    testId="phone"
                    placeholder=""
                    errorMessage={"Please enter a valid phone number."}
                    store={(s) => s.joint_account}
                    selector={(account) => account.phone}
                    step={props.step}
                  ></PhoneInputComponent>

                  <EmailInputComponent<Pick<Account, "email">, string>
                    label="Email Address"
                    placeholder=""
                    errorMessage={"Please enter a valid email address."}
                    allowSpecialCharacters={true}
                    infoIcon="Email Address"
                    testId="email"
                    store={(s) => s.joint_account}
                    selector={(account) => account.email}
                    step={props.step}
                    validateAsync={async (value) => {
                      const { first_name, last_name } = joint_account_getter();
                      const { zip } = joint_current_address_getter();

                      const [valid, error] = await isEmailValid(
                        (value as string) ?? "",
                        first_name ?? "",
                        last_name ?? "",
                        zip ?? ""
                      );

                      return [valid, () => error];
                    }}
                  ></EmailInputComponent>

                  <DobInputComponent
                    testId="dob"
                    store={(s) => s.joint_account}
                    step={props.step}
                    validate={validate_joint_dob_span}
                  ></DobInputComponent>

                  <SsnInputComponent<Pick<Account, "ssn">, string>
                    label="Social Security Number"
                    placeholder="xxx-xx-xxxx"
                    errorMessage={
                      "Please enter a valid social security number."
                    }
                    infoIcon="Social Security Number"
                    testId="ssn"
                    store={(s) => s.joint_account}
                    selector={(account) => account.ssn}
                    step={props.step}
                  ></SsnInputComponent>
                </>
              </div>
            </div>
          )}
        </div>
      </div>

      <>
        <div className="group-box">
          <div className="">
            <h2>Authorization and Legal</h2>
            <ElectronicRecordsDisclosure step={props.step} />
          </div>

          <CaptchaComponent />
        </div>
      </>
    </>
  );
};
