import { useState } from "react";
import { MaskedInputElement } from "./MaskedInputElement";
import { IFieldProps } from "./IFieldProps";
import { FieldLayoutComponent } from "./FieldLayoutComponent";
import { isNullOrWhite } from "../_helpers/isNullOrWhite";
import { ITestableComponent } from "./ITestableComponent";
import { IFieldSelector, Stores, useFormStore } from "../_store/useApplicationState";
import { useFieldValidationRegistry } from "../_store/useFieldValidationRegistry";
import { InputModeType } from "./InputModeType";

type MaskedInputComponentProps<
  TStore extends Stores,
  TField extends TStore[keyof TStore]
> = IFieldProps<TStore, TField> &
  ITestableComponent &
  IFieldSelector<TStore, TField> & {
    formatter: (input:string) => string
  } & {
    inputMode: InputModeType
  }

export const MaskedInputComponent = <
  TStore extends Stores,
  TField extends TStore[keyof TStore]
>(
  props: MaskedInputComponentProps<TStore, TField>
) => {
  const [showError, setShowError] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const validate = (
    value: TField,
    force: boolean
  ): [boolean] | [boolean, (label: string) => string] => {
    if (force) {
      setIsDirty(() => true);
    }

    if (isDirty || force) {
      const isValid = !isNullOrWhite(value);

      if (!isValid) {
        setShowError(() => true);
        setErrorMessage(() => `${props.label} is required.`);
        return [false, (label: string) => `${label} is required.`];
      }
    }

    if (props.validate) {
      const [isValid, customMessage] = props.validate(value, force, isDirty);

      if (!isValid) {
        setShowError(() => true);
        setErrorMessage(() => customMessage(props.label ?? "This"));
        return [false, customMessage];
      }
    }

    setShowError(() => false);
    setErrorMessage(() => "");
    return [true];
  };

  const onBlurValidation = async (value: TField) => {
    if (!isDirty) {
      return;
    }

    const [isValid] = validate(value, false);
    if (isValid && props.validateAsync) {
      const [isValidAsync, message] = await props.validateAsync(value);

      if (!isValidAsync) {
        setShowError(() => true);
        setErrorMessage(() => message(props.label ?? ""));
      }
    }
  };

  const [store] = useFormStore<TStore>(props.store);

  useFieldValidationRegistry(
    props.store,
    props.selector,
    props.step,
    async (): Promise<[boolean, (m: string) => string]> => {
      const value = props.selector(store.getState()) as TField;
      const [isValid, label] = validate(value, true);
      if (isValid && props.validateAsync) {
        const [isValidAsync, message] = await props.validateAsync(value);

        if (!isValidAsync) {
          setShowError(() => true);
          setErrorMessage(() => message(props.label ?? ""));
        }

        return [isValidAsync, message];
      }

      return [isValid, label ?? (() => "")];
    }
  );

  return (
    <FieldLayoutComponent
      {...props}
      showError={showError}
      errorMessage={errorMessage}
    >
      <MaskedInputElement<TStore, TField>
        {...props}

        inputMode={props.inputMode}
        formatter={props.formatter}

        step={props.step}
        store={props.store}
        selector={props.selector}
        
        showError={showError}
        onChange={(value) => {
          if (!isNullOrWhite(value)) {
            setIsDirty(() => true);
          }

          setShowError(() => false);

          if (props.onChange) {
            props.onChange(value);
          }
        }}
        onBlur={async (value) => {
          await onBlurValidation(value);
          if (props.onBlur) {
            props.onBlur(value, true);
          }
        }}
      />
    </FieldLayoutComponent>
  );
};
