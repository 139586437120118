import { create } from "zustand";
import { match, P } from "ts-pattern";
import {
  ApplicationStateSelector,
  useApplicationState,
} from "./useApplicationState";
import { Steps } from "./application";
import { useEffect } from "react";
// import { uniq } from "lodash";
// import { isNullOrWhite } from "../_helpers/isNullOrWhite";

// Build a map of the application state keys as string for indexing
const useFormStoreKeys = () => {
  const stores_initial = useApplicationState.getInitialState();

  const keys = Object.keys(stores_initial).reduce((stores_acc, store_key) => {
    const store = stores_initial[store_key as keyof typeof stores_initial];
    const initial = store.getInitialState();

    const clone = Object.keys(initial).reduce((acc, key) => {
      acc[key] = `${store_key}__${key}`;
      return acc;
    }, {} as { [key: string]: string });

    stores_acc[store_key] = [store_key, clone];
    return stores_acc;
  }, {} as { [key: string]: [string, unknown] });

  //TODO: Check this...
  return keys as unknown as typeof stores_initial;
};

export type FieldValidationFunction = () =>
  | [boolean]
  | [boolean, (label: string) => string];

  export type FieldValidationAsyncFunction = () =>
    | Promise<[boolean]>
    | Promise<[boolean, (label: string) => string]>;

type ValidationKey = { key: string; step: Steps | "" };
interface IValidationFunctionRegistry {
  fields: Map<ValidationKey, FieldValidationFunction | FieldValidationAsyncFunction>;

  push: (input: {
    key: ValidationKey;
    validator: FieldValidationFunction | FieldValidationAsyncFunction;
  }) => void;
  single: (
    key: ValidationKey
  ) =>
    | [key: ValidationKey, validator: FieldValidationFunction | FieldValidationAsyncFunction]
    | ["not_found", "not_found"];
  remove: (key: ValidationKey) => void;
}

const useValidationFunctionRegistryStore = create<IValidationFunctionRegistry>(
  (set, get) => ({
    fields: new Map(),
    push: (input) => {
      const fields = get().fields;
      const exists = Array.from(fields.entries()).find(
        (field) =>
          field[0].key === input.key.key && field[0].step === input.key.step
      );

      if (exists) {
        fields.delete(exists[0]);
      }

      fields.set(input.key, input.validator);
      set({ fields });
    },
    single: (input: ValidationKey) => {
      const fields = get().fields;
      const exists = Array.from(fields.entries()).find(
        (field) => field[0].key === input.key && field[0].step === input.step
      );

      return exists ?? ["not_found", "not_found"];
    },
    remove: (input: ValidationKey) => {
      const fields = get().fields;
      const exists = Array.from(fields.entries()).find(
        (field) => field[0].key === input.key && field[0].step === input.step
      );

      if (exists) {
        fields.delete(exists[0]);
      }
    },
  })
);

type CombinedControls = "months_years" | "city_state_zip";

export const useFieldValidationRegistry = <TStore>(
  store: ApplicationStateSelector<TStore>,
  field: ((s: TStore) => TStore[keyof TStore]) | CombinedControls,
  step: Steps | "" = "",
  validator: FieldValidationFunction | FieldValidationAsyncFunction
) => {
  const keys = useFormStoreKeys();
  const push = useValidationFunctionRegistryStore((r) => r.push);
  const _single = useValidationFunctionRegistryStore((r) => r.single);
  const _remove = useValidationFunctionRegistryStore((r) => r.remove);

  const store_tuple = store(keys) as unknown as [string, unknown];

  
  const key = match(field)
    .with(P.string, (field_as_string) => {
      return `${store_tuple[0]}__${field_as_string}`;
    })
    .otherwise((field_as_selector) => {
      const store_key = store(keys) as unknown as [string, unknown];
      return field_as_selector(store_key[1] as TStore) as string;
    });



  useEffect(() => {
    
    const _unregister = () => {
      const [exists] = _single({ key, step });
  
      if (exists !== "not_found") {
        _remove(exists);
      }
    };
  
    const _register = (validator: FieldValidationFunction | FieldValidationAsyncFunction) => {
      const entry = { key: { key, step }, validator };
      push(entry);
    };

    _register(validator);

    return () => {
      _unregister();
    };
  }, [_remove, _single, key, push, step, validator]);

  return [key];
};

export const useFieldValidation = () => {
  const keys = useFormStoreKeys();
  const _single = useValidationFunctionRegistryStore((r) => r.single);

  const single = <TStore>(
    // store: (s: typeof keys) => [keyof typeof keys, TStore],
    store: ApplicationStateSelector<TStore>,
    field: ((s: TStore) => TStore[keyof TStore]) | CombinedControls,
    step: Steps | "" = ""
  ) => {
    const store_tuple = store(keys) as unknown as [string, unknown];
    const key = match(field)
      .with(P.string, (field_as_string) => {
        return `${store_tuple[0]}_${field_as_string}`;
      })
      .otherwise((field_as_selector) => {
        const store_key = store(keys) as unknown as [string, unknown];
        return field_as_selector(store_key[1] as TStore) as string;
      });

    const found = _single({ key, step });
    return found;
  };

  const find = (step?: Steps) => {
    const all = useValidationFunctionRegistryStore.getState();
    
    const validators = Array.from(all.fields)
      .filter((validator) => validator[0].step === step)
      .map((validator) => validator[1]);

    return validators;
  };

  return {
    single,
    find,
  };
};

// const count_empty_props = (obj: Record<string, unknown>): [number, number] => {
//   let totalProperties = 0;
//   let nullOrEmptyProperties = 0;

//   for (const key in obj) {
//     if (Object.hasOwnProperty.call(obj, key)) {
//       totalProperties++;
//       if (!isNullOrWhite(obj[key])) {
//         nullOrEmptyProperties++;
//       }
//     }
//   }

//   return [totalProperties, nullOrEmptyProperties];
// }

// /**
//  * Hook to calculate the completed/missing fields in a step
//  * @returns a function to filter by application step
//  */
// export const useFieldCompletionMap = () => {  
//   const stores_initial = useApplicationState.getInitialState();
//   const field_map = (step?: Steps) => {
//     const all = useValidationFunctionRegistryStore.getState();

//     const stores_in_step = uniq(
//       Array.from(all.fields)
//         .filter((field) => field[0].step === step)
//         .map(
//           (field) => field[0].key.split("__")[0] as keyof typeof stores_initial
//         )
//     );

//     const total_and_empty_props = stores_in_step.reduce(
//       (acc, key) => {
//         const store = stores_initial[key];

//         const state = store.getState() as unknown as Record<string, unknown>;
//         console.log("state=>", state);

//         const props = count_empty_props(state);
//         acc[0] += props[0];
//         acc[1] += props[1];

//         return acc;
//       },
//       [0, 0] as [number, number]
//     );

//     return total_and_empty_props;
//   };

//   return field_map;
// };
