import { MonthsYearsComponent } from "../../../_components/MonthsYearsComponent";
import { StreetAddressComponent } from "../../../_components/StreetAddressComponent";
import { IApplicationStep } from "../../../_store/application";
import { CityStateZip } from "../../../my-autoloan-shared/CityStateZip";
import { useApplicationType } from "../../../_store/useApplicationType";
import { useRequiresPreviousAddress } from "../../../_store/useResidenceRequirements";

export const JointPreviousAddress = (props:IApplicationStep) => {
  const application_type = useApplicationType();
  const joint_requires_previous = useRequiresPreviousAddress(
    (s) => s.joint_current_address
  );

  return (
    <>
      {application_type === "Joint" && joint_requires_previous && (
        <div className="group-box">
          <h2>Co-Applicant Previous Address</h2>
          <div className="content">
            <div className="grid margin-responsive">
              <StreetAddressComponent
                label={"Street"}
                placeholder=""
                maxLength={50}
                store={(s) => s.joint_previous_address}
                step={props.step}
              />

              <CityStateZip
                store={(s) => s.joint_previous_address}
                step={props.step}
              />

              <MonthsYearsComponent
                label="How long did you live at this address?"
                errorMessage="Please select years and months at this address."
                store={(s) => s.joint_previous_address}
                step={props.step}
              ></MonthsYearsComponent>
            </div>
            <div className=""></div>
          </div>
        </div>
      )}
    </>
  );
};
