import "./CityStateZip.css";
import { InputComponent } from "../_components/InputComponent";
import { StateSelectComponent } from "../_components/StateSelectComponent";
import { zipMask } from "../_helpers/Masks";
import { StreetCityStateZip } from "../_store/address";
import {
  isZipValid
} from "../_helpers/validations";
import { ComponentValidationResult } from "../_components/IFieldProps";
import { IStoreSelector } from "../_store/useApplicationState";
import { IApplicationStep } from "../_store/application";
import { StoreField } from "../_components/StoreField";


export const CityStateZip = <TAddress extends StreetCityStateZip>(props: IStoreSelector<TAddress> & IApplicationStep) => {

  const validateZip = (
    zip: StoreField<Pick<TAddress, 'zip'>>,
    force: boolean,
    isDirty: boolean
  ): ComponentValidationResult => {
    if (!(force || isDirty)) {
      return [true];
    }

    if (!isZipValid(zip!)) {
      return [false, () => "Invalid zip code."];
    }

    return [true];
  };

  return (
    <div className="city-state-zip">
      {/* TODO: We use to see if city was vulgar. 
                Since we are using AddressApi, we could save for server side validation?
                The user is able to edit the city after selecting an address from dropdown.
        */}
      {/* TODO: error messages can look awkward on shorter inputs
                long messages can take up 3-4 lines, not very appealing...
        */}
      <InputComponent<Pick<StreetCityStateZip, "city">, string>
        label="City"
        autoComplete="city"
        placeholder=""
        maxLength={50}
        testId={"city"}
        className="city"
        store={props.store}
        selector={(address) => address.city}
        step={props.step}
      ></InputComponent>

      {/* TODO: Not all state names fit in box */}
      <StateSelectComponent
        label="State"
        autoComplete="state"
        prompt=""
        testId={"state"}
        className="state"
        store={props.store}
        selector={(address) => address.state}
        step={props.step}
      />

      <InputComponent<Pick<StreetCityStateZip, "zip">, string>
        label="Zip"
        autoComplete="zip"
        inputMode="numeric"
        placeholder=""
        mask={zipMask}
        testId={"zip"}
        className="zip"
        validate={(zip, force, isDirty) => validateZip(zip, force, isDirty)}
        store={props.store}
        selector={(address) => address.zip}
        step={props.step}
      ></InputComponent>
    </div>
  );
};
