import { IApplicationStep } from "../_store/application";
import { CommunityPropertyState } from "./CommunityPropertyState";
import { useRequiresCommunity } from "../_store/useResidenceRequirements";

export const JointCommunity = (props:IApplicationStep) => {
  const joint_requires_community = useRequiresCommunity(
    (s) => s.joint_current_address
  );
  return (
    <>
      {joint_requires_community && (
        <CommunityPropertyState
          store={(s) => s.joint_community}
          step={props.step}
        />
      )}
    </>
  );
}