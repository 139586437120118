
export enum InfoIcons {
  'Email Address' = 'We communicate with you via email with application status and notice of credit decisions and approval information.',
  'Social Security Number' = 'Your personal information is safe and secure; we use 128-bit encryption. In order to process your application and provide you with real-time credit decisions we need your SSN.',
  'Monthly House Payment' = 'If the Joint Applicant lives with the Applicant and the Applicant entered 100% of the housing payment, the Joint Applicant\'s Housing Payment should then be entered as $0.00.  If the Applicant only entered their portion of the Housing Payment then the Joint Applicant should enter their portion of the Housing Payment.  If the Joint Applicant and Applicant do not live together, each should enter their individual amounts for their Housing Payment.',
  'Time at Employer' = 'For Unemployed, Retired, or Student, enter how long you have been unemployed, retired, or a student.',
  'Work Phone Number' = 'For Unemployed, Retired, or Student, enter your primary phone number',
  'Gross Yearly Income' = 'All Applicants: enter your combined total pre-tax income from ALL income sources you wish to disclose for the purpose of this application. Non-taxable income should be increased by 25%.',
  'Remaining Balance' = 'This is the amount you owe on the vehicle you\'re refinancing.',
  'Interest Rate' = 'This is the interest rate on the vehicle loan you\'re refinancing.',
  'Monthly Payment' = 'This is the monthly payment you are currently paying for the vehicle you\'re refinancing.',
  'Lien Holder' = 'Select from the list your current auto finance company that you make payments to OR if they are not listed, select Other and add your lender name in the field provided.',
  'Loan Amount' = 'Loan Amount is the estimated amount you want to borrow and finance; it does not have to be exact. It should include the vehicle\'s sales price plus tax, title, and license fees minus any down payment.',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  'Lease Buyout Amount' = 'This is the amount you owe on the vehicle you\'re refinancing.',
  'Lease Expiration Date' = 'This is the date your lease expires and you\'re required to return the vehicle if you don\'t purchase it.',
  'Lease Holder' = 'This is the bank or finance company you\'re currently making your lease payments to.',
  'Password' = '8 to 20 characters with at lease one upper case, lower case, number, and symbol.'
}
