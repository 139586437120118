import { isNullOrWhite } from "../_helpers/isNullOrWhite";
import { validateEmail, validateName, validatePhone, validateSsn, validateVulgarField, ValidationResponseDto } from "./validation-api"

// Note: Trying to keep validations that use mal api separate from other validations

const VALIDATION_ERROR = 'An error occurred while validating your information.'

export const isPotentialVulgarFieldValid = async (
  value: string
): Promise<[boolean, (label: string) => string]> => {
  try {
    const response = await validateVulgarField(value);
    const data = (await response.json()) as ValidationResponseDto;
    return [
      data.is_valid,
      data.is_valid ? () => "" : (label) => `Please enter a valid ${label}.`,
    ];
  } catch (err) {
    console.log(err);
  }

  return [false, () => VALIDATION_ERROR];
};

export const isPhoneValid = async (value: unknown, label: string): Promise<[boolean, string]> => {
  try {
    const value_as_string = value?.toString() ?? '';
    const response = await validatePhone(value_as_string);
    const data = await response.json() as ValidationResponseDto;
    return [data.is_valid, data.is_valid ? '' : `Please enter a valid ${label}.`];
  } catch (err) {
    console.log(err);
  }

  return [false, VALIDATION_ERROR];
}

// TODO: rename to indicate this is server side
export const isEmailValid = async (email: string, firstName: string, lastName: string, zip: string): Promise<[boolean, string]> => {
  try {
    if (isNullOrWhite(email) || isNullOrWhite(firstName) || isNullOrWhite(lastName) || isNullOrWhite(zip)) {
      // api will return 400
      return [true, ''];
    }

    const response = await validateEmail(email, firstName, lastName, zip);
    const data = await response.json() as ValidationResponseDto;
    return [data.is_valid, data.is_valid ? '' : 'Your e-mail address is already in use.'];
  } catch (err) {
    console.log(err);
  }

  return [false, VALIDATION_ERROR];
}

export const isSsnValid = async (ssn: unknown, errorMessage?: string | undefined): Promise<[boolean, string]> => {
  try {
    const ssn_as_string = ssn?.toString() ?? '';
    if (isNullOrWhite(ssn_as_string)) {
      return [false, 'invalid ssn type'];
    }

    const response = await validateSsn(ssn_as_string);
    const data = await response.json() as ValidationResponseDto;

    if (!data.is_valid && data.error_message === 'DUPLICATE_SSN_30_DAYS') {
      return [false, 'DUPLICATE_SSN_30_DAYS'];
    }

    return [data.is_valid, data.is_valid ? '' : (errorMessage ?? 'Please enter a valid social security number.')];
  } catch (err) {
    console.log(err);
  }

  return [false, VALIDATION_ERROR];
}

export const isNameValid = async (firstName: string, lastName: string): Promise<[boolean, string]> => {
  try {
    const response = await validateName(firstName, lastName);
    const data = await response.json() as ValidationResponseDto;
    return [data.is_valid, data.is_valid ? '' : 'Please enter a valid name.'];
  } catch (err) {
    console.log(err)
  }

  return [false, VALIDATION_ERROR];
}

export const check_vulgar_bogus = async (
  value: string
): Promise<[boolean, ((label:string) => string)]> => {

  const [isVulgarFree, vulgarErrorMessage] = await isPotentialVulgarFieldValid(value);
  if (!isVulgarFree) {
    return [false, vulgarErrorMessage];
  }

  return [true, () => ""];
};