import info from "../../assets/info-120.svg";

import { MonthsYearsComponent } from "../../../_components/MonthsYearsComponent";
import { StreetAddressComponent } from "../../../_components/StreetAddressComponent";
import { Address } from "../../../_store/address";
import { CityStateZip } from "../../../my-autoloan-shared/CityStateZip";
import { ResidenceOwnership } from "../../../my-autoloan-shared/ResidenceOwnership";
import { IApplicationStep } from "../../../_store/application";
import { useApplicationType } from "../../../_store/useApplicationType";
import { money_parser } from "../../../_helpers/parsers";
import { MoneyInputComponent } from "../../../_components/MoneyInputComponent";

export const JointCurrentAddress = (props:IApplicationStep) => {
  const application_type = useApplicationType();

  return (
    <>
      {application_type === "Joint" && (
        <div className="group-box">
          <h2>Co-Applicant Address</h2>
          <div className="content">
            <div className="grid margin-responsive">
              <StreetAddressComponent
                label={"Street"}
                placeholder=""
                maxLength={50}
                store={(s) => s.joint_current_address}
                step={props.step}
              />

              <CityStateZip
                store={(s) => s.joint_current_address}
                step={props.step}
              />

              <MonthsYearsComponent
                label="How long have you lived at this address?"
                errorMessage="Please select years and months at this address."
                minimumValueErrorMessage="Must have lived at least one month at this address."
                store={(s) => s.joint_current_address}
                step={props.step}
              ></MonthsYearsComponent>

              <ResidenceOwnership
                label={"Do you"}
                // applicant={"joint"}
                store={(s) => s.joint_current_address}
                selector={(address) => address.ownership}
                step={props.step}
              ></ResidenceOwnership>

              <MoneyInputComponent<Pick<Address, "monthly_payment">, number | null>
                label="Monthly Housing Payment"
                placeholder=""
                maxLength={9}
                infoIcon="Monthly House Payment"
                testId="jc_monthly_payment"
                store={(s) => s.joint_current_address}
                selector={(address) => address.monthly_payment}
                step={props.step}
                field_parser={money_parser}
              ></MoneyInputComponent>
            </div>
            <div className="info">
              <p className="text-sm">
                <img src={info} style={{ width: 40, marginBottom: "5px" }} />
                {`If the Joint Applicant lives with the Applicant and the
                Applicant entered 100% of the housing payment, the Joint
                Applicant's Housing Payment should then be entered as $0.00. If
                the Applicant only entered their portion of the Housing Payment,
                then the Joint Applicant should enter their portion of the
                Housing Payment. If the Joint Applicant and Applicant do not
                live together, each should enter their individual amount for the
                Housing Payment.`}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
