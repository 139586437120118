import { create } from "zustand";
import { useApplicationState } from "../_store/useApplicationState";
import { useEffect } from "react";
import { useSubmitPayload } from "./useSubmitPayload";


function flatten_object<T extends Record<string, unknown>>(
  obj: T,
  prefix: string = ""
): Record<string, unknown> {
  const flattened: Record<string, unknown> = {};

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const newKey = prefix ? `${prefix}_${key}` : key;
      const value = obj[key];

      if (
        value !== null &&
        typeof value === "object" &&
        !Array.isArray(value)
      ) {
        Object.assign(
          flattened,
          flatten_object(value as Record<string, unknown>, newKey)
        );
      } else if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean" ||
        value === null
      ) {
        flattened[newKey] = value;
      }
    }
  }

  return flattened;
}

function count_properties<T extends Record<string, unknown>>(
  obj: T
): [number, number] {
  let totalProperties = 0;
  let populated = 0;

  for (const key in obj) {
    if (!key.includes("$") && Object.hasOwnProperty.call(obj, key)) {
      totalProperties++;
      if (obj[key] !== null && obj[key] !== "") {
        populated++;
      }
    }
  }

  return [totalProperties, populated];
}

type ApplicationProgress = {
  progress: number;
  setProgress: (progress: number) => void;
};

export const useApplicationProgress = create<ApplicationProgress>((set) => ({
  progress: 5,
  setProgress: (progress: number) => {
    set({ progress });
  },
}));


export const useSubscribeApplicationState = () => {
  const application_state = useApplicationState();

  const [build_payload] = useSubmitPayload();
  const setProgress = useApplicationProgress((s) => s.setProgress);

  useEffect(() => {
    const subs: (() => void)[] = [];

    Object.keys(application_state).forEach((key) => {
      const unsub = application_state[key as keyof typeof application_state].subscribe(
        (previous, next) => {
          if (previous === next) {
            return;
          }

          const payload = build_payload();
          const flat = flatten_object(payload);

          const [total, populated] = count_properties(flat);

          const progress = (populated / total) * 100;

          setProgress(progress);
        }
      );

      subs.push(unsub);
    });

    return(() => {

      subs.forEach(unsub => unsub());
    });
  }, [application_state, build_payload, setProgress]);
};
