import { IFieldError } from "./FieldLayoutComponent";
import { ComponentValidationResult, IFieldProps } from "./IFieldProps";
import { ITestableComponent } from "./ITestableComponent";
import { isValidPhoneFormat } from "../_helpers/validations";
import { isPhoneValid } from "../_api/mal-validations";
import { IFieldSelector, Stores } from "../_store/useApplicationState";
import { StoreField } from "./StoreField";
import { MaskedInputComponent } from "./MaskedInputComponent";
import { formatGeneral } from "cleave-zen";

type PhoneInputComponentProps<
  TStore extends Stores,
  TField extends StoreField<TStore>
> = IFieldProps<TStore, TField> &
  IFieldError &
  ITestableComponent &
  IFieldSelector<TStore, TField>;

export const PhoneInputComponent = <TStore extends Stores,TField extends StoreField<TStore>>(
  props: PhoneInputComponentProps<TStore, TField>
) => {
  const validatePhone = (
    phone: StoreField<TStore>,
    force: boolean,
    isDirty: boolean
  ): ComponentValidationResult => {
    if (force || isDirty) {
      const valid = isValidPhoneFormat(phone);
      if (!valid) {
        return [valid, () => props.errorMessage ?? "Invalid phone."];
      }
    }

    return [true];
  };

  const validatePhoneAsync = async (
    phone: StoreField<TStore>
  ): Promise<[boolean, (label: string) => string]> => {
    const [isPhoneValidResult, phoneNotValidErrorMessage] = await isPhoneValid(
      phone,
      props.label ?? "Phone"
    );
    if (!isPhoneValidResult) {
      return [false, () => phoneNotValidErrorMessage];
    }

    return [true, () => ""];
  };

  return (
    <MaskedInputComponent<TStore, TField>
      {...props}
      inputMode="tel"
      autoComplete="tel-national"
      formatter={(input: string) => {
        const masked = formatGeneral(input, {
          delimiter: "-",
          blocks: [3, 3, 4],
          numericOnly: true
        });
        return masked;
      }}
      validate={validatePhone}
      validateAsync={validatePhoneAsync}
      store={props.store}
      selector={props.selector}
    ></MaskedInputComponent>
  );
};
