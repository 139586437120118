import { InputComponent } from "../_components/InputComponent";
import { SelectComponent } from "../_components/SelectComponent";
import { StreetAddressComponent } from "../_components/StreetAddressComponent";
import { isNullOrWhite } from "../_helpers/isNullOrWhite";
import { isNameValid } from "../_api/mal-validations";
import { CityStateZip } from "./CityStateZip";
import {
  CommunityProperty,
  MaritalStatus,
} from "../_store/communityProperty";
import {
  IStoreSelector,
  useFormStore,
} from "../_store/useApplicationState";
import { IApplicationStep } from "../_store/application";
import { nameMask } from "../_helpers/Masks";

export const CommunityPropertyState = (
  props: IStoreSelector<CommunityProperty> & IApplicationStep
) => {
  const [store] = useFormStore<CommunityProperty>(props.store);
  const marital_status = store((community) => community.marital_status);

  const requiresSpousalInformation =
    marital_status === MaritalStatus.Married ||
    marital_status === MaritalStatus.Separated;

  const validateAsync = async (): Promise<
    [boolean, (label: string) => string]
  > => {
    const { spouse_first_name, spouse_last_name } = store.getState();

    if (!isNullOrWhite(spouse_first_name) && !isNullOrWhite(spouse_last_name)) {
      const [isValid, errorMessage] = await isNameValid(
        spouse_first_name!,
        spouse_last_name!
      );

      if (!isValid) {
        return [false, () => errorMessage];
      }
    }

    return [true, () => ""];
  };

  const maritalStatusKeys: string[] = Object.keys(MaritalStatus);
  const maritalStatusOptions = maritalStatusKeys.map((x) => {
    const key: keyof typeof MaritalStatus = x as keyof typeof MaritalStatus;
    return (
      <option key={key} value={MaritalStatus[key]}>
        {key}
      </option>
    );
  });

  return (
    <div className="group-box">
      <h2>Community Property State Requirements</h2>
      <div className="content">
        <div className="grid margin-responsive">
          <div className="">
            <SelectComponent<Pick<CommunityProperty, "marital_status">, MaritalStatus | undefined>
              label="Marital Status"
              className="col"
              store={props.store}
              selector={(community) => community.marital_status}
              step={props.step}
            >
              <option></option>
              {maritalStatusOptions}
            </SelectComponent>

            {requiresSpousalInformation && (
              <>
                <InputComponent<Pick<CommunityProperty, "spouse_first_name">, string>
                  label="First Name"
                  maxLength={50}
                  testId="spouse_first_name"
                  placeholder=""
                  validateAsync={validateAsync}
                  store={props.store}
                  selector={(community) => community.spouse_first_name}
                  step={props.step}
                  mask={nameMask}
                ></InputComponent>

                <InputComponent<Pick<CommunityProperty, "spouse_last_name">, string>
                  label="Last Name"
                  maxLength={50}
                  testId="spouse_last_name"
                  placeholder=""
                  validateAsync={validateAsync}
                  store={props.store}
                  selector={(community) => community.spouse_last_name}
                  step={props.step}
                  mask={nameMask}
                ></InputComponent>
              </>
            )}
          </div>
        </div>

        {/* TODO check this... empty div? */}
        {!requiresSpousalInformation && <div className=""></div>}

        {requiresSpousalInformation && (
          <div className="">
            <div className="grid margin-responsive">
              <StreetAddressComponent
                label={"Street"}
                placeholder=""
                maxLength={50}
                store={props.store}
                step={props.step}
              />

              <CityStateZip<CommunityProperty>
                store={props.store}
                step={props.step}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
