import { IFieldProps } from './IFieldProps';
import { IFieldError } from './FieldLayoutComponent';
import { IFieldSelector, Stores, useFormStore } from '../_store/useApplicationState';

export interface ISelectElementProps {
  prompt?: string;
  children?: React.ReactNode;
}


type SelectElementProps<
  TStore extends Stores,
  TField extends TStore[keyof TStore]
> = IFieldProps<TStore, TField> &
  IFieldError &
  IFieldSelector<TStore, TField> &
  ISelectElementProps;

export const SelectElement = <TStore extends Stores, TField extends TStore[keyof TStore]>(
  props:SelectElementProps<TStore, TField>
) => {

  const [store, set] = useFormStore<TStore>(props.store);
  const setter = set(props.selector);
  
  //this value is reactive
  const value = store(props.selector);
  const value_as_string = value?.toString();

  return (
    <select
      className={`form-select  
        ${
          props.showError
            ? 'focus:ring focus:ring-rose-400 focus:border-error-950 border-error-950 border-2'
            : 'border-[#d3d3d3] focus:border-[#d3d3d3] focus:ring-gray-600 focus:ring-2'
        } form-input-field
        ${props.disabled ? 'text-[#535353] opacity-40' : ''}
        ${props.className ? ` ${props.className}` : ''}
            `}
      value={value_as_string}
      disabled={props.disabled}
      onChange={(e) => {
        const parsed = props.field_parser ? 
            props.field_parser(e.target.value) :
            e.target.value as TField;

        setter(parsed);
        if (props.onChange) {
          props.onChange(parsed);
        }
      }}
      onBlur={(e) => {
        const parsed = props.field_parser ? 
            props.field_parser(e.target.value) :
            e.target.value as TField;

        if (props.onBlur) {
          props.onBlur(parsed, true);
        }
      }}
      data-testid="field_select"
    >
      {props.children}
    </select>
  );
};
