import { capitalize } from "lodash";
import { check_vulgar_bogus } from "../../../_api/mal-validations";
import { InputComponent } from "../../../_components/InputComponent";
import { MonthsYearsComponent } from "../../../_components/MonthsYearsComponent";
import { PhoneInputComponent } from "../../../_components/PhoneInputComponent";
import { SelectComponent } from "../../../_components/SelectComponent";

import { IApplicationStep } from "../../../_store/application";
import { Employment, EmploymentStatus } from "../../../_store/employment";
import {
  IStoreSelector,
  useFormStore,
} from "../../../_store/useApplicationState";
import info from "../../assets/info-120.svg";
import { validateIncome } from "./validateIncome";
import { useFieldValidation } from "../../../_store/useFieldValidationRegistry";
import { money_parser } from "../../../_helpers/parsers";
import { MoneyInputComponent } from "../../../_components/MoneyInputComponent";
import { onlyLettersAndNumbersMask } from "../../../_helpers/Masks";


export const CurrentEmployment = (
  props: IApplicationStep &
    IStoreSelector<Employment> & {
      label?: string;
      applicant: 'primary' | 'joint'
    }
) => {
  const [, set] = useFormStore(props.store);
  const occupation_setter = set((s) => s.occupation);
  const employer_setter = set((s) => s.employer);

  const { single: get_validator } = useFieldValidation();

  return (
    <div className="group-box" id="top">
      <h2>{props.label ?? "Employment"}</h2>
      <div className="content">
        <div className="grid margin-responsive">
          <>
            {/* TODO: Set and disable Occupation and Employer for Unemployed, retired, or Student */}
            <SelectComponent<
              Pick<Employment, "status">,
              EmploymentStatus | undefined
            >
              label="Employment Status"
              testId="employment_status"
              store={props.store}
              selector={(employment) => employment.status}
              step={props.step}
              onChange={(value) => {
                //default values
                if (
                  value &&
                  ["unemployed", "retired", "student"].includes(value)
                ) {
                  const formatted = capitalize(value);
                  occupation_setter(formatted);
                  employer_setter(formatted);

                  // Validators are only available here
                  // TODO: try to simplify this...
                  const [, occupation_validator] = get_validator(
                    props.store,
                    (employment) => employment.occupation,
                    props.step
                  );
                  const [, employer_validator] = get_validator(
                    props.store,
                    (employment) => employment.employer,
                    props.step
                  );

                  if (employer_validator !== "not_found") {
                    employer_validator();
                  }

                  if (occupation_validator !== "not_found") {
                    occupation_validator();
                  }
                } else {
                  occupation_setter("");
                  employer_setter("");
                }
              }}
            >
              <option value=""></option>
              <option value="employed">Employed</option>
              <option value="unemployed">Unemployed</option>
              <option value="retired">Retired</option>
              <option value="military">Military</option>
              <option value="self">Self Employed</option>
              <option value="student">Student</option>
              <option value="disabled">Disabled</option>
              <option value="other">Other</option>
            </SelectComponent>

            <InputComponent<Pick<Employment, "occupation">, string>
              label="Occupation"
              placeholder=""
              maxLength={50}
              testId="occupation"
              store={props.store}
              selector={(employment) => employment.occupation}
              step={props.step}
              validateAsync={(value) => check_vulgar_bogus(value as string)}
              mask={onlyLettersAndNumbersMask}
            ></InputComponent>

            <InputComponent<Pick<Employment, "employer">, string>
              label="Current Employer"
              placeholder=""
              maxLength={75}
              testId="emp_name"
              store={props.store}
              selector={(employment) => employment.employer}
              step={props.step}
              validateAsync={(value) => check_vulgar_bogus(value as string)}
              mask={onlyLettersAndNumbersMask}
            ></InputComponent>

            <PhoneInputComponent
              label="Work Phone"
              placeholder={""}
              infoIcon="Work Phone Number"
              testId="work_phone"
              store={props.store}
              selector={(employment) => employment.phone}
              step={props.step}
              errorMessage={"Please enter a valid work phone number."}
            ></PhoneInputComponent>

            <MonthsYearsComponent
              label="Time at Employer?"
              infoIcon="Time at Employer"
              testId="elapsed_time"
              store={props.store}
              step={props.step}
            />

            <MoneyInputComponent<Pick<Employment, "income">, number | null>
              label="Gross Yearly Income"
              placeholder=""
              maxLength={12}
              infoIcon="Gross Yearly Income"
              testId="income"
              store={props.store}
              selector={(employment) => employment.income}
              step={props.step}
              validate={validateIncome(props.applicant, 'current')}
              field_parser={money_parser}
            ></MoneyInputComponent>
          </>
        </div>
        <div className="info">
          <p className="text-sm">
            <img src={info} style={{ width: 40, marginBottom: "5px" }} />
            For Unemployed, Retired, or Student, enter how long you have been
            unemployed, retired, or a student.
          </p>
          <p className="text-sm mt-2">
            For Unemployed, Retired, or Student, enter your primary phone
            number.
          </p>
          <p className="text-sm mt-2">
            All Applicants: enter your combined total pre-tax income from ALL
            income sources you wish to disclose for the purpose of this
            application. Non-taxable income should be increased by 25%.
          </p>
        </div>
      </div>
    </div>
  );
};
