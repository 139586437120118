import { MoneyInputComponent } from "../../_components/MoneyInputComponent";
import { SelectComponent } from "../../_components/SelectComponent";
import { money_parser } from "../../_helpers/parsers";
import { IApplicationStep } from "../../_store/application";
import { LoanDetails } from "../../_store/loan";
import { IStoreSelector, useFormStore } from "../../_store/useApplicationState";

export const PrivatePartyLoan = (props: IStoreSelector<LoanDetails> & IApplicationStep) => {

  const [loan_details_store] = useFormStore(app => app.loan_details);
  loan_details_store.setState({ trade_in: "NoTradeIn", vehicle_type: "UsedVehicle" });

  return (
    <div className="">
      <h2>Loan Details</h2>

      <div className="grid margin-responsive">
        <MoneyInputComponent<Pick<LoanDetails, "amount">, number | null>
          label="Loan Amount"
          maxLength={9}
          testId="loanAmount"
          className="col"
          infoIcon="Loan Amount"
          store={props.store}
          selector={(loan) => loan.amount}
          step={props.step}
          field_parser={money_parser}
          minimum={1}
        />

        <SelectComponent<Pick<LoanDetails, "term">, string>
          label="Term"
          className="col"
          store={props.store}
          selector={(loan) => loan.term}
          step={props.step}
        >
          <option></option>
          <option>24</option>
          <option>36</option>
          <option>48</option>
          <option>54</option>
          <option>60</option>
          <option>72</option>
          <option>84</option>
        </SelectComponent>
      </div>
    </div>
  );
}