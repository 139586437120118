import { useState } from "react";
import {
  FieldLayoutComponent,
  IFieldError,
} from "../../_components/FieldLayoutComponent";
import { IFieldProps } from "../../_components/IFieldProps";
import { LoanDetails, TradeIn } from "../../_store/loan";
import {
  IFieldSelector,
  useFormStore,
} from "../../_store/useApplicationState";
import { useFieldValidationRegistry } from "../../_store/useFieldValidationRegistry";

type TradeInType = Pick<LoanDetails, "trade_in">;

export const TradeInComponent = (
  props: IFieldProps<TradeInType> & IFieldError & IFieldSelector<TradeInType, TradeIn | null>
) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [store, set] = useFormStore<TradeInType>(props.store);
  const trade_in = store((s) => s.trade_in);
  const setter = set(props.selector);

  //Component does not have isDirty - no need to force
  const validate = (value: TradeIn | null): [boolean, (label: string) => string] => {
    if (!value) {
      setShowError(() => true);
      setErrorMessage(() => "Please confirm if trading in current car.");
      return [false, () => "Please confirm if trading in current car."];
    }

    setShowError(() => false);
    setErrorMessage(() => "");
    return [true, () => ""];
  };

  useFieldValidationRegistry(props.store, props.selector, props.step, () => {
    const value = store.getState().trade_in;
    return validate(value);
  });

  return (
    <FieldLayoutComponent
      {...props}
      showError={showError}
      errorMessage={errorMessage}
    >
      <div className="inline-radio">
        <label htmlFor="trade_in_yes">
          <input
            type="radio"
            id="trade_in_yes"
            name="trade_in"
            checked={trade_in === "WithTradeIn"}
            onChange={() => {
              setter("WithTradeIn");
              validate("WithTradeIn");
            }}
            data-testid="trade_in_yes"
          />
          Yes
        </label>

        <label htmlFor="trade_in_no">
          <input
            type="radio"
            id="trade_in_no"
            name="trade_in"
            checked={trade_in === "NoTradeIn"}
            onChange={() => {
              setter("NoTradeIn");
              validate("NoTradeIn");
            }}
            data-testid="trade_in_no"
          />
          No
        </label>
      </div>
    </FieldLayoutComponent>
  );
};
